/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:01-04-2023
Purpose : The purpose of this code is to create a fetchData function responsible for fetching data related to written stories from the "wikibedtimestories.com" application. This function utilizes Axios to make API requests to retrieve story data.
Brief : The code defines an asynchronous function named fetchData.
This function is designed to retrieve data related to written stories from the application. It uses the all_stories endpoint from the WrittenStories model.
It takes in several parameters (page, allStoriesLoaded, setData, setTotalStories, setTotalStoriesObject, setFirstAndLastStoriesNumber, setLoading, setAllStoriesLoaded) to manage data fetching and updating state within the application.
The function constructs a GET request using Axios to fetch stories data with pagination parameters (pageNumber and pageSize).
Upon receiving the response, it extracts necessary information such as totalStories, totalStoriesObject, and firstAndLastStoriesNumber from the JSON response.
It updates the application state accordingly by appending new data (json.data) to the existing data (prevData) and sets flags (allStoriesLoaded) based on the fetched data length.
Any errors encountered during the fetching process are logged to the console, and the loading state is updated accordingly.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import axios from 'axios';
import { all_stories } from '../../Model/WS-WrittenStories/WrittenStories';

const PAGE_LIMIT = 10;
const fetchData = async (page, sortby, userId, allStoriesLoaded, setSortBy, setData, setTotalStories, setTotalStoriesObject, setFirstAndLastStoriesNumber, setLoading, setAllStoriesLoaded) => {
    if (!allStoriesLoaded) {
        try {
            const response = await axios.get(`${all_stories}`, {
                params: {
                    sortBy: sortby,
                    pageNumber: page,
                    pageSize: PAGE_LIMIT,
                    userId: userId
                },
            });
            const json = response.data;

            if (json && json.data) {  // Check if json.data exists before accessing its length property
                const { totalStories, totalStoriesObject, firstAndLastStoriesNumber } = json;
                setFirstAndLastStoriesNumber(firstAndLastStoriesNumber);
                setTotalStories(totalStories);
                setTotalStoriesObject(totalStoriesObject);
                setData((prevData) => [...prevData, ...json.data]);

                if (json.data.length < PAGE_LIMIT) {
                    setAllStoriesLoaded(true);
                }
            } else {
                console.error('Invalid response structure:', json.status);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }
};



export default fetchData;
