/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:-12-14-2023
Purpose : This code is to provide Controller for AuthorList.
Brief : The code consists of several API endpoint constants crucial for managing CN-related functionalities. It includes endpoints for handling CN drafts, adding tags to stories, and fetching metadata related to characters used in stories. These endpoints are structured to facilitate the creation and management of content within the application.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import axios from 'axios';
import { CN_Author_Follow, CN_Author_Unfollow } from '../../../Model/CN-ContributionNetwork/ContributionNetwork';

const unfollowAuthor = async (authorId, authToken) => {
    try {
        let body = {
            author_id: parseInt(authorId)
        };
        const response = await axios.put(CN_Author_Unfollow, body, {
            headers: {
                'Authorization': authToken
            }
        });
    } catch (error) {
        if (error.response) {
            // The request was made, but the server responded with a status code that falls out of the range of 2xx
            console.error('Server responded with an error status:', error.response.status);
            console.error('Response data:', error.response.data);
            console.error('Response headers:', error.response.headers);
        } else if (error.request) {
            // The request was made but no response was received
            console.error('Request made but no response received:', error.request);
        } else {
            // Something happened in setting up the request that triggered an error
            console.error('Error setting up the request:', error.message);
        }
        console.error('Error config:', error.config);
    }
};


export default unfollowAuthor;