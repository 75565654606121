/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:10-19-2023
Purpose : New Search Page
Brief :
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useEffect, useRef, useState } from 'react'
import searchicon from '../../../Assets/Images/Search.webp';
import { storycount } from '../../../../../../Model/WS-WrittenStories/WrittenStories';
import { useLocation, useNavigate } from 'react-router-dom';
import '../../CS/NewSearch.css'

function NewSearch({ totalStories }) {
    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const [userId, setUserID] = useState(localStorage.getItem('UserID') || null);
    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
    }, []);
    const [searchText, setSearchText] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showNoSuggestions, setShowNoSuggestions] = useState(false);
    const [storyCountData, setStoryCountData] = useState(null);
    const suggestionBoxRef = useRef(null);
    const navigate = useNavigate();

    const handleSearch = async (e) => {
        if (e.key === 'Enter') {
            navigate(`/search-results?searchText=${searchText}`);
            window.location.reload(); // Reload the screen
        }
    };

    const handleChange = (e) => {
        const inputValue = e.target.value;
        setSearchText(inputValue);

        // Show suggestions only if there's input and suggestions available
        if (inputValue.trim() !== '' && suggestions.length > 0) {
            setShowSuggestions(true);
        } else {
            setShowSuggestions(false);
        }
    };
    useEffect(() => {
        if (searchText.trim() !== '') {
            fetchSuggestions();
        } else {
            setSuggestions([]);
        }
    }, [searchText]);

    const fetchSuggestions = async () => {
        const PAGE_LIMIT = 10; // Define your page limit
        const page = 1; // Set the page number

        try {
            const response = await fetch(`https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/pages/tags?sortMode=ASC&sortBy=views&user_id=1&tags=${searchText}&pageNumber=${page}&pageSize=${PAGE_LIMIT}&user_id=${userId}`);
            if (response.ok) {
                const responseData = await response.json();
                if (responseData && responseData.data && responseData.data.length > 0) {
                    const extractedSuggestions = responseData.data.map(item => ({
                        storyID: item.storyID,
                        title: item.title,
                        summary: item.summary,
                    }));
                    setSuggestions(extractedSuggestions);
                    setShowNoSuggestions(false); // Hide "No suggestions found" message
                } else {
                    setSuggestions([]);
                    setShowNoSuggestions(true); // Show "No suggestions found" message
                }
            } else {
                console.error('Failed to fetch suggestions');
                setSuggestions([]);
                setShowNoSuggestions(true); // Show "No suggestions found" message
            }
        } catch (error) {
            console.error('Error fetching suggestions:', error);
            setSuggestions([]);
            setShowNoSuggestions(true); // Show "No suggestions found" message
        }
    };

    const handleSuggestionClick = (clickedTitle) => {
        navigate(`/search-results?searchText=${clickedTitle}`);
        window.location.reload(); // Reload the screen
    };
    const handleClickOutside = (event) => {
        if (suggestionBoxRef.current && !suggestionBoxRef.current.contains(event.target)) {
            setSuggestions([]); // Hide suggestions when clicking outside the box
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const [showSuggestions, setShowSuggestions] = useState(false); // Add state to manage suggestion box visibility

    // Function to handle click on the input field
    const handleInputClick = () => {
        if (suggestions.length > 0) {
            setShowSuggestions(true); // Show suggestions when input field is clicked and suggestions exist
        }
    };
    return (
        <div className='ws-search-box-new'>
            <div className='ws-new-search-padding'>
                <div className='ws-search-whitebox-new'>
                    <img src={searchicon} alt='searchicon' />
                    <input
                        className='ws-search-input-new'
                        placeholder="Search stories, audios and authors"
                        name="q"
                        type="text"
                        value={searchText}
                        onChange={handleChange}
                        onKeyPress={handleSearch}
                        id="searchInput"
                        onClick={handleInputClick} // Add onClick event to handle input click
                    />

                </div>
                {showSuggestions && (
                    <div className='suggestion-box' ref={suggestionBoxRef}>
                        {suggestions && suggestions.length > 0 ? (
                            suggestions.map((suggestion, index) => (
                                <div key={index}>
                                    <div onClick={() => handleSuggestionClick(suggestion.title)}>{suggestion.title}</div>
                                </div>
                            ))
                        ) : null}
                        {showNoSuggestions && searchText.length > 0 && suggestions.length === 0 && (
                            <div className='no-suggestion'>No suggestions found</div>
                        )}
                    </div>
                )}
            </div>


        </div>
    )
}

export default NewSearch
