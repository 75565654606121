/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:01-04-2023
Purpose : Search Result Page 
Brief :
SearchResult, serves as the search results page. It includes a header, a search box component (SearchBox), a search results component (Search), and a footer. 
It manages the state for displaying search data and controlling pagination by fetching stories based on the search text and page number. 
The fetchStories function is responsible for retrieving stories based on the provided search parameters. 
The page displays search results filtered by the entered text and supports paginated loading of stories.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React from 'react'
import Search from './Search'
import Header from '../../../WS-WrittenStories/src/JS/Header/Header'
import Footer from '../../../WS-WrittenStories/src/JS/Footer/Footer'
import '../../CS/SearchResult.css'
import { useEffect, useState } from 'react';
import SearchBox from './SearchBox'
import { fetchStories } from '../../../../../Controller/SM-StoriesMetadata/SearchResultController';
import { useLocation } from 'react-router-dom'

function SearchResult() {

    const location = useLocation();
    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const [userId, setUserID] = useState(localStorage.getItem('UserID') || null);
    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
    }, []);
    const PAGE_LIMIT = 10;
    const [page, setPage] = useState(1);
    const [data, setData] = useState([]);
    const [allStoriesLoaded, setAllStoriesLoaded] = useState(false);
    const [totalStories, setTotalStories] = useState(0);
    const [totalStoriesObject, setTotalStoriesObject] = useState(0);

    const params = new URLSearchParams(window.location.search);
    const searchTextParam = params.get('searchText');
    useEffect(() => {
        const fetchData = async () => {
            try {
                const stories = await fetchStories(searchTextParam, page, PAGE_LIMIT, userId, setTotalStories, setTotalStoriesObject);
                // Check if there are no stories found
                if (!stories || stories.length === 0) {
                    setAllStoriesLoaded(true);
                } else {
                    setData((prevData) => [...prevData, ...stories]);

                    if (stories.length < PAGE_LIMIT) {
                        setAllStoriesLoaded(true);
                    }
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [searchTextParam, page]);
    return (
        <div>
            <Header
            />
            <SearchBox
            />
            <Search data={data} allStoriesLoaded={allStoriesLoaded}
                totalStories={totalStories}
                totalStoriesObject={totalStoriesObject}
                setPage={setPage} page={page} searchTextParam={searchTextParam} setData={setData}
            />
            <Footer />
        </div>
    )
}

export default SearchResult
