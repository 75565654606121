/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:01-04-2023
Purpose : This code is to provide data model for stories MetaData
Brief : The code contains several API endpoint constants essential for managing story-related functionalities. It includes endpoints for sub-categories metadata, categories for searching stories, fetching story details by ID, updating story views, and searching tags associated with stories. These endpoints are designed to handle different aspects of story management within the application.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

export const sub_category = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/metadata/sub_categories?pageNumber=1&sortMode=ASC&sortBy=id`;
export const category_for_searchstory = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/pages/category?&sortMode=DESC&sortBy=rating&filterBy=all_stories&status=3`
export const story_detail_story_id = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story?userId=1`
export const update_story_views = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/view`


export const searchtag = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/pages/tags?sortMode=ASC&sortBy=views`
export const authToken = 'v4.public.eyJhdWQiOiJkZXYud2lraWJlZHRpbWVzdG9yaWVzLmNvbSIsImVtYWlsIjoiYW1pdGthbGl0YUBnbWFpbC5jb20iLCJleHAiOiIyMDIzLTA3LTMxVDA4OjE3OjU2WiIsImlhdCI6IjIwMjMtMDctMzFUMDc6MTc6NTZaIiwiaXNzIjoiZGV2Lndpa2liZWR0a1MzMHNyIlp1LsDAyFxsxXQGsKMaODdYIo7zNgdRf8jMMmOvA7OIQabHv-PqI-F7lIzw';