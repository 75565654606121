/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:10-19-2023
Purpose : Term Of Use Page
Brief :
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useState } from 'react';
import '../../CS/TermOfUse.css';
import Header from '../../../../WS-WrittenStories/src/JS/Header/Header';
import Footer from '../../../../WS-WrittenStories/src/JS/Footer/Footer';
import TermsofUser from './TermsofUser';
import Termsforcontibutor from './Termsforcontibutor';

function TermOfUse() {
    const [showUserTerms, setShowUserTerms] = useState(true); // Initially set to true
    const [showContributorTerms, setShowContributorTerms] = useState(false);

    const handleUserClick = () => {
        setShowUserTerms(true);
        setShowContributorTerms(false);
    };

    const handleContributorClick = () => {
        setShowUserTerms(false);
        setShowContributorTerms(true);
    };

    return (
        <div>
            <Header />
            <div className='as-termbtn'>
                <button
                    className="termButton"
                    style={{
                        backgroundColor: showUserTerms ? '#403085' : 'transparent',
                        color: showUserTerms ? '#ffffff' : '#403085',
                    }}
                    onClick={handleUserClick}
                >
                    Terms for User
                </button>
                <button
                    className="termButton"
                    style={{
                        backgroundColor: showContributorTerms ? '#403085' : 'transparent',
                        color: showContributorTerms ? '#ffffff' : '#403085',
                    }}
                    onClick={handleContributorClick}
                >
                    Terms for Contributor
                </button>
            </div>

            {showUserTerms && <TermsofUser />}
            {showContributorTerms && <Termsforcontibutor />}
            <Footer />
        </div>
    );
}

export default TermOfUse;


