/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:11-24-2023
Purpose : This code is to provide data model for User Management
Brief : The code contains endpoints essential for User Management functionalities, including user login and user signup. These endpoints are used to handle user authentication and registration processes within the application. The endpoints are constructed using environment variables for flexibility across different environments.
Developer : Jayash Todi
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */
// UserProfile
// postapi
export const UM_Login = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/user/login`;
export const UM_SignUp = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/user`;
// patchapi
export const updatepassword = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/user/password`
export const updateuser = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/user`;
// UserAuthentication
export const checkduplicateemail = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/user/check_email_duplicate`;
export const OtpEmail = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/user/send_otp_email`;
export const verifyotp = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/user/verify_otp`
export const changepassword = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/user/password`
export const resendemailverify = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0`;

//GetUser
export const userdetail = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/user`;

