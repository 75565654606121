/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:12-12-2023
Purpose : AuthorList
Brief :
Developer : Amit Kalita
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useEffect, useState } from 'react'
import AuthorBanner from './AuthorBanner'
import FavoriteAuthor from '../../../Assets/AuthorImage/FavoriteAuthor.webp'
import DiscoverAuthor from '../../../Assets/AuthorImage/DiscoverAuthor.webp'
import ArrowRight from '../../../Assets/AuthorImage/Arrow - Right.webp'
import AddUser from '../../../Assets/AuthorImage/Add User.webp'
import Following from '../../../Assets/AuthorImage/following.webp'
import WbsLogo from '../../../../WS-WrittenStories/Assets/Images/WBSLogo.png'
import Profile from '../../../../WS-WrittenStories/Assets/Images/defaultprofile.webp'
import AdsDetailView from '../../../../AM-AplicationManagement/src/JS/Ads/AdsDetailView'
import { useNavigate, useLocation } from 'react-router-dom'
import { CN_Author_Follow, CN_Author_Unfollow } from '../../../../../../Model/CN-ContributionNetwork/ContributionNetwork'
import axios from 'axios'

function AuthorContent({ data, visit, maylike,
    loading }) {
    const location = useLocation();
    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const [userId, setUserID] = useState(localStorage.getItem('UserID') || null);
    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
    }, []);
    const navigate = useNavigate();
    const handleAuthorProfile = (author_id, author_name) => {
        const formattedauthorName = author_name.replace(/\s+/g, '-');
        navigate(`/authorprofile/${author_id}/${formattedauthorName}`
        );
        window.scrollTo(0, 0);
    }
    const handleDiscoverAuthor = () => {
        navigate(`/discoverauthor`
        );
        window.scrollTo(0, 0);
    }
    const handleCommunity = () => {
        navigate(`/authorcommunity`
        );
        window.scrollTo(0, 0);
    }
    const handleFavoriteAuthor = () => {
        navigate(`/favoriteauthor`
        );
        window.scrollTo(0, 0);
    }
    const handleWriteSection = () => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            navigate('/writingSection'
            );
            window.scrollTo(0, 0);
        }
    }
    const [followingStatus, setFollowingStatus] = useState({
        card1: false,
        card2: false,
        card3: false,
        card4: false,
        // Add more cards as needed
    });

    const handleToggle = (card, author_id) => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            setFollowingStatus(prevState => ({
                ...prevState,
                [card]: !prevState[card]

            }));
            followAuthor(author_id); // Call followAuthor function with authorId
        }
    };

    const [authorFollow, setAuthorFollow] = useState(new Array(data.length).fill(false));
    const handleAuthorFollow = (author_id, index) => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            console.log('logged in');

            // Create a copy of the bookmarkedStories array
            const updatedAuthorFollow = [...authorFollow];

            // Toggle the bookmarked state for the specific story
            updatedAuthorFollow[index] = !updatedAuthorFollow[index];

            // Update the bookmarked state for all stories
            setAuthorFollow(updatedAuthorFollow);

            // Perform other actions for bookmarking like updating the story if needed
            // updateStoryBookmark(storyID);
        }
    }
    const unfollowAuthor = (author_id) => {
        author_id = parseInt(author_id);
        // Define the request body
        const requestBody = {
            author_id: author_id,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .put(CN_Author_Unfollow, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const followAuthor = (author_id) => {
        author_id = parseInt(author_id);
        // console.log(author_id)
        // Define the request body
        const requestBody = {
            author_id: author_id,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .post(CN_Author_Follow, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    return (
        <div className='cn-authorlist'>
            <AuthorBanner data={data}
                loading={loading} />
            <div className='cn-authorlist-page'>
                <div className='cn-author-part'>
                    <div className='web'>
                        <div className='cn-author-fav-discover'>
                            <div className='cn-author-fav'>
                                <div className='cn-image-container'>
                                    <img src={FavoriteAuthor} alt='favoriteauthor' onClick={handleFavoriteAuthor} />
                                    <div className='image-text'>
                                        <div className='cn-fav-text'>
                                            Favorite Authors
                                        </div>

                                        <div className='cn-arrowright'>
                                            <img src={ArrowRight} alt='arrow' />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='cn-author-discover' onClick={handleDiscoverAuthor}>
                                <div className='cn-image-container'>
                                    <img src={DiscoverAuthor} alt='discoverauthor' />
                                    <div className='image-text'>
                                        <div className='cn-fav-text'>
                                            Discover Authors
                                        </div>

                                        <div className='cn-arrowright'>
                                            <img src={ArrowRight} alt='arrow' />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mobile'>
                        <div className='cn-author-fav-discover'>
                            <div className='cn-author-fav'>
                                <div className='cn-image-container'>
                                    <img src={FavoriteAuthor} alt='favoriteauthor' onClick={handleFavoriteAuthor} />
                                    <div className='image-text'>
                                        <div className='cn-fav-text'>
                                            Favorite Authors
                                        </div>

                                        <div className='cn-arrowright'>
                                            <img src={ArrowRight} alt='arrow' />

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='cn-author-discover' onClick={handleDiscoverAuthor}>
                                <div className='cn-image-container'>
                                    <img src={DiscoverAuthor} alt='discoverauthor' />
                                    <div className='image-text'>
                                        <div className='cn-fav-text'>
                                            Discover Authors
                                        </div>

                                        <div className='cn-arrowright'>
                                            <img src={ArrowRight} alt='arrow' />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='web'>
                        <div className='cn-new-community-part'>
                            <div className='cn-new-community-header'>
                                <div className='cn-new-community-text'>
                                    New to the community
                                </div>
                                <div className='cn-new-show-all' onClick={handleCommunity}>Show All</div>
                            </div>
                            <div className='cn-new-author'>
                                {loading ? ( // Conditionally render the loading screen or data
                                    <div className='loading'>
                                        <div className='load'>Loading story</div>
                                    </div>
                                ) : (
                                    <div className='author-card-container'>
                                        {data.map((author, i) => {
                                            return (
                                                <div key={i}>
                                                    <div className='cn-new-author-card'>
                                                        <div className='author-img' onClick={handleAuthorProfile.bind(null, author.author_id, author.author_name)}>
                                                            <img src={author.image_name} alt='author-img' />
                                                        </div>
                                                        <div className='cn-author-name' onClick={handleAuthorProfile.bind(null, author.author_id, author.author_name)}>
                                                            {author.author_name}
                                                        </div>
                                                        <div className='cn-author-design' onClick={handleAuthorProfile.bind(null, author.author_id, author.author_name)}>
                                                            Author
                                                        </div>
                                                        <div className='cn-author-follow' onClick={() => handleAuthorFollow(author.author_id, i)}>
                                                            {author.is_following ? (

                                                                authorFollow[i] ? (
                                                                    <div className='cn-author-follow-inside' onClick={() => followAuthor(author.author_id, i)}>
                                                                        <div className='cn-author-add'>
                                                                            <img src={AddUser} alt='adduser' />
                                                                        </div>
                                                                        <div className='cn-add-follow'>
                                                                            Follow
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className='cn-author-follow-inside' onClick={() => unfollowAuthor(author.author_id, i)}>
                                                                        <div className='cn-author-add'>
                                                                            <img src={Following} alt='adduser' />
                                                                        </div>
                                                                        <div className='cn-add-follow'>
                                                                            Following
                                                                        </div>
                                                                    </div>
                                                                )

                                                            ) : (

                                                                authorFollow[i] ? (
                                                                    <div className='cn-author-follow-inside' onClick={() => unfollowAuthor(author.author_id, i)}>
                                                                        <div className='cn-author-add'>
                                                                            <img src={Following} alt='adduser' />
                                                                        </div>
                                                                        <div className='cn-add-follow'>
                                                                            Following
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className='cn-author-follow-inside' onClick={() => followAuthor(author.author_id, i)}>
                                                                        <div className='cn-author-add'>
                                                                            <img src={AddUser} alt='adduser' />
                                                                        </div>
                                                                        <div className='cn-add-follow'>
                                                                            Follow
                                                                        </div>
                                                                    </div>

                                                                )

                                                            )
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}
                            </div>

                        </div>
                        <div className='cn-new-community-part-2'>
                            <div className='cn-new-community-header'>
                                <div className='cn-new-community-text'>
                                    Authors you may like
                                </div>
                            </div>

                            <div className='cn-new-author'>
                                {loading ? ( // Conditionally render the loading screen or data
                                    <div className='loading'>
                                        <div className='load'>Loading story</div>
                                    </div>
                                ) : (
                                    <div className='author-card-container'>
                                        {maylike.map((author, i) => {
                                            return (
                                                <div key={i}>
                                                    <div className='cn-new-author-card'>
                                                        <div className='author-img' onClick={handleAuthorProfile.bind(null, author.author_id, author.author_name)}>
                                                            <img src={author.image_name} alt='author-img' />
                                                        </div>
                                                        <div className='cn-author-name' onClick={handleAuthorProfile.bind(null, author.author_id, author.author_name)}>
                                                            {author.author_name}
                                                        </div>
                                                        <div className='cn-author-design-2' onClick={handleAuthorProfile.bind(null, author.author_id, author.author_name)}>
                                                            Author
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}

                            </div>

                        </div>
                    </div>
                    <div className='mobile'>
                        <div className='cn-new-community-part'>
                            <div className='cn-new-community-header'>
                                <div className='cn-new-community-text'>
                                    New to the community
                                </div>
                                <div className='cn-new-show-all' onClick={handleCommunity}>Show All</div>
                            </div>
                            <div className='cn-new-author'>
                                {loading ? ( // Conditionally render the loading screen or data
                                    <div className='loading'>
                                        <div className='load'>Loading story</div>
                                    </div>
                                ) : (
                                    <div className='author-card-container'>
                                        {data.map((author, i) => {
                                            return (
                                                <div key={i}>
                                                    <div className='cn-new-author-card'>
                                                        <div className='author-img' onClick={handleAuthorProfile.bind(null, author.author_id, author.author_name)}>
                                                            <img src={author.image_name} alt='author-img' />
                                                        </div>
                                                        <div className='cn-author-name' onClick={handleAuthorProfile.bind(null, author.author_id, author.author_name)}>
                                                            {author.author_name}
                                                        </div>
                                                        <div className='cn-author-design' onClick={handleAuthorProfile.bind(null, author.author_id, author.author_name)}>
                                                            Author
                                                        </div>
                                                        <div className='cn-author-follow' onClick={() => handleAuthorFollow(author.author_id, i)}>
                                                            {author.is_following ? (

                                                                authorFollow[i] ? (
                                                                    <div className='cn-author-follow-inside' onClick={() => followAuthor(author.author_id, i)}>
                                                                        <div className='cn-author-add'>
                                                                            <img src={AddUser} alt='adduser' />
                                                                        </div>
                                                                        <div className='cn-add-follow'>
                                                                            Follow
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className='cn-author-follow-inside' onClick={() => unfollowAuthor(author.author_id, i)}>
                                                                        <div className='cn-author-add'>
                                                                            <img src={Following} alt='adduser' />
                                                                        </div>
                                                                        <div className='cn-add-follow'>
                                                                            Following
                                                                        </div>
                                                                    </div>
                                                                )

                                                            ) : (

                                                                authorFollow[i] ? (
                                                                    <div className='cn-author-follow-inside' onClick={() => unfollowAuthor(author.author_id, i)}>
                                                                        <div className='cn-author-add'>
                                                                            <img src={Following} alt='adduser' />
                                                                        </div>
                                                                        <div className='cn-add-follow'>
                                                                            Following
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className='cn-author-follow-inside' onClick={() => followAuthor(author.author_id, i)}>
                                                                        <div className='cn-author-add'>
                                                                            <img src={AddUser} alt='adduser' />
                                                                        </div>
                                                                        <div className='cn-add-follow'>
                                                                            Follow
                                                                        </div>
                                                                    </div>

                                                                )

                                                            )
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}
                            </div>

                        </div>
                        <div className='cn-new-community-part-2'>
                            <div className='cn-new-community-header'>
                                <div className='cn-new-community-text'>
                                    Authors you may like
                                </div>
                            </div>

                            <div className='cn-new-author'>
                                {loading ? ( // Conditionally render the loading screen or data
                                    <div className='loading'>
                                        <div className='load'>Loading story</div>
                                    </div>
                                ) : (
                                    <div className='author-card-container'>
                                        {maylike.map((author, i) => {
                                            return (
                                                <div key={i}>
                                                    <div className='cn-new-author-card'>
                                                        <div className='author-img' onClick={handleAuthorProfile.bind(null, author.author_id, author.author_name)}>
                                                            <img src={author.image_name} alt='author-img' />
                                                        </div>
                                                        <div className='cn-author-name' onClick={handleAuthorProfile.bind(null, author.author_id, author.author_name)}>
                                                            {author.author_name}
                                                        </div>
                                                        <div className='cn-author-design-2' onClick={handleAuthorProfile.bind(null, author.author_id, author.author_name)}>
                                                            Author
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )}

                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div className='web'>
                <div className='cn-author-bottom'>
                    <div className='cn-author-writing'>
                        <div className='cn-author-new-write'>
                            <div className='cn-web-logo'>
                                <div className='cn-logo'>
                                    <img src={WbsLogo} alt='wbslogo' />
                                </div>
                                <div className='cn-logo-name'>
                                    <span className='cn-logo-wiki'>Wiki</span> Bedtime Stories
                                </div>
                            </div>
                            <div className='cn-author-write-text'>
                                Your place to read, write, and repeat.
                            </div>
                            <div className='cn-author-write-text-two'>
                                Want to get featured on Wiki Bedtime Stories?
                            </div>
                            <div className='cn-author-write-btn' onClick={handleWriteSection}>
                                Start your writing journey!
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default AuthorContent
