/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:10-19-2023
Purpose : Home Screen Search Box
Brief :
SearchBox, represents a search bar UI with suggestions for story titles. It features an input field where users can search for stories, audios, and authors. Upon typing in the search bar, it fetches and displays suggestions based on the entered text. Clicking on a suggestion navigates to the search results page filtered by the clicked suggestion. Additionally, it provides a background image and descriptive text related to the available literary works and authors.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useState, useEffect, useRef } from 'react'
import Banner from '../../../WS-WrittenStories/Assets/Images/BG.webp'
import searchicon from '../../../WS-WrittenStories/Assets/Images/Search.webp'
import { useNavigate, useLocation } from 'react-router-dom';

function SearchBox() {
    const location = useLocation();
    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const [userId, setUserID] = useState(localStorage.getItem('UserID') || null);
    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
    }, []);
    const [suggestions, setSuggestions] = useState([]);
    const [showNoSuggestions, setShowNoSuggestions] = useState(false);
    const [storyCountData, setStoryCountData] = useState(null);
    const suggestionBoxRef = useRef(null);
    const searchParams = new URLSearchParams(location.search);

    const [searchText, setSearchText] = useState('');
    const navigate = useNavigate();
    useEffect(() => {
        const fetchStoryCount = async () => {
            try {
                const response = await fetch(`https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/story_count`);
                if (response.ok) {
                    const data = await response.json();
                    setStoryCountData(data);
                } else {
                    throw new Error('Failed to fetch story count');
                }
            } catch (error) {
                console.error('Error fetching story count:', error);
            }
        };

        fetchStoryCount();
    }, []);
    const handleSearch = async (e) => {
        if (e.key === 'Enter') {
            navigate(`/search-results?searchText=${searchText}`
            );
            window.location.reload(); // Reload the screen
        }
    };
    const handleChange = (e) => {
        const inputValue = e.target.value;
        setSearchText(inputValue);

        // Show suggestions only if there's input and suggestions available
        if (inputValue.trim() !== '' && suggestions.length > 0) {
            setShowSuggestions(true);
        } else {
            setShowSuggestions(false);
        }
    };
    useEffect(() => {
        if (searchText.trim() !== '') {
            fetchSuggestions();
        } else {
            setSuggestions([]);
        }
    }, [searchText]);

    const fetchSuggestions = async () => {
        const PAGE_LIMIT = 10; // Define your page limit
        const page = 1; // Set the page number

        try {
            const response = await fetch(`https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/pages/tags?sortMode=ASC&sortBy=views&user_id=1&tags=${searchText}&pageNumber=${page}&pageSize=${PAGE_LIMIT}&user_id${userId}`);
            if (response.ok) {
                const responseData = await response.json();
                if (responseData && responseData.data && responseData.data.length > 0) {
                    const extractedSuggestions = responseData.data.map(item => ({
                        storyID: item.storyID,
                        title: item.title,
                        summary: item.summary,
                    }));
                    setSuggestions(extractedSuggestions);
                    setShowNoSuggestions(false); // Hide "No suggestions found" message
                } else {
                    setSuggestions([]);
                    setShowNoSuggestions(true); // Show "No suggestions found" message
                }
            } else {
                console.error('Failed to fetch suggestions');
                setSuggestions([]);
                setShowNoSuggestions(true); // Show "No suggestions found" message
            }
        } catch (error) {
            console.error('Error fetching suggestions:', error);
            setSuggestions([]);
            setShowNoSuggestions(true); // Show "No suggestions found" message
        }
    };

    const handleSuggestionClick = (clickedTitle) => {
        navigate(`/search-results?searchText=${clickedTitle}`
        );
        window.location.reload(); // Reload the screen
    };
    const handleClickOutside = (event) => {
        if (suggestionBoxRef.current && !suggestionBoxRef.current.contains(event.target)) {
            setSuggestions([]); // Hide suggestions when clicking outside the box
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const [showSuggestions, setShowSuggestions] = useState(false); // Add state to manage suggestion box visibility

    // Function to handle click on the input field
    const handleInputClick = () => {
        if (suggestions.length > 0) {
            setShowSuggestions(true); // Show suggestions when input field is clicked and suggestions exist
        }
    };
    return (
        <div>
            <div className='ws-search-text-box'>

                <div className="image-text-container">
                    <div className="image-overlay">
                        <img src={Banner} alt="By johnniedavid12412" />
                    </div>
                    <div className="text-container">
                        <div>
                            <div className='ws-search-discover'>Your Story, Our Platform. Let's Create A Magical World!</div>
                            <div className='ws-search-distinct'>
                                {storyCountData && (<strong>{storyCountData.totalAuthors}</strong>)}
                                &nbsp;Authors, {storyCountData && (<strong>{storyCountData.totalStories} </strong>)} stories, enchanting short bedtime stories every night
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default SearchBox
