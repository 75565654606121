/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:10-19-2023
Purpose : User Profile Page
Brief :
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useEffect, useState } from 'react'
import '../../../CS/UserProfile/UserPublishedCard.css'
import NoPublished from '../../../../Assets/UserProfile/NoPublish.webp'
import { useLocation, useNavigate } from 'react-router';


function UserNoPublishedCard() {
    const navigate = useNavigate();
    const location = useLocation();
    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const [userId, setUserID] = useState(localStorage.getItem('UserID') || null);
    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
    }, []);
    const handleWriteSection = () => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            navigate('/writingSection');
        }
    }
    return (
        <div className='UserPublishedCard'>
            <div className='user-nopublish-img'>
                <img src={NoPublished} alt='nopublished' />
            </div>
            <div className='user-nopublish-text'>No Publications</div>
            <div className='user-nopublish-submit'>Submit your publications <span onClick={handleWriteSection} className='user-publish-btn'>here</span></div>
        </div>
    )
}

export default UserNoPublishedCard
