/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:12-12-2023
Purpose : AuthorList
Brief :
Developer : Jayesh Todi
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useEffect } from 'react';
import '../../CS/AuthorPublications.css';
import AuthorPublicationCard from './AuthorPublicationCard';
import AuthorAudioStoryCard from './AuthorAudioStoryCard';
import AuthorFollowerCard from './AuthorFollowerCard';

const AuthorPublications = ({ profileData, data, audioData, setPage, page, totalStories, displayList, authorFollowerData, authorFollowingData, hasMore }) => {
  const handleScroll = (e) => {
    const { clientHeight, scrollHeight, scrollTop } = e.target;
    const isBottom = scrollHeight - (scrollTop + clientHeight) <= 1;
    if (displayList > 3) return;
    if (isBottom && hasMore) {
      console.log('Reached bottom, loading more...');
      setPage(prevPage => prevPage + 1);
    } else if (isBottom) {
      console.log('No more stories to load');
    }
  };
  return (
    <div>
      <div className='web'>
        <div className='AuthorPublicationsMainSection' >
          <div className='AuthorPublicationsHead'>
            {(displayList === 0) ? <div>Stories ({profileData.total_stories})</div> : <></>}
            {(displayList === 1) ? <div>Followers ({profileData.followers_count})</div> : <></>}
            {(displayList === 2) ? <div>Following ({profileData.following_count})</div> : <></>}
            {(displayList === 3) ? <div>Audio Stories ({profileData.audio_story_count})</div> : <></>}
            {(displayList === 4) ? <div>Most Viewed Stories</div> : <></>}
            {(displayList === 5) ? <div>Most Liked Stories</div> : <></>}
            {(displayList === 6) ? <div>Most Saved Stories</div> : <></>}
          </div>
          <div className='AuthorPublicationsCards' onScroll={handleScroll}>
            {(displayList === 0 && data && data.length > 0) ? data.map((story, i) => <AuthorPublicationCard key={i} storyDetails={story} />) : <></>}
            {(displayList === 3 && audioData && audioData.length > 0) ? audioData.map((story, i) => <AuthorAudioStoryCard key={i} storyDetails={story} />) : <></>}
            {(displayList === 1 && authorFollowerData && authorFollowerData.length > 0) ? authorFollowerData.map((fol) => <AuthorFollowerCard follower={fol} displayList={displayList} />) : <></>}
            {(displayList === 2 && authorFollowingData && authorFollowingData.length > 0) ? authorFollowingData.map((fol) => <AuthorFollowerCard follower={fol} displayList={displayList} />) : <></>}
            {(displayList >= 4 && data && data.length > 0) ? data.map((story, i) => <AuthorPublicationCard key={i} storyDetails={story} />) : <></>}
          </div>
        </div>
      </div>
      <div className='mobile'>
        <div className='AuthorPublicationsMainSectionMob' >
          <div className='AuthorPublicationsHead'>
            {(displayList === 0) ? <div>Stories ({profileData.total_stories})</div> : <></>}
            {(displayList === 1) ? <div>Followers ({profileData.followers_count})</div> : <></>}
            {(displayList === 2) ? <div>Following ({profileData.following_count})</div> : <></>}
            {(displayList === 3) ? <div>Audio Stories ({profileData.audio_story_count})</div> : <></>}
            {(displayList === 4) ? <div>Most Viewed Stories</div> : <></>}
            {(displayList === 5) ? <div>Most Liked Stories</div> : <></>}
            {(displayList === 6) ? <div>Most Saved Stories</div> : <></>}
          </div>
          <div className='AuthorPublicationsCards' onScroll={handleScroll}>
            {(displayList === 0 && data && data.length > 0) ? data.map((story, i) => <AuthorPublicationCard key={i} storyDetails={story} />) : <></>}
            {(displayList === 3 && audioData && audioData.length > 0) ? audioData.map((story, i) => <AuthorAudioStoryCard key={i} storyDetails={story} />) : <></>}
            {(displayList === 1 && authorFollowerData && authorFollowerData.length > 0) ? authorFollowerData.map((fol) => <AuthorFollowerCard follower={fol} displayList={displayList} />) : <></>}
            {(displayList === 2 && authorFollowingData && authorFollowingData.length > 0) ? authorFollowingData.map((fol) => <AuthorFollowerCard follower={fol} displayList={displayList} />) : <></>}
            {(displayList >= 4 && data && data.length > 0) ? data.map((story, i) => <AuthorPublicationCard key={i} storyDetails={story} />) : <></>}
          </div>
        </div>
      </div>
    </div>

  )
}

export default AuthorPublications