/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:12-12-2023
Purpose : AuthorCommunity
Brief :
Developer : Amit Kalita
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useEffect, useState } from 'react'
import BG from '../../../Assets/DiscoverAuthor/BG.webp'
import AddUser from '../../../Assets/AuthorImage/Add User.webp'
import Following from '../../../Assets/AuthorImage/following.webp'
import '../../CS/Community.css'
import searchicon from '../../../../WS-WrittenStories/Assets/Images/Search.webp';
import StarFav from '../../../Assets/DiscoverAuthor/StarFav.webp'
import FavButton from '../../../Assets/DiscoverAuthor/FavButton.webp'
import Profile from '../../../../WS-WrittenStories/Assets/Images/defaultprofile.webp'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'
import { CN_Author_Favorite, CN_Author_Follow, CN_Author_UnFavorite, CN_Author_Unfollow } from '../../../../../../Model/CN-ContributionNetwork/ContributionNetwork'


function AuthorCommunityContent({ data,
    loading }) {
    const navigate = useNavigate();
    const location = useLocation();
    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const [userId, setUserID] = useState(localStorage.getItem('UserID') || null);
    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
    }, []);
    const [authorFollow, setAuthorFollow] = useState(new Array(data.length).fill(false));
    const [authorFavor, setAuthorFavor] = useState(new Array(data.length).fill(false));

    const handleAuthorFollow = (author_id, index) => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            console.log('logged in');

            // Create a copy of the bookmarkedStories array
            const updatedAuthorFollow = [...authorFollow];

            // Toggle the bookmarked state for the specific story
            updatedAuthorFollow[index] = !updatedAuthorFollow[index];

            // Update the bookmarked state for all stories
            setAuthorFollow(updatedAuthorFollow);
        }
    }
    const unfollowAuthor = (author_id) => {
        author_id = parseInt(author_id);

        // Define the request body
        const requestBody = {
            author_id: author_id,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .put(CN_Author_Unfollow, requestBody, { headers: requestHeaders })
            .then((response) => {
                // window.location.reload(); // Reload the screen
                // console.log(response.data); // Handle the API response here
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const followAuthor = (author_id) => {
        author_id = parseInt(author_id);

        // Define the request body
        const requestBody = {
            author_id: author_id,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .post(CN_Author_Follow, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const handleAuthorFavor = (author_id, index) => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            console.log('logged in');

            // Create a copy of the bookmarkedStories array
            const updatedAuthorFavor = [...authorFavor];

            // Toggle the bookmarked state for the specific story
            updatedAuthorFavor[index] = !updatedAuthorFavor[index];

            // Update the bookmarked state for all stories
            setAuthorFavor(updatedAuthorFavor);

            // Perform other actions for bookmarking like updating the story if needed
            // updateStoryBookmark(storyID);
        }
    }
    const unfavorAuthor = (author_id) => {
        author_id = parseInt(author_id);
        // Define the request body
        const requestBody = {
            author_id: author_id,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(CN_Author_UnFavorite, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const favorAuthor = (author_id) => {
        author_id = parseInt(author_id);
        // Define the request body
        const requestBody = {
            author_id: author_id,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(CN_Author_Favorite, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const handleAuthorProfile = (author_id, author_name) => {
        const formattedauthorName = author_name.replace(/\s+/g, '-');
        navigate(`/authorprofile/${author_id}/${formattedauthorName}`
        );
        window.scrollTo(0, 0);
    }
    return (
        <div className='cn-community-page'>
            <div className='cn-new-discover-page'>
                <div className='cn-new-community-header'>
                    <div className='cn-new-community-text'>
                        New to the community
                    </div>
                </div>
                <div className='cn-new-author'>
                    {loading ? ( // Conditionally render the loading screen or data
                        <div className='loading'>
                            <div className='load'>Loading story</div>
                        </div>
                    ) : (
                        <div className='discover-author-card-container'>

                            {data.map((author, i) => {
                                return (
                                    <div className='cn-author-followed' key={i}>
                                        <div className='cn-new-author-followed-card'>
                                            <div className='cn-author-img' onClick={handleAuthorProfile.bind(null, author.author_id, author.author_name)}>
                                                <img src={author.image_name} alt='author-img' />
                                            </div>
                                            <div className='cn-follow-author-name' onClick={handleAuthorProfile.bind(null, author.author_id, author.author_name)}>
                                                {author.author_name}
                                            </div>
                                            <div className='cn-follow-author-bio' onClick={handleAuthorProfile.bind(null, author.author_id, author.author_name)}>

                                                {author.biography}
                                            </div>
                                            <div className='web'>

                                                <div className='cn-follow-fav'>
                                                    <div className='cn-author-follow' onClick={() => handleAuthorFollow(author.author_id, i)}>
                                                        {author.is_following ? (

                                                            authorFollow[i] ? (
                                                                <div className='cn-author-follow-inside' onClick={() => followAuthor(author.author_id, i)}>
                                                                    <div className='cn-author-add'>
                                                                        <img src={AddUser} alt='adduser' />
                                                                    </div>
                                                                    <div className='cn-add-follow'>
                                                                        Follow
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className='cn-author-follow-inside' onClick={() => unfollowAuthor(author.author_id, i)}>
                                                                    <div className='cn-author-add'>
                                                                        <img src={Following} alt='adduser' />
                                                                    </div>
                                                                    <div className='cn-add-follow'>
                                                                        Following
                                                                    </div>
                                                                </div>
                                                            )

                                                        ) : (

                                                            authorFollow[i] ? (
                                                                <div className='cn-author-follow-inside' onClick={() => unfollowAuthor(author.author_id, i)}>
                                                                    <div className='cn-author-add'>
                                                                        <img src={Following} alt='adduser' />
                                                                    </div>
                                                                    <div className='cn-add-follow'>
                                                                        Following
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className='cn-author-follow-inside' onClick={() => followAuthor(author.author_id, i)}>
                                                                    <div className='cn-author-add'>
                                                                        <img src={AddUser} alt='adduser' />
                                                                    </div>
                                                                    <div className='cn-add-follow'>
                                                                        Follow
                                                                    </div>
                                                                </div>

                                                            )

                                                        )
                                                        }

                                                    </div>
                                                    <div className='cn-community-author-favorite' onClick={() => handleAuthorFavor(author.author_id, i)}>
                                                        {author.is_favorite ? (
                                                            authorFavor[i] ? (
                                                                <div className='cn-author-follow-inside' onClick={() => favorAuthor(author.author_id, i)}>
                                                                    <div className='cn-author-add'>
                                                                        <img src={StarFav} alt='adduser' />
                                                                    </div>
                                                                    <div className='cn-add-favorite'>
                                                                        Add to favorite
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className='cn-author-follow-inside' onClick={() => unfavorAuthor(author.author_id, i)}>
                                                                    <div className='cn-author-add'>
                                                                        <img src={StarFav} alt='adduser' />
                                                                    </div>
                                                                    <div className='cn-add-favorite'>
                                                                        Added to favorite
                                                                    </div>
                                                                </div>
                                                            )
                                                        ) : (
                                                            authorFavor[i] ?
                                                                (
                                                                    <div className='cn-author-follow-inside' onClick={() => unfavorAuthor(author.author_id, i)}>
                                                                        <div className='cn-author-add'>
                                                                            <img src={StarFav} alt='adduser' />
                                                                        </div>
                                                                        <div className='cn-add-favorite'>
                                                                            Added to favorite
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div className='cn-author-follow-inside' onClick={() => favorAuthor(author.author_id, i)}>
                                                                        <div className='cn-author-add'>
                                                                            <img src={StarFav} alt='adduser' />
                                                                        </div>
                                                                        <div className='cn-add-favorite'>
                                                                            Add to favorite
                                                                        </div>
                                                                    </div>
                                                                )
                                                        )
                                                        }

                                                    </div>


                                                </div>
                                            </div>
                                            <div className='mobile'>
                                                <div className='cn-author-follow' onClick={() => handleAuthorFollow(author.author_id, i)}>
                                                    {author.is_following ? (

                                                        authorFollow[i] ? (
                                                            <div className='cn-author-follow-inside' onClick={() => followAuthor(author.author_id, i)}>
                                                                <div className='cn-author-add'>
                                                                    <img src={AddUser} alt='adduser' />
                                                                </div>
                                                                <div className='cn-add-follow'>
                                                                    Follow
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className='cn-author-follow-inside' onClick={() => unfollowAuthor(author.author_id, i)}>
                                                                <div className='cn-author-add'>
                                                                    <img src={Following} alt='adduser' />
                                                                </div>
                                                                <div className='cn-add-follow'>
                                                                    Following
                                                                </div>
                                                            </div>
                                                        )

                                                    ) : (

                                                        authorFollow[i] ? (
                                                            <div className='cn-author-follow-inside' onClick={() => unfollowAuthor(author.author_id, i)}>
                                                                <div className='cn-author-add'>
                                                                    <img src={Following} alt='adduser' />
                                                                </div>
                                                                <div className='cn-add-follow'>
                                                                    Following
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className='cn-author-follow-inside' onClick={() => followAuthor(author.author_id, i)}>
                                                                <div className='cn-author-add'>
                                                                    <img src={AddUser} alt='adduser' />
                                                                </div>
                                                                <div className='cn-add-follow'>
                                                                    Follow
                                                                </div>
                                                            </div>

                                                        )

                                                    )
                                                    }

                                                </div>
                                                <div className='cn-community-author-favorite' onClick={() => handleAuthorFavor(author.author_id, i)}>
                                                    {author.is_favorite ? (
                                                        authorFavor[i] ? (
                                                            <div className='cn-author-follow-inside' onClick={() => favorAuthor(author.author_id, i)}>
                                                                <div className='cn-author-add'>
                                                                    <img src={StarFav} alt='adduser' />
                                                                </div>
                                                                <div className='cn-add-favorite'>
                                                                    Add to favorite
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className='cn-author-follow-inside' onClick={() => unfavorAuthor(author.author_id, i)}>
                                                                <div className='cn-author-add'>
                                                                    <img src={StarFav} alt='adduser' />
                                                                </div>
                                                                <div className='cn-add-favorite'>
                                                                    Added to favorite
                                                                </div>
                                                            </div>
                                                        )
                                                    ) : (
                                                        authorFavor[i] ?
                                                            (
                                                                <div className='cn-author-follow-inside' onClick={() => unfavorAuthor(author.author_id, i)}>
                                                                    <div className='cn-author-add'>
                                                                        <img src={StarFav} alt='adduser' />
                                                                    </div>
                                                                    <div className='cn-add-favorite'>
                                                                        Added to favorite
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className='cn-author-follow-inside' onClick={() => favorAuthor(author.author_id, i)}>
                                                                    <div className='cn-author-add'>
                                                                        <img src={StarFav} alt='adduser' />
                                                                    </div>
                                                                    <div className='cn-add-favorite'>
                                                                        Add to favorite
                                                                    </div>
                                                                </div>
                                                            )
                                                    )
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default AuthorCommunityContent
