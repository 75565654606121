/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:10-19-2023
Purpose : User Profile Page
Brief :
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DeleteIcon from '../../../../Assets/UserProfile/Delete.webp'
import EditIcon from '../../../../Assets/UserProfile/UmEdit Square.webp'

function UserApprovedCard({ storyDetails }) {
    const navigate = useNavigate();
    const location = useLocation();
    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const [userId, setUserID] = useState(localStorage.getItem('UserID') || null);
    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
    }, []);
    const handleEdit = () => {
        navigate(`/moderatorapprovededitstory/${storyDetails.storyID}/${storyDetails.authorUserID}`
        );
    }
    const [isPopupVisible, setPopupVisible] = useState(false);

    const showPopup = () => {
        setPopupVisible(true);
    };

    const hidePopup = () => {
        setPopupVisible(false);
    };

    const handleDelete = () => {
        // Handle delete logic here
        handleDeleteStory();
    };

    const handleCancel = () => {
        // Handle cancel logic here
        hidePopup();
    };
    const handleDeleteStory = async () => {
        const storyId = String(storyDetails.storyID);  // Convert storyID to a string
        const apiUrl = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/`;

        try {
            const response = await fetch(apiUrl, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    // Add any additional headers as needed
                },
                body: JSON.stringify({
                    story_id: storyId,
                }),
            });

            if (response.ok) {
                console.log('Story deleted successfully');
                // Add any additional logic after successful deletion
            } else {
                console.error('Failed to delete story:', response.statusText);
                // Handle error accordingly
            }
        } catch (error) {
            console.error('Error during delete:', error.message);
            // Handle error accordingly
        }
        hidePopup();
        window.location.reload();
    };
    return (
        <div className='AuthorPublicationCardMainSection'
        >
            <div className='AuthorPublicationCardImgSection' onClick={handleEdit}>

                <img src={storyDetails.storyBackGroundImage} />
            </div>

            <div className='AuthorPublicationCardInfoSection'>

                <div className='AuthorPublicationCardInfoSectionStoryTitleDraft'>
                    <div onClick={handleEdit}>{storyDetails.title}</div>
                    <div className='EditDelete'>
                        <div className='editicon' onClick={handleEdit}>
                            <img src={EditIcon} alt='edit' />
                        </div>
                        <div className='deleteicon' onClick={showPopup}>
                            <img src={DeleteIcon} alt='delete' />
                        </div>
                    </div>

                    {isPopupVisible && (
                        <div className='popup-overlay'>
                            <div className='popup-content'>
                                <p>Are you sure you want to delete?</p>
                                <div className='popup-buttons'>
                                    <button onClick={handleDelete}>Delete</button>
                                    <button onClick={handleCancel}>Cancel</button>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                <div className='AuthorPublicationCardInfoSectionAuthor' onClick={handleEdit}>
                    <span className='AuthorPublicationCardInfoSectionAuthorName'>
                        By {storyDetails.authorName} -
                    </span>
                    <span className='AuthorPublicationCardInfoSectionAuthorPostDate'>
                        {storyDetails.creationDate}
                    </span>
                </div>

                <div className='AuthorPublicationCardInfoSectionStoryBrief' onClick={handleEdit}>
                    {storyDetails.summary}
                </div>

                <div className='AuthorPublicationCardInfoSectionData' onClick={handleEdit}>
                    <div className='AuthorPublicationCardInfoSectionDataViews'>
                        <div className='AuthorPublicationCardInfoSectionDataFirst'>
                            {storyDetails.views}
                        </div>

                        <div className='AuthorPublicationCardInfoSectionDataSecond'>
                            views
                        </div>
                    </div>

                    <div className='AuthorPublicationCardInfoSectionDataRating'>
                        <div className='AuthorPublicationCardInfoSectionDataFirst'>
                            {storyDetails.rating}
                        </div>

                        <div className='AuthorPublicationCardInfoSectionDataSecond'>
                            rating
                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}

export default UserApprovedCard
