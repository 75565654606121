/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:12-19-2023
Purpose : User Profile 
Brief :
Developer : Amit Kalita
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useEffect, useState } from 'react';
import '../../CS/UserProfile/UserCard.css';
import following from '../../../../CN-ContributionNetwork/Assets/AuthorProfile/following.svg';
import stories from '../../../../CN-ContributionNetwork/Assets/AuthorProfile/Document.svg';
import followers from '../../../../CN-ContributionNetwork/Assets/AuthorProfile/3 User.svg';
import audioStories from '../../../../CN-ContributionNetwork/Assets/AuthorProfile/Play.svg';
import followAuthor from '../../../../../../Controller/CN-ContributionNetwork/AuthorProfile/AuthorProfileFollow';
import { useLocation, useNavigate } from 'react-router-dom';
import favoriteAuthor from '../../../../../../Controller/CN-ContributionNetwork/AuthorProfile/AuthorProfileFavorite';
import followersPurple from '../../../../CN-ContributionNetwork/Assets/AuthorProfile/followersPurple.svg';
import followingPurple from '../../../../CN-ContributionNetwork/Assets/AuthorProfile/followingPurple.svg';
import storiesPurple from '../../../../CN-ContributionNetwork/Assets/AuthorProfile/StoriesPurple.svg';
import audioStoriesPurple from '../../../../CN-ContributionNetwork/Assets/AuthorProfile/AudioStoriesPurple.svg';
import Draft from '../../../Assets/UserProfile/Download.svg'
import DraftPurple from '../../../Assets/UserProfile/DownloadPurple.svg'
import user from '../../../../WS-WrittenStories/Assets/Images/defaultprofile.webp'
import Liked from '../../../Assets/UserProfile/Liked.svg'
import LikedPurple from '../../../Assets/UserProfile/LikedPurple.svg'
import Saved from '../../../Assets/UserProfile/Saved.svg'
import SavedPurple from '../../../Assets/UserProfile/SavedPurple.svg'

const UserCard = ({ data, authorId, setData, setDisplayList, displayList, setPage }) => {
    const location = useLocation();
    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const [userId, setUserID] = useState(localStorage.getItem('UserID') || null);
    const [isAdmin, setIsAdmin] = useState(localStorage.getItem('IsAdmin') || null);

    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
        setIsAdmin(localStorage.getItem('IsAdmin') === 'true');
        // setIsAdmin(localStorage.getItem('IsAdmin') || null);
    }, []);
    // console.log(isAdmin)
    const navigate = useNavigate();
    const [isFollowing, setIsFollowing] = useState(false);
    const [isFavorite, setIsFavorite] = useState(false);

    const handleFollowClick = () => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            followAuthor(authorId, authToken);
            setIsFollowing(true);
        }
    }

    const handleFavoriteClick = () => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            favoriteAuthor(authorId, authToken);
            setIsFavorite(true);
        }
    }

    useEffect(() => {
        if (displayList === 0) {
            document.getElementById('AuthorProfileLeftCardFifthStories').style.color = '#593ECC';
            document.getElementById('AuthorProfileLeftCardFifthFollowing').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthAudio').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowers').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthDraft').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthDraft1').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthStories2').style.color = '#593ECC';
            document.getElementById('AuthorProfileLeftCardFifthSaved').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthLiked').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowing2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthReview').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthApproved').style.color = '#474747';

        } else if (displayList === 1) {
            document.getElementById('AuthorProfileLeftCardFifthStories').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowing').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthAudio').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowers').style.color = '#593ECC';

            document.getElementById('AuthorProfileLeftCardFifthStories2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowing2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthAudio2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowers2').style.color = '#593ECC';
            document.getElementById('AuthorProfileLeftCardFifthDraft').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthReview').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthApproved').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthDraft1').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthSaved').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthLiked').style.color = '#474747';
        } else if (displayList === 2) {
            document.getElementById('AuthorProfileLeftCardFifthStories').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowing').style.color = '#593ECC';
            document.getElementById('AuthorProfileLeftCardFifthAudio').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowers').style.color = '#474747';

            document.getElementById('AuthorProfileLeftCardFifthStories2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowing2').style.color = '#593ECC';
            document.getElementById('AuthorProfileLeftCardFifthAudio2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowers2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthDraft').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthReview').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthApproved').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthDraft1').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthSaved').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthLiked').style.color = '#474747';
        } else if (displayList === 3) {
            document.getElementById('AuthorProfileLeftCardFifthStories').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowing').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthAudio').style.color = '#593ECC';
            document.getElementById('AuthorProfileLeftCardFifthFollowers').style.color = '#474747';

            document.getElementById('AuthorProfileLeftCardFifthStories2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowing2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthAudio2').style.color = '#593ECC';
            document.getElementById('AuthorProfileLeftCardFifthFollowers2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthDraft').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthReview').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthApproved').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthDraft1').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthSaved').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthLiked').style.color = '#474747';
        }
        else if (displayList === 5) {
            document.getElementById('AuthorProfileLeftCardFifthStories').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowing').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthAudio').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowers').style.color = '#474747';

            document.getElementById('AuthorProfileLeftCardFifthStories2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowing2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthAudio2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowers2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthDraft').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthReview').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthApproved').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthDraft1').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthSaved').style.color = '#593ECC';
            document.getElementById('AuthorProfileLeftCardFifthLiked').style.color = '#474747';
        }
        else if (displayList === 6) {
            document.getElementById('AuthorProfileLeftCardFifthStories').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowing').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthAudio').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowers').style.color = '#474747';

            document.getElementById('AuthorProfileLeftCardFifthStories2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowing2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthAudio2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowers2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthReview').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthApproved').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthDraft').style.color = '#474747';

            document.getElementById('AuthorProfileLeftCardFifthDraft1').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthSaved').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthLiked').style.color = '#593ECC';
        }

        if (displayList === 4) {
            document.getElementById('AuthorProfileLeftCardFifthStories').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowing').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthAudio').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowers').style.color = '#474747';

            document.getElementById('AuthorProfileLeftCardFifthStories2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowing2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthDraft').style.color = '#593ECC';
            document.getElementById('AuthorProfileLeftCardFifthReview').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthApproved').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthDraft1').style.color = '#593ECC';
            document.getElementById('AuthorProfileLeftCardFifthAudio2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowers2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthSaved').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthLiked').style.color = '#474747';
        }
        if (displayList === 7) {
            document.getElementById('AuthorProfileLeftCardFifthStories').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowing').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthAudio').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowers').style.color = '#474747';

            document.getElementById('AuthorProfileLeftCardFifthStories2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowing2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthDraft').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthReview').style.color = '#593ECC';
            document.getElementById('AuthorProfileLeftCardFifthApproved').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthDraft1').style.color = '#593ECC';
            document.getElementById('AuthorProfileLeftCardFifthAudio2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowers2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthSaved').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthLiked').style.color = '#474747';
        }
        if (displayList === 8) {
            document.getElementById('AuthorProfileLeftCardFifthStories').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowing').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthAudio').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowers').style.color = '#474747';

            document.getElementById('AuthorProfileLeftCardFifthStories2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowing2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthDraft').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthReview').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthApproved').style.color = '#593ECC';
            document.getElementById('AuthorProfileLeftCardFifthDraft1').style.color = '#593ECC';
            document.getElementById('AuthorProfileLeftCardFifthAudio2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthFollowers2').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthSaved').style.color = '#474747';
            document.getElementById('AuthorProfileLeftCardFifthLiked').style.color = '#474747';
        }
    })

    return (
        <div className='AuthorProfileLeftCard'>

            <div className='AuthorProfileLeftCardFirst'>
                <img src={data.ImageName} alt='Author' />
                {/* {data.is_AutorImage
                    ? 
                    <img src={data.ImageName} alt='Author' />
                    :
                    <img src={user} alt='deafault' />

                } */}
            </div>

            <div className='AuthorProfileLeftCardSecond'>
                <div>
                    <span className='AuthorProfileLeftCardSecondName'>{data.Name}</span>
                </div>


            </div>

            <div className='AuthorProfileLeftCardThird'></div>

            <div className='AuthorProfileLeftCardFourth'>
                <div className='AuthorProfileLeftCardFourthUpper'>

                </div>

                <div className='AuthorProfileLeftCardFourthLower'>

                </div>
            </div>

            <div className='AuthorProfileLeftCardThird'></div>

            <div className='AuthorProfileLeftCardFifth'>
                <div onClick={() => { setDisplayList(5); setPage(1) }}>
                    <img id='AuthorProfileLeftCardFifthFollowersImg' src={(displayList === 5) ? SavedPurple : Saved} />
                    <span id='AuthorProfileLeftCardFifthSaved' className='AuthorProfileLeftCardFifthTag'>Saved stories</span>

                </div>
                <div onClick={() => { setDisplayList(6); setPage(1) }}>
                    <img id='AuthorProfileLeftCardFifthFollowersImg' src={(displayList === 6) ? LikedPurple : Liked} />
                    <span id='AuthorProfileLeftCardFifthLiked' className='AuthorProfileLeftCardFifthTag'>Liked stories</span>

                </div>

                <div onClick={() => { setDisplayList(1); setPage(1) }}>
                    <img id='AuthorProfileLeftCardFifthFollowersImg' src={(displayList === 1) ? followersPurple : followers} />
                    <span id='AuthorProfileLeftCardFifthFollowers' className='AuthorProfileLeftCardFifthTag'>Followers</span>
                    <span id='AuthorProfileLeftCardFifthFollowers2'>
                    </span>
                </div>

                <div onClick={() => { setDisplayList(2); setPage(1) }}>
                    <img id='AuthorProfileLeftCardFifthFollowingImg' src={(displayList === 2) ? followingPurple : following} />
                    <span id='AuthorProfileLeftCardFifthFollowing' className='AuthorProfileLeftCardFifthTag'>Following</span>
                    <span id='AuthorProfileLeftCardFifthFollowing2'>
                    </span>
                </div>

                <div onClick={() => { setDisplayList(0); setPage(1) }}>
                    <img id='AuthorProfileLeftCardFifthStoriesImg' src={(displayList === 0) ? storiesPurple : stories} />
                    <span id='AuthorProfileLeftCardFifthStories' className='AuthorProfileLeftCardFifthTag'>Stories</span>
                    <span id='AuthorProfileLeftCardFifthStories2'>
                    </span>
                </div>

                <div onClick={() => { setDisplayList(3); setPage(1) }}>
                    <img id='AuthorProfileLeftCardFifthAudioImg' src={(displayList === 3) ? audioStoriesPurple : audioStories} />
                    <span id='AuthorProfileLeftCardFifthAudio' className='AuthorProfileLeftCardFifthTag'>Audio stories</span>
                    <span id='AuthorProfileLeftCardFifthAudio2'>
                    </span>
                </div>
                <div onClick={() => { setDisplayList(4); setPage(1) }}>
                    <img id='AuthorProfileLeftCardFifthAudioImg' src={(displayList === 4) ? DraftPurple : Draft} />
                    <span id='AuthorProfileLeftCardFifthDraft' className='AuthorProfileLeftCardFifthTag'>Draft stories</span>
                    <span id='AuthorProfileLeftCardFifthDraft1'>
                    </span>
                </div>
                {(isAdmin) ? (
                    <div onClick={() => { setDisplayList(7); setPage(1) }}>
                        <img id='AuthorProfileLeftCardFifthAudioImg' src={(displayList === 7) ? DraftPurple : Draft} />
                        <span id='AuthorProfileLeftCardFifthReview' className='AuthorProfileLeftCardFifthTag'>Review Stories</span>
                        <span id='AuthorProfileLeftCardFifthDraft1'>
                        </span>
                    </div>
                ) : (
                    <div id='AuthorProfileLeftCardFifthAudioImg'>
                        <div id='AuthorProfileLeftCardFifthReview'></div>
                        <div id='AuthorProfileLeftCardFifthDraft1'></div>
                    </div>
                )}
                {(isAdmin) ? (
                    <div onClick={() => { setDisplayList(8); setPage(1) }}>
                        <img id='AuthorProfileLeftCardFifthAudioImg' src={(displayList === 8) ? DraftPurple : Draft} />
                        <span id='AuthorProfileLeftCardFifthApproved' className='AuthorProfileLeftCardFifthTag'>Approved Story</span>
                        <span id='AuthorProfileLeftCardFifthDraft1'>
                        </span>
                    </div>
                ) : (
                    <div id='AuthorProfileLeftCardFifthAudioImg'>
                        <div id='AuthorProfileLeftCardFifthApproved'></div>
                        <div id='AuthorProfileLeftCardFifthDraft1'></div>
                    </div>
                )}
            </div>

        </div>
    )
}

export default UserCard