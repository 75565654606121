/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:12-19-2023
Purpose : User Profile 
Brief :
Developer : Amit Kalita
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useState } from 'react';
import '../../CS/UserProfile/UserDescriptionCard.css';
import starBlank from '../../../../CN-ContributionNetwork/Assets/AuthorProfile/StarBlank.svg';
import starGolden from '../../../../CN-ContributionNetwork/Assets/AuthorProfile/StarGolden.svg';
import starHalf from '../../../../CN-ContributionNetwork/Assets/AuthorProfile/StarHalf.svg';
import starGray from '../../../../CN-ContributionNetwork/Assets/AuthorProfile/StarGrey.svg';
import love from '../../../../CN-ContributionNetwork/Assets/AuthorProfile/Love.svg';
import eye from '../../../../CN-ContributionNetwork/Assets/AuthorProfile/eye.svg';
import EditSquare from '../../../Assets/UserProfile/Edit Square.webp'
import UserEditProfile from './UserEditProfile/UserEditProfile';

const UserDescriptionCard = (
    { profileData, authToken }
) => {
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const handleEditClick = () => {
        setIsPopupOpen(!isPopupOpen);
    };
    return (
        <div className='AuthorDescriptionCardMainSection'>
            <div className='AuthorDescriptionCardFirst'>
                <div className='UserDescriptionCardFirstHead'>
                    <div className='UserDescName'>{profileData.Name}</div>

                    <div className='UserDescimg'><img src={EditSquare} alt='Editsquare' onClick={handleEditClick} /></div>
                </div>
                {profileData.is_biography ? (
                    <div className='AuthorDescriptionCardFirstDesc'>

                        {profileData.biography}
                    </div>
                ) : (
                    <div></div>
                )}

            </div>
            {isPopupOpen && (
                <UserEditProfile setIsPopupOpen={setIsPopupOpen} profileData={profileData} authToken={authToken} />
            )}
            <div className='AuthorDescriptionCardSecond'>

                <div className='AuthorDescriptionCardSecondFirst'>
                    <div className='AuthorDescriptionCardSecondHead'>
                        Story Views
                    </div>
                    <div className='AuthorDescriptionCardSecondInfo'>
                        <div>
                            <img id='AuthorDescriptionCardSecondInfoEye' src={eye} />
                        </div>
                        <div>
                            {profileData.total_views}
                        </div>
                    </div>
                </div>
                <div className='AuthorDescriptionCardSecondSecond'>
                    <div className='AuthorDescriptionCardSecondHead'>
                        Average Rating
                    </div>
                    <div className='AuthorDescriptionCardSecondInfo'>
                        {(profileData.rating <= 0) ?
                            <>
                                <img src={starGray} />
                                <img src={starGray} />
                                <img src={starGray} />
                                <img src={starGray} />
                                <img src={starGray} />
                            </>
                            : <></>}

                        {(profileData.rating > 0.5 && profileData.rating <= 1.0) ?
                            <>
                                <img src={starGolden} />
                                <img src={starGray} />
                                <img src={starGray} />
                                <img src={starGray} />
                                <img src={starGray} />
                            </>
                            : <></>}
                        {(profileData.rating > 1.0 && profileData.rating <= 1.5) ?
                            <>
                                <img src={starGolden} />
                                <img src={starHalf} />
                                <img src={starGray} />
                                <img src={starGray} />
                                <img src={starGray} />
                            </>
                            : <></>}
                        {(profileData.rating > 1.5 && profileData.rating <= 2.0) ?
                            <>
                                <img src={starGolden} />
                                <img src={starGolden} />
                                <img src={starGray} />
                                <img src={starGray} />
                                <img src={starGray} />
                            </>
                            : <></>}
                        {(profileData.rating > 2.0 && profileData.rating <= 2.5) ?
                            <>
                                <img src={starGolden} />
                                <img src={starGolden} />
                                <img src={starHalf} />
                                <img src={starGray} />
                                <img src={starGray} />
                            </>
                            : <></>}
                        {(profileData.rating > 2.5 && profileData.rating <= 3.0) ?
                            <>
                                <img src={starGolden} />
                                <img src={starGolden} />
                                <img src={starGolden} />
                                <img src={starGray} />
                                <img src={starGray} />
                            </>
                            : <></>}
                        {(profileData.rating > 3.0 && profileData.rating <= 3.5) ?
                            <>
                                <img src={starGolden} />
                                <img src={starGolden} />
                                <img src={starGolden} />
                                <img src={starHalf} />
                                <img src={starGray} />
                            </>
                            : <></>}
                        {(profileData.rating > 3.5 && profileData.rating <= 4.0) ?
                            <>
                                <img src={starGolden} />
                                <img src={starGolden} />
                                <img src={starGolden} />
                                <img src={starGolden} />
                                <img src={starGray} />
                            </>
                            : <></>}
                        {(profileData.rating > 4.0 && profileData.rating <= 4.5) ?
                            <>
                                <img src={starGolden} />
                                <img src={starGolden} />
                                <img src={starGolden} />
                                <img src={starGolden} />
                                <img src={starHalf} />
                            </>
                            : <></>}
                        {(profileData.rating > 4.5) ?
                            <>
                                <img src={starGolden} />
                                <img src={starGolden} />
                                <img src={starGolden} />
                                <img src={starGolden} />
                                <img src={starGolden} />

                            </>
                            : <></>}


                    </div>
                </div>
                <div className='AuthorDescriptionCardSecondThird'>
                    <div className='AuthorDescriptionCardSecondHead'>
                        Total Likes
                    </div>
                    <div className='AuthorDescriptionCardSecondInfo'>
                        <div id='author-img-heart'>
                            <img src={love} />
                        </div>
                        <div>
                            {profileData.total_likes}
                        </div>
                    </div>
                </div>
                <div className='AuthorDescriptionCardSecondFourth'>
                    <div className='AuthorDescriptionCardSecondHead'>
                        Added to Favorite
                    </div>
                    <div id='AuthorDescriptionCardSecondInfoStar' className='AuthorDescriptionCardSecondInfo'>
                        <div>
                            <img src={starBlank} />
                        </div>
                        <div>
                            {profileData.add_to_favorite}
                        </div>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default UserDescriptionCard;