/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:12-12-2023
Purpose : AuthorList
Brief :
Developer : Jayesh Todi
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React from 'react';
import '../../CS/AuthorDescriptionCard.css';
import starBlank from '../../../Assets/AuthorProfile/StarBlank.svg';
import starGolden from '../../../Assets/AuthorProfile/StarGolden.svg';
import starHalf from '../../../Assets/AuthorProfile/StarHalf.svg';
import starGray from '../../../Assets/AuthorProfile/StarGrey.svg';
import love from '../../../Assets/AuthorProfile/Love.svg';
import eye from '../../../Assets/AuthorProfile/eye.svg';

const AuthorDescriptionCard = ({ profileData, setDisplayList, setAuthorProfilePublicationsData, setAuthorProfileAudioData, setAuthorProfileFollowersData, setAuthorProfileFollowingData }) => {
    const rating = profileData.rating;
    const setAllArraysAsEmpty = () => {
        setAuthorProfileAudioData([]);
        setAuthorProfileFollowersData([]);
        setAuthorProfileFollowingData([]);
        setAuthorProfilePublicationsData([]);
    }
    return (
        <div >
            <div className='web'>
                <div className='AuthorDescriptionCardMainSection'>

                    <div className='AuthorDescriptionCardFirst'>
                        <div className='AuthorDescriptionCardFirstHead'>
                            {profileData.name}
                        </div>   {profileData.
                            is_biography
                            ?
                            <div className='AuthorDescriptionCardFirstDesc'>   {profileData.biography}</div> :
                            <div></div>
                        }

                    </div>

                    <div className='AuthorDescriptionCardSecond'>

                        <div className='AuthorDescriptionCardSecondFirst'>
                            <div className='AuthorDescriptionCardSecondHead'>
                                Story Views
                            </div>
                            <div className='AuthorDescriptionCardSecondInfo'>
                                <div>
                                    <img id='AuthorDescriptionCardSecondInfoEye' src={eye} />
                                </div>
                                <div>
                                    {profileData.total_views}
                                </div>
                            </div>
                        </div>

                        <div className='AuthorDescriptionCardSecondSecond'>
                            <div className='AuthorDescriptionCardSecondHead'>
                                Average Rating
                            </div>
                            <div className='AuthorDescriptionCardSecondInfo'>
                                {(rating <= 0.5) ?
                                    <>
                                        <img src={starHalf} />
                                        <img src={starGray} />
                                        <img src={starGray} />
                                        <img src={starGray} />
                                        <img src={starGray} />
                                    </>
                                    : <></>}
                                {(rating > 0.5 && rating <= 1.0) ?
                                    <>
                                        <img src={starGolden} />
                                        <img src={starGray} />
                                        <img src={starGray} />
                                        <img src={starGray} />
                                        <img src={starGray} />
                                    </>
                                    : <></>}
                                {(rating > 1.0 && rating <= 1.5) ?
                                    <>
                                        <img src={starGolden} />
                                        <img src={starHalf} />
                                        <img src={starGray} />
                                        <img src={starGray} />
                                        <img src={starGray} />
                                    </>
                                    : <></>}
                                {(rating > 1.5 && rating <= 2.0) ?
                                    <>
                                        <img src={starGolden} />
                                        <img src={starGolden} />
                                        <img src={starGray} />
                                        <img src={starGray} />
                                        <img src={starGray} />
                                    </>
                                    : <></>}
                                {(rating > 2.0 && rating <= 2.5) ?
                                    <>
                                        <img src={starGolden} />
                                        <img src={starGolden} />
                                        <img src={starHalf} />
                                        <img src={starGray} />
                                        <img src={starGray} />
                                    </>
                                    : <></>}
                                {(rating > 2.5 && rating <= 3.0) ?
                                    <>
                                        <img src={starGolden} />
                                        <img src={starGolden} />
                                        <img src={starGolden} />
                                        <img src={starGray} />
                                        <img src={starGray} />
                                    </>
                                    : <></>}
                                {(rating > 3.0 && rating <= 3.5) ?
                                    <>
                                        <img src={starGolden} />
                                        <img src={starGolden} />
                                        <img src={starGolden} />
                                        <img src={starHalf} />
                                        <img src={starGray} />
                                    </>
                                    : <></>}
                                {(rating > 3.5 && rating <= 4.0) ?
                                    <>
                                        <img src={starGolden} />
                                        <img src={starGolden} />
                                        <img src={starGolden} />
                                        <img src={starGolden} />
                                        <img src={starGray} />
                                    </>
                                    : <></>}
                                {(rating > 4.0 && rating <= 4.5) ?
                                    <>
                                        <img src={starGolden} />
                                        <img src={starGolden} />
                                        <img src={starGolden} />
                                        <img src={starGolden} />
                                        <img src={starHalf} />
                                    </>
                                    : <></>}
                                {(rating > 4.5) ?
                                    <>
                                        <img src={starGolden} />
                                        <img src={starGolden} />
                                        <img src={starGolden} />
                                        <img src={starGolden} />
                                        <img src={starGolden} />

                                    </>
                                    : <></>}


                            </div>
                        </div>

                        <div className='AuthorDescriptionCardSecondThird'>
                            <div className='AuthorDescriptionCardSecondHead'>
                                Total Likes
                            </div>
                            <div className='AuthorDescriptionCardSecondInfo'>
                                <div id='author-img-heart' >
                                    <img src={love} />
                                </div>
                                <div>
                                    {profileData.total_likes}
                                </div>
                            </div>
                        </div>

                        <div className='AuthorDescriptionCardSecondFourth'>
                            <div className='AuthorDescriptionCardSecondHead'>
                                Added to Favorite
                            </div>
                            <div id='AuthorDescriptionCardSecondInfoStar' className='AuthorDescriptionCardSecondInfo'>
                                <div>
                                    <img src={starBlank} />
                                </div>
                                <div>
                                    {profileData.add_to_favorite}
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className='AuthorDescriptionCardThird'>
                        <div className='AuthorDescriptionCardThirdHead'>
                            Achievements
                        </div>
                        <div className='AuthorDescriptionCardThirdInfo'>
                            <span onClick={() => { setDisplayList(4); setAllArraysAsEmpty(); }}>
                                Most viewed stories
                            </span>
                            <span onClick={() => { setDisplayList(5); setAllArraysAsEmpty(); }}>
                                Most liked stories
                            </span>
                            <span onClick={() => { setDisplayList(6); setAllArraysAsEmpty(); }}>
                                Most saved stories
                            </span>
                        </div>
                    </div>

                </div>
            </div>
            <div className='mobile'>

            </div>
        </div>

    )
}

export default AuthorDescriptionCard;