/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:12-12-2023
Purpose : AuthorList
Brief :
Developer : Amit Kalita
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React from 'react'
import Header from '../../../../WS-WrittenStories/src/JS/Header/Header'
import Footer from '../../../../WS-WrittenStories/src/JS/Footer/Footer'
import FavoriteAuthorSearchArea from './FavoriteAuthorSearchArea'

const FavoriteAuthorSearch = () => {
  return (
    <div>
      <Header />
      <FavoriteAuthorSearchArea />
      <Footer />
    </div>
  )
}

export default FavoriteAuthorSearch