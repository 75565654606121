/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:01-04-2023
Purpose : This code snippet defines a function named fetchStoryData designed to fetch specific story data from the "wikibedtimestories.com" application. It utilizes the story_detail_story_id endpoint from the WS-WrittenStories/WrittenStories model to retrieve details about a particular story based on its story_id.
Brief : The code presents an asynchronous function called fetchStoryData.
Its purpose is to retrieve detailed information about a story from the "wikibedtimestories.com" application.
It employs the story_detail_story_id endpoint along with the provided story_id parameter to form a URL and make a GET request using fetch to obtain story details.
Upon receiving the response, it extracts the JSON data and returns the first element (result.data[0]), assuming that the API response provides an array of story data.
There's a commented-out line indicating an alternative way to specify the API endpoint using a custom API URL.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import { story_detail_story_id } from '../../Model/WS-WrittenStories/WrittenStories';
export const fetchStoryData = async (story_id, userId, setLoading) => {
    const response = await fetch(`${story_detail_story_id}&userId=${userId}&storyId=${story_id}`);
    const result = await response.json();
    return result.data[0];
};
