/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:12-19-2023
Purpose : User Profile 
Brief :
Developer : Amit Kalita
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useState } from 'react'
import EditPurpleImg from '../../../../Assets/UserProfile/Edit SquarePurple.webp'
import EditIcon from '../../../../Assets/UserProfile/UmEdit Square.webp'
import SocialPurpleImg from '../../../../Assets/UserProfile/webpurple.webp'
import SocialImg from '../../../../Assets/UserProfile/web.webp'
import DeleteIcon from '../../../../Assets/UserProfile/Delete.webp'
import ChatIcon from '../../../../Assets/UserProfile/Chat.webp'
import ProfileIcon from '../../../../Assets/UserProfile/Profile.webp'
import Facebook from '../../../../Assets/UserProfile/Facebook.webp'
import Instagram from '../../../../Assets/UserProfile/Instagram.webp'
import LinkedIn from '../../../../Assets/UserProfile/LinkedIn.webp'
import Twitter from '../../../../Assets/UserProfile/twitter-x.webp'
import user from '../../../../../CN-ContributionNetwork/Assets/user.png'
import '../../../CS/UserProfile/EditUser.css'
import { useNavigate } from 'react-router-dom'

function UserEditProfile({ setIsPopupOpen, profileData, authToken }) {
    const navigate = useNavigate();

    const [nameValue, setNameValue] = useState(profileData.Name);
    const [bioValue, setBioValue] = useState(profileData.biography);
    const [facebookValue, setFacebookValue] = useState(profileData.facebook_url);
    const [instagramValue, setInstagramValue] = useState(profileData.instagram_url);
    const [linkedinValue, setLinkedinValue] = useState(profileData.linkedin_url);
    const [twitterValue, setTwitterValue] = useState(profileData.twitter_url);


    const handleNameTextareaChange = (event) => {
        const inputName = event.target.value;
        setNameValue(inputName)
    };
    const handleBioTextareaChange = (event) => {
        const inputBio = event.target.value;
        setBioValue(inputBio)
    };
    const handleFacebookTextareaChange = (event) => {
        const inputFacebook = event.target.value;
        setFacebookValue(inputFacebook)
    };
    const handleInstagramTextareaChange = (event) => {
        const inputInstagram = event.target.value;
        setInstagramValue(inputInstagram)
    };
    const handleLinkedinTextareaChange = (event) => {
        const inputLinkedin = event.target.value;
        setLinkedinValue(inputLinkedin)
    };
    const handleTwitterTextareaChange = (event) => {
        const inputTwitter = event.target.value;
        setTwitterValue(inputTwitter)
    };
    const handleEditClick = () => {
        setIsPopupOpen(false);
    };
    const [currentView, setCurrentView] = useState('parttwo');
    const handleButtonClick = (view) => {
        setCurrentView(view);
    };
    const handleOverlayClick = (e) => {
        // Check if the clicked element or any of its ancestors has the class 'um-userprofile'
        if (!e.target.closest('.um-userprofile')) {
            // Close the popup only if the clicked element is outside the 'um-userprofile' area
            setIsPopupOpen(false);
        }
    };
    const [coverImgUrl, setCoverImgUrl] = useState(null);
    const [image, setImage] = useState(null);
    const uploadImage = (e) => {
        console.log(e.target.files);
        if (e.target.files && e.target.files[0]) {
            let temp = URL.createObjectURL(e.target.files[0]);
            setCoverImgUrl(URL.createObjectURL(e.target.files[0]));
            console.log(temp);
            const element = document.getElementById('um-userprofile-img-upload');
            element.style.backgroundImage = `url('${temp}')`;
            element.style.backgroundSize = 'cover';
            element.style.backgroundRepeat = 'no-repeat';
            element.style.height = '134px'; // Set your desired height
            element.style.width = '237.84px'; // Set your desired width

            element.style.display = 'block'; // Set your desired heigh
            element.style.borderRadius = '50%'; // Set border-radius to make it a circle
            document.getElementById('um-userprofile-img-upload-part').style.display = 'none';
            setImage(e.target.files[0]);
        }
    }
    const handleSubmit = async () => {
        uploadImageApi();
        // Prepare the data to be sent in the request body
        const requestData = {
            name: nameValue,
            biography: bioValue,
        };

        try {
            // Make the PUT request to update the user profile
            const response = await fetch(`https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/user/edit`, {
                method: 'PUT',
                headers: {
                    Authorization: authToken,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            // Check if the request was successful (status code 2xx)
            if (response.ok) {
                console.log('Profile updated successfully!');
                // Optionally, you can handle any additional logic after successful update
            } else {
                // Handle error scenarios
                console.error('Error updating profile:', response.statusText);
            }
        } catch (error) {
            console.error('Error updating profile:', error.message);
        }
        setTimeout(() => {
            navigate('/', { state: { authToken: authToken, UserID: profileData.UserID, from: 'finalWriteSection' } })
        }, 2000)
    };
    const handleSocialUrl = async () => {
        // Prepare the data to be sent in the request body
        const requestData = {
            facebook_url: facebookValue,
            instagram_url: instagramValue,
            linkedin_url: linkedinValue,
            twitter_url: twitterValue,
        };

        try {
            // Make the PUT request to update the user profile
            const response = await fetch(`https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/user/edit_profile`, {
                method: 'PUT',
                headers: {
                    Authorization: authToken,
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(requestData),
            });

            // Check if the request was successful (status code 2xx)
            if (response.ok) {
                console.log('Profile updated successfully!');
                // Optionally, you can handle any additional logic after successful update
            } else {
                // Handle error scenarios
                console.error('Error updating profile:', response.statusText);
            }
        } catch (error) {
            console.error('Error updating profile:', error.message);
        }
        setTimeout(() => {
            navigate('/', { state: { authToken: authToken, UserID: profileData.UserID, from: 'finalWriteSection' } })
        }, 2000)
    }
    function uploadImageApi() {
        const fileInput = document.getElementById('myImage');
        const file = fileInput.files[0];

        if (file) {
            const formData = new FormData();
            formData.append('myImage', file);
            console.log(profileData.UserID)
            fetch(`https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/user/image_upload?userId=${profileData.UserID}`, {
                method: 'POST',
                body: formData
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok.');
                    }

                    const contentType = response.headers.get('content-type');
                    if (contentType && contentType.indexOf('application/json') !== -1) {
                        return response.json(); // Parse JSON if content-type is JSON
                    } else {
                        return response.blob(); // Get response as Blob if not JSON
                    }
                })
                .then(data => {
                    // Check if data is JSON or Blob
                    if (data instanceof Blob) {
                    } else {
                        // Handle JSON response
                        console.log('Image uploaded:', data);
                        // Do something with the JSON data if needed
                    }
                })
                .catch(error => {
                    // Handle errors
                    console.error('There was a problem with the upload:', error);
                });
        } else {
            console.error('No file selected.');
        }
    }
    const [isPopupVisible, setPopupVisible] = useState(false);

    const showPopup = () => {
        setPopupVisible(true);
    };

    const hidePopup = () => {
        setPopupVisible(false);
    };

    const handleDelete = () => {
        // Handle delete logic here
        handleDeleteAccount();
    };

    const handleCancel = () => {
        // Handle cancel logic here
        hidePopup();
    };
    const handleDeleteAccount = async () => {
        const apiUrl = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/user`;

        try {
            const response = await fetch(apiUrl, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `${authToken}`, // Include the authorization token
                    // Add any additional headers as needed
                },
            });

            if (response.ok) {
                console.log('Account deleted successfully');
                // Add any additional logic after successful deletion
            } else {
                console.error('Failed to delete account:', response.statusText);
                // Handle error accordingly
            }
        } catch (error) {
            console.error('Error during account deletion:', error.message);
            // Handle error accordingly
        }
        hidePopup();
        navigate('/')

    };
    return (
        <div className='um-profile-popupOverlay' onClick={handleOverlayClick}>
            {/* Your popup content goes here */}
            <div className='um-profile-popupContent'>
                {/* Add your editing form or any other content for the popup */}
                <div className='um-userprofile'>
                    <div className='um-user-partone'>
                        <div
                            className={`um-editbtn ${currentView === 'parttwo' ? 'active' : ''}`} onClick={() => handleButtonClick('parttwo')}
                        >
                            <img src={currentView === 'parttwo' ? EditPurpleImg : EditIcon} alt='editimg' />
                            Edit Profile</div>
                        <div
                            className={`um-socialbtn ${currentView === 'partthree' ? 'active' : ''}`} onClick={() => handleButtonClick('partthree')}
                        >
                            <img src={currentView === 'partthree' ? SocialPurpleImg : SocialImg} alt='socialimg' />
                            Social Media</div>
                        <div className='um-deletebtn' onClick={showPopup}>
                            <img src={DeleteIcon} alt='deleteimg' />
                            Delete Account
                        </div>
                        {isPopupVisible && (
                            <div className='popup-overlay'>
                                <div className='popup-content'>
                                    <p>Are you sure you want to delete your account?</p>
                                    <div className='popup-buttons'>
                                        <button onClick={handleDelete}>Delete</button>
                                        <button onClick={handleCancel}>Cancel</button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    {currentView === 'parttwo' && (
                        <div className='um-user-parttwo'>
                            <div className='um-profile-head'>Edit Profile</div>
                            <div className='um-profile-avatar'>Avatar</div>
                            <div className='um-userprofile-img'>
                                <div id='um-userprofile-img-upload' className='um-userprofile-img-upload'>
                                    <img id='um-userprofile-img-upload-part' src={(profileData.ImageName === "https://usc1.contabostorage.com/c90a0b065b91428eb30d29775630936d:wbs-backend/dev/um/users/images/") ? user : profileData.ImageName} />
                                </div>
                                <div className='um-uploadtext'>
                                    <div className='um-userprofile-upload'>
                                        <label htmlFor="myImage" className='um-img-upload'>
                                            <div className='um-img-upload-text'>Upload new images</div>
                                        </label>
                                        <input type="file" name="myImage" id="myImage"
                                            accept="image/*" required
                                            onChange={(e) => uploadImage(e)} placeholder='Choose image from library'
                                            style={{ display: 'none' }} // Optionally hide the input element
                                            rows='1'
                                        />
                                    </div>
                                    <div className='um-userprofile-uploadtext'>At least 500kb recommended.
                                        JPG or PNG is allowed.</div>
                                </div>
                            </div>
                            <div className='um-profile-name'>Name</div>
                            <div className='um-profile-nametext'><img src={ProfileIcon} alt='um-profile' /><input
                                value={nameValue}
                                onChange={handleNameTextareaChange}
                                placeholder='Enter name' /></div>
                            <div className='um-profile-bio'>Bio</div>
                            <div className='um-profile-biotext'><img src={ChatIcon} alt='um-chat' /><textarea
                                value={bioValue}
                                onChange={handleBioTextareaChange}
                                placeholder='Short bio'
                            /></div>
                            <div className='um-profile-savebtn' onClick={handleSubmit}>Save Changes</div>
                        </div>
                    )}
                    {currentView === 'partthree' && (
                        <div className='um-user-partthree'>
                            <div className='um-profile-head'>Social Media</div>
                            <div className='um-profile-name'>Facebook</div>
                            <div className='um-facebook-nametext'><img src={Facebook} alt='um-profile' /><input
                                value={facebookValue}
                                onChange={handleFacebookTextareaChange}
                                placeholder='Enter url'
                            /></div>
                            <div className='um-profile-name'>Instagram</div>
                            <div className='um-instagram-nametext'><img src={Instagram} alt='um-profile' /><input
                                value={instagramValue}
                                onChange={handleInstagramTextareaChange}
                                placeholder='Enter url'
                            /></div>
                            <div className='um-profile-name'>LinkedIn</div>
                            <div className='um-linkedin-nametext'><img src={LinkedIn} alt='um-profile' /><input
                                value={linkedinValue}
                                onChange={handleLinkedinTextareaChange}
                                placeholder='Enter url'
                            /></div>
                            <div className='um-profile-name'>Twitter</div>
                            <div className='um-twitter-nametext'><img src={Twitter} alt='um-profile' /><input
                                value={twitterValue}
                                onChange={handleTwitterTextareaChange}
                                placeholder='Enter url'
                            /></div>
                            <div className='um-profile-savebtn' onClick={handleSocialUrl}>Save Changes</div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

export default UserEditProfile
