/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:10-19-2023
Purpose : Character Page
Brief :
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useEffect, useState } from 'react'
import './Character.css'
import { useLocation } from 'react-router-dom';
function Character({ toggleVisibility }) {
    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const [userId, setUserID] = useState(localStorage.getItem('UserID') || null);
    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
    }, []);
    // State to store character data
    const [characterId, setCharacterId] = useState('');

    const [characterData, setCharacterData] = useState({
        name: '',
        characterCategory: '',
        goodValue: '',
        badValue: '',
        regionId: '0',
        eraId: '0',
        mythologyId: '0',
        epicId: '0',
        folktaleId: '0',
    });
    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Prepare the character data to be sent to the API
        const characterDataToSend = {
            region_id: characterData.regionId,
            era_id: characterData.eraId,
            mythology_id: characterData.mythologyId,
            epic_id: characterData.epicId,
            folktales_id: characterData.folktaleId,
            character_name: characterData.name,
            character_category: characterData.characterCategory,
            defining_good_value: characterData.goodValue,
            defining_bad_value: characterData.badValue,
        };

        try {
            // Send a POST request to the API with the authToken in headers
            const response = await fetch(`https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/metadata/characters`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authToken, // Include the authToken in the headers
                },
                body: JSON.stringify(characterDataToSend),
            });

            if (response.ok) {
                const responseData = await response.json();
                alert('New Character Added')

                // You can check the response data for any specific success indicators.
            } else {
                console.error('Failed to send character data to the API.');
                // You can also log the response status and any error message from the API.
                console.error('API Response Status:', response.status);
                const errorData = await response.json();
                console.error('API Error Message:', errorData);
            }
        } catch (error) {
            console.error('An error occurred while sending character data:', error);
        }
    };
    return (
        <div>
            <button className="back-button" onClick={() => toggleVisibility(false)}>Back</button> {/* Button to hide the Character component */}

            <h1 className='ch_heading'>Create Character</h1>
            <form className="form-container" onSubmit={handleSubmit}>
                {/* Your Character component content */}
                <div>
                    <label htmlFor="name">Character Name:</label>
                    <input className='chinput'
                        type="text"
                        id="name"
                        value={characterData.name}
                        onChange={(e) =>
                            setCharacterData({ ...characterData, name: e.target.value })
                        }
                    />
                </div>
                <div>
                    <label htmlFor="characterCategory">Character Category:</label>
                    <input className='chinput'
                        type="text"
                        id="name"
                        value={characterData.characterCategory}
                        onChange={(e) =>
                            setCharacterData({ ...characterData, characterCategory: e.target.value })
                        }
                    />

                </div>
                <div>
                    <label htmlFor="goodValue">Good Value:</label>
                    <input className='chinput'
                        type="text"
                        id="goodValue"
                        value={characterData.goodValue}
                        onChange={(e) =>
                            setCharacterData({ ...characterData, goodValue: e.target.value })
                        }
                    />
                </div>
                <div>
                    <label htmlFor="badValue">Bad Value:</label>
                    <input className='chinput'
                        type="text"
                        id="badValue"
                        value={characterData.badValue}
                        onChange={(e) =>
                            setCharacterData({ ...characterData, badValue: e.target.value })
                        }
                    />
                </div>
                <div>
                    <label htmlFor="regionId">Region:</label>
                    <select className="ch_select"
                        id="regionId"
                        value={characterData.regionId}
                        onChange={(e) =>
                            setCharacterData({ ...characterData, regionId: e.target.value })
                        }
                    >
                        <option value="0">None</option>
                        <option value="1">Asia</option>
                        <option value="2">South Asia</option>
                        <option value="3">Europe</option>
                        <option value="4">Africa</option>
                        <option value="5">Middle East</option>
                        <option value="6">North America</option>
                        <option value="7">South America</option>
                        <option value="8">Central America</option>
                        <option value="9">Central Asia</option>
                        <option value="10">Australia and Oceania</option>
                        <option value="11">Antarctica</option>

                    </select>
                </div>
                <div>
                    <label htmlFor="eraId">Era:</label>
                    <select className="ch_select"
                        id="eraId"
                        value={characterData.eraId}
                        onChange={(e) =>
                            setCharacterData({ ...characterData, eraId: e.target.value })
                        }
                    >
                        <option value="0">None</option> {/* Default option */}
                        <option value="1">Ancient</option>
                        <option value="2">Medieval</option>
                        <option value="3">Modern</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="epicId">Epic:</label>
                    <select className="ch_select"
                        id="epicId"
                        value={characterData.epicId}
                        onChange={(e) =>
                            setCharacterData({ ...characterData, epicId: e.target.value })
                        }
                    >
                        <option value="0">None</option> {/* Default option */}
                        <option value="1">Mahabharat</option>
                        <option value="2">Ramayana</option>
                        <option value="3">Puranas</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="mythologyId">Mythology:</label>
                    <select className="ch_select"
                        id="mythologyId"
                        value={characterData.mythologyId}
                        onChange={(e) =>
                            setCharacterData({ ...characterData, mythologyId: e.target.value })
                        }
                    >
                        <option value="0">None</option>
                        <option value="1">Hindu</option>
                        <option value="2">Greek</option>
                        <option value="3">Korean</option>
                        <option value="4">Christian</option>
                        <option value="5">Egyptian</option>
                        <option value="6">Japanese</option>
                        <option value="7">Chinese</option>
                    </select>
                </div>
                <div>
                    <label htmlFor="folktaleId">Folktale:</label>
                    <select className="ch_select"
                        id="folktaleId"
                        value={characterData.foktaleId}
                        onChange={(e) =>
                            setCharacterData({ ...characterData, folktaleId: e.target.value })
                        }
                    >
                        <option value="0">None</option>
                        <option value="1">Fables</option>
                        <option value="2">Moral Stories</option>
                        <option value="3">Fairy Tales</option>
                        <option value="4">Akbar Birbal Tales</option>
                        <option value="5">Inspirational Stories</option>
                        <option value="6">Spiritual Stories</option>
                        <option value="7">Chinese Tales</option>
                        <option value="8">Korean Tales</option>
                        <option value="9">Arabian Nights</option>
                        <option value="10">Marathi Tales</option>
                        <option value="11">Vikram Bethal Tales</option>
                        <option value="12">Tamil Folklore</option>
                        <option value="13">Islamic Folklore</option>
                        <option value="14">Rajasthani Tales</option>
                        <option value="15">Funny Stories</option>
                        <option value="16">Sleep Stories</option>
                        <option value="17">Japanese Tales</option>
                        <option value="18">Russian Stories</option>
                        <option value="19">Toddler Stories</option>
                        <option value="20">Festival Stories</option>
                    </select>
                </div>

                <button className='ch_submit' id='submitBtn' type="submit">Create Character</button>
            </form>
        </div>
    )
}

export default Character
