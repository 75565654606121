/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:11-24-2023
Purpose : Final Page of CN
Brief : 
Developer : Jayash Todi
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React from 'react';
import '../CS/ContributionNetworkFinalPage.css';
import GIF from '../../Assets/CN-WS-GIF.gif';

const ContributionNetworkFinalPage = () => {
  return (
    <div id='CN-WS-Fin-Popup' className='CN-WS-Fin'>
      <div>
        <img src={GIF} />
      </div>
      <p id='CN-WS-Fin-upper-text'>Your story has been submitted successfully!</p>
      <p id='CN-WS-Fin-lower-text'>We'll get back to you once your story is audited.</p>
    </div>
  )
}

export default ContributionNetworkFinalPage;