/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:-12-14-2023
Purpose : This code is to provide Controller for ProfileFolloweing.
Brief : Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import axios from 'axios';
import { CN_Author_Followers } from '../../../Model/CN-ContributionNetwork/ContributionNetwork';
const PAGE_LIMIT = 10;
const fetchAuthorProfileFollowers = async (page, authorId, setAuthorProfileFollowersData, existingData, setHasMore) => {

    try {
        const response = await axios.get(`${CN_Author_Followers}`, {
            params: {
                pageNumber: page,
                pageSize: PAGE_LIMIT,
                authorId: authorId,
            }
        });
        const json = response.data;
        setAuthorProfileFollowersData(json.data);

        if (json.data.length < PAGE_LIMIT) {
            setHasMore(false); // No more data to fetch
        }
        const updatedData = [...existingData, ...json.data];
        setAuthorProfileFollowersData(updatedData);

    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export default fetchAuthorProfileFollowers;