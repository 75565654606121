/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:12-12-2023
Purpose : AuthorList
Brief :
Developer : Amit Kalita
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useEffect, useState } from 'react'
import Header from '../../../../WS-WrittenStories/src/JS/Header/Header'
import Footer from '../../../../WS-WrittenStories/src/JS/Footer/Footer'
import FavoriteAuthorContent from './FavoriteAuthorContent'
import { useLocation } from 'react-router-dom'
import fetchAuthorData from '../../../../../../Controller/CN-ContributionNetwork/DiscoverAuthor/DiscoverAuthor'
import fetchGetAuthor from '../../../../../../Controller/CN-ContributionNetwork/DiscoverAuthor/GetFavoriteAuthor'
import Exception from '../../../../AM-AplicationManagement/src/JS/Exception/Exception'
import fetchAuthorNewCommunity from '../../../../../../Controller/CN-ContributionNetwork/FavoriteAuthor/NewCommunity'

function FavoriteAuthor() {
    const [fav, setFav] = useState([]);
    const [data, setData] = useState([]);
    const [getfav, setGetfav] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const [userId, setUserID] = useState(localStorage.getItem('UserID') || null);
    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
    }, []);
    useEffect(() => {
        fetchAuthorNewCommunity(page, userId, setData, setLoading);
    }, [page]);
    useEffect(() => {
        fetchGetAuthor(page, userId, setGetfav, setLoading);
    }, [page]);
    return (
        <div>
            <Header />
            <FavoriteAuthorContent data={data} fav={fav}
                getfav={getfav}
                loading={loading} />
            <Footer />
        </div>
    )
}

export default FavoriteAuthor
