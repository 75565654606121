/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:12-19-2023
Purpose : Google Login 
Brief :
Developer : Amit Kalita
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useEffect, useState } from "react";
import { gapi } from "gapi-script";
import { useNavigate } from "react-router-dom";
import GoogleImg from '../../Assets/google.webp'
import axios from "axios";
const client_id = "854417717353-4epii987kuic14tqh2co0clmig9bhelc.apps.googleusercontent.com";

function GoogleButton() {
    const navigate = useNavigate();
    const [error, setError] = useState(null);

    useEffect(() => {
        function loadGoogleAPI() {
            // Load the Google API library
            gapi.load('auth2', () => {
                // Initialize the Google API client
                gapi.auth2.init({
                    client_id: client_id,
                })
                    .then(() => {
                        console.log('Google API client initialized');
                    })
                    .catch((error) => {
                        console.log('Error initializing Google API client:', error);
                        setError('Failed to initialize Google API client');
                    });
            });
        }

        // Load the Google API library when the component mounts
        loadGoogleAPI();
    }, []);

    const onSuccess = async (googleUser) => { // Add "async" here
        // Extract user data from the response
        const profile = googleUser.getBasicProfile();
        const name = profile.getName();
        const email = profile.getEmail();
        const id_token = googleUser.getAuthResponse().id_token; // Get the id_token
        const NT = profile.NT; // Assuming NT is a property of the profile object
        // Validate email
        if (!email || email === "0") {
            setError("Please provide a valid email address");
            return;
        }

        const loginData = {
            email,
            name,
            password: NT,
            fcmToken: 'zzzz',
            loginType: 'google', // Change this to 'google'
            deviceType: 'android',
        };

        // Replace with actual API call using fetch or axios
        const createUserURL = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/user`; // Make sure to define this URL
        try {
            setLoading(true); // Set loading to true when starting the login operation

            const loginResponse = await fetch(createUserURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginData),
            });

            if (!loginResponse.ok) {
                throw new Error("Failed to store user data");
            }

            const responseData = await loginResponse.json();
            if (responseData.status === 200) {
                const UserID = responseData.data.UserID; // Extract UserID from response data

                if (responseData.data.IsAdmin) { // Change 'data' to 'responseData'
                    // Save UserID and token to local storage
                    localStorage.setItem('UserID', UserID);
                    localStorage.setItem('token', responseData.token);

                    navigate('/');
                    handleWelcome(responseData.token);
                } else {
                    navigate('/'); // Change 'data' to 'responseData'
                    handleWelcome(responseData.token);
                }
            } else {
                if (responseData.status === 451) {
                    // Handle the case where email is already registered
                    console.log('Email is already registered. Redirecting to login...');
                    // Perform the appropriate action (redirect to login or show a message)
                    onLogin(googleUser); // Call the onSuccess function with the same googleUser parameter
                    return;
                }
            }
            // Handle the response from the server
        } catch (error) {
            console.log("Error storing user data:", error.message);
            setError(error.message);
            // Handle the error
        } finally {
            setLoading(false); // Set loading to false regardless of success or failure
        }
    };
    const handleWelcome = async (authToken) => {
        try {
            // Use the authToken parameter in your request headers
            const response = await axios.post(`https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/user/welcome`, {
            }, {
                headers: {
                    'Authorization': authToken
                }
            });

            // Handle the response as needed

        } catch (error) {
            // Handle errors as needed
            console.error('Error during welcome request:', error);
        }
    };

    const onFailure = (error) => {
        console.log("Login failed:", error);
        setError("Failed to authenticate with Google");
        // Handle the error
    };
    const [loading, setLoading] = useState(false);
    const onLogin = async (googleUser) => {
        setLoading(true); // Set loading to true when starting the login operation

        const profile = googleUser.getBasicProfile();
        const email = profile.getEmail();
        const id_token = googleUser.getAuthResponse().id_token;
        const NT = profile.NT;
        const loginData = {
            email,
            password: NT,
            fcmToken: 'zzzz',
            loginType: 'google',
            deviceType: 'react',
        };

        const loginURL = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/user/login`;

        try {
            const response = await fetch(loginURL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(loginData),
            });

            const data = await response.json();

            if (response.status === 200) {
                console.log(data)
                const UserID = data.data.UserID;
                const Image = data.data.ImageName;
                const ImageExist = data.data.ImageExist;
                // Save UserID and token to local storage
                localStorage.setItem('UserID', UserID);
                localStorage.setItem('token', data.token);
                localStorage.setItem('ImageName', Image);
                localStorage.setItem('ImageExist', ImageExist);

                if (data.data.IsAdmin) {
                    // Save UserID and token to local storage
                    localStorage.setItem('UserID', UserID);
                    localStorage.setItem('token', data.token);
                    localStorage.setItem('ImageName', Image);
                    localStorage.setItem('ImageExist', ImageExist);

                    navigate('/'
                    );
                } else {
                    navigate('/'
                    );
                }
            } else {
                console.log('Login failed.');
            }
        } catch (error) {
            console.log('Login error:', error);
        } finally {
            setLoading(false); // Set loading to false regardless of success or failure
        }
    };


    return (
        <div
            onClick={() => {
                const auth2 = gapi.auth2.getAuthInstance();
                auth2.signIn()
                    .then(onSuccess)
                    .catch(onFailure);
            }}
            className='um-login-google'>
            <img src={GoogleImg} alt='google' />
            {loading && (
                <div className="loading-overlay">
                    {/* Add your loading spinner or message here */}
                    Loading...
                </div>
            )}
        </div>
    );
}

export default GoogleButton;
