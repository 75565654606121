/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:12-12-2023
Purpose : AuthorList
Brief :
Developer : Jayesh Todi
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React from 'react'
import '../../CS/AuthorSocialMediaCard.css';
import facebook from '../../../Assets/AuthorProfile/Facebook.svg';
import instagram from '../../../Assets/AuthorProfile/Instagram.svg';
import linkedin from '../../../Assets/AuthorProfile/Linkedin.svg';
import twitter from '../../../Assets/AuthorProfile/Twitter.svg';
import facebookdisable from '../../../Assets/AuthorProfile/FacebookDisable.svg';
import instagramdisable from '../../../Assets/AuthorProfile/InstagramDisable.svg';
import linkedindisable from '../../../Assets/AuthorProfile/LinkedInDisable.svg';
import twitterdisable from '../../../Assets/AuthorProfile/twitter-xDisable.svg';

const AuthorSocialMediaCard = ({ data }) => {
  return (
    <div>
      <div className='web'>
        <div className='AuthorSocialMediaCardMainSection'>
          <div className='AuthorSocialMediaCardMainSectionFirst'>
            <span>Follow me</span>
          </div>
          <div className='AuthorSocialMediaCardMainSectionSecond'>
            <span>Connect</span>
          </div>
          <div className='AuthorSocialMediaCardMainSectionThird'>
            {data.is_facebook ? (
              <a href={data.facebook_url}>
                <img id='fbSocialMediaAuthor' src={facebook} alt='Facebook' />
              </a>
            ) : (
              <a href={data.facebook_url}><img id='fbSocialMediaAuthordisable' src={facebookdisable} /></a>
            )}
            {data.is_instagram ? (
              <a href={data.instagram_url}><img id='instSocialMediaAuthor' src={instagram} /></a>

            ) : (
              <a href={data.instagram_url}><img id='instSocialMediaAuthordisable' src={instagramdisable} /></a>
            )}
            {data.is_linkedin ? (
              <a href={data.linkedin_url}><img id='linkedinSocialMediaAuthor' src={linkedin} /></a>

            ) : (
              <a href={data.linkedin_url}><img id='linkedinSocialMediaAuthordisable' src={linkedindisable} /></a>
            )}
            {data.is_twitter ? (
              <a href={data.twitter_url}><img id='twitterSocialMediaAuthor' src={twitter} /></a>

            ) : (<a href={data.twitter_url}><img id='twitterSocialMediaAuthordisable' src={twitterdisable} /></a>
            )}
          </div>
        </div>
      </div>
      <div className='mobile'>

      </div>
    </div>

  )
}

export default AuthorSocialMediaCard