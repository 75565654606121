/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:10-19-2023
Purpose : Not Found Page
Brief :
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React from 'react'
import '../../CS/NotFound.css'
import BackArrow from '../../../Assets/Images/Arrow - Left.webp'
import Header from '../../../../WS-WrittenStories/src/JS/Header/Header'
import Footer from '../../../../WS-WrittenStories/src/JS/Footer/Footer'
import { useNavigate } from 'react-router-dom'

function NotFound() {
    const navigate = useNavigate();
    return (
        <div>
            <Header />
            <div className='am-notfound'>
                <div className='am-notfound-404'>404</div>
                <div className='am-notfoundwhop'>Whoops! we can't find that page!</div>
                <div className='am-notfound-page'>Sorry, the page you're looking for doesn't exist. If you think <br />something is broken, report a problem.</div>
                <div className='am-return-btn-text'>
                    <div className='am-return-btn' onClick={() => { navigate('/') }}>
                        <img src={BackArrow} alt='backbtn' />
                    </div>
                    <div className='am-return-home' onClick={() => { navigate('/') }}>Return to Home Page</div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default NotFound
