/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:12-12-2023
Purpose : AuthorList
Brief :
Developer : Amit Kalita
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useEffect, useState } from 'react'
import BG from '../../../Assets/DiscoverAuthor/BGFavorite.webp'
import searchicon from '../../../../WS-WrittenStories/Assets/Images/Search.webp';
import FavoriteAuthorSearchCard from './FavoriteAuthorSearchCard';
import '../../CS/FavoriteAuthorSearchArea.css';
import fetchFavAuthorSearchData from '../../../../../../Controller/CN-ContributionNetwork/DiscoverAuthor/FavoriteAuthorSearch'
import SearchResult from './SearchResult';
import { useLocation } from 'react-router-dom';

const FavoriteAuthorSearchArea = () => {
    const location = useLocation();
    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const [userId, setUserID] = useState(localStorage.getItem('UserID') || null);
    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
    }, []);
    let currentUrl = window.location.href;
    let searchText = '';
    for (let i = currentUrl.length - 1; currentUrl[i] != '/'; i--) {
        searchText = currentUrl[i] + searchText;
    }
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true); // Initially set to true

    const [errorMessage, setErrorMessage] = useState(null); // State to store error message
    useEffect(() => {
        fetchFavAuthorSearchData(userId, searchText, setData, setErrorMessage, setLoading);
    }, [userId, searchText]);

    return (
        <div id='FavoriteAuthorSearchAreaSearchBar'>
            <div className='cn-image-text-container'>
                <div className='cn-discover-author-bg'>
                    <img src={BG} alt='background' />
                </div>
                <div className='cn-text-container'>
                    <div className='cn-bg-discover-author'>
                        <div className='cn-discover'>Favorite Authors</div>

                        <div className='cn-explore'>The most loved and favorite authors across the Wiki Bedtime Stories Community!</div>
                        <div className='cn-discover-search'>
                            <SearchResult />

                        </div>

                    </div>
                </div>
            </div>
            <div className='FavoriteAuthorSearchAreaSearchResults'>
                Search Results for : {searchText}
            </div>
            <div className='FavoriteAuthorSearchAreaContent'>
                {loading ? (
                    <div className="loading-overlay">
                        {/* Add your loading spinner or message here */}
                        Loading...
                    </div>
                ) : (
                    <>
                        {data && data.length > 0 ? (
                            data.map((authorData, i) => (
                                <FavoriteAuthorSearchCard
                                    key={i}
                                    author={authorData}
                                    index={i}
                                    dataLength={data.length}
                                />
                            ))
                        ) : (
                            <div className='FA-NoAuthor '>No authors found.</div>
                        )}
                    </>
                )}
                {errorMessage && <p>{errorMessage}</p>}
            </div>
        </div>
    )
}

export default FavoriteAuthorSearchArea