/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:12-12-2023
Purpose : AuthorList
Brief :
Developer : Jayesh Todi
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React from 'react'
import '../../CS/AuthorFollowerCard.css'
import user from '../../../../WS-WrittenStories/Assets/Images/defaultprofile.webp'
import { useNavigate } from 'react-router-dom';

const AuthorFollowerCard = ({ follower, displayList }) => {
    const navigate = useNavigate();
    const authorview = (authorId, author_name) => {
        // window.location.reload();
        const formattedauthorName = author_name.replace(/\s+/g, '-');
        navigate(`/authorprofile/${authorId}/${formattedauthorName}`
        );
        window.location.reload();
        window.scrollTo(0, 0);
    }

    return (
        <div className='AuthorFollowerCardMainSection'>
            <div className='AuthorFollowerCardImage'
                onClick={() => authorview(
                    displayList === 1 ? follower.user_id : follower.author_id,
                    displayList === 1 ? follower.name : follower.author_name
                )}

            >
                <img
                    style={{ width: "72px", height: "72px" }}
                    src={
                        displayList === 1
                            ? (follower.image_name)
                            : (displayList === 2
                                ? (follower.image_name)
                                : user)
                    }
                />

            </div>
            <div className='AuthorFollowerCardInfo'
                onClick={() => authorview(
                    displayList === 1 ? follower.user_id : follower.author_id,
                    displayList === 1 ? follower.name : follower.author_name
                )}
            >
                <div className='AuthorFollowerCardName'>
                    {(displayList === 1) ? follower.name : follower.author_name}
                </div>
                <div className='AuthorFollowerCardAuthor'>
                    Author
                </div>
            </div>
        </div>
    )
}

export default AuthorFollowerCard