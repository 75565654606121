/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:10-19-2023
Purpose : Cookie DisClaimer Page
Brief :
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React from 'react'
import '../../CS/Cookie.css'
import Header from '../../../../WS-WrittenStories/src/JS/Header/Header'
import Footer from '../../../../WS-WrittenStories/src/JS/Footer/Footer'

function CookieDisclaimer() {
    return (
        <div>
            <Header />
            <div className='am-cookie-page'>
                <div className='am-cookie'>
                    Cookie disclaimer
                </div>
                <p className='am-cookie-para'>
                    This website uses cookies.
                </p>
                <p className='am-cookie-para'>
                    We use cookies to personalise content and ads, to provide social media features and to analyse our traffic. We also share information about your use of our site with our social media, advertising and analytics partners who may combine it with other information that you've provided to them or that they've collected from your use of their services.
                </p>
            </div>
            <Footer />
        </div>
    )
}

export default CookieDisclaimer
