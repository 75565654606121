/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:11-1-2023
Purpose : Preview Part in Contribution Network
Brief :
Developer : Jayash Todi.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useEffect, useRef, useState } from 'react';
import plusIcon from '../../../Assets/Plus.webp';
import upload from '../../../Assets/Upload.webp'
import ImageIcon from '../../../Assets/Image.webp';
import HappyPot from '../../../Assets/HappyPot.png';
import Danger from '../../../Assets/Danger Circle.webp'
import PopupImage from '../../../Assets/PopUpBox.webp'
import { useLocation, useNavigate } from 'react-router-dom';
import { CN_Characters, CN_Draft, CN_Tags, CN_DraftPatch } from '../../../../../../Model/CN-ContributionNetwork/ContributionNetwork';
import axios from 'axios';
import Character from './Character';

const ModeratorPreview = (props) => {

    const navigate = useNavigate();
    let location = useLocation();

    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const [userId, setUserID] = useState(localStorage.getItem('UserID') || null);
    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
    }, []);

    const handleReject = async (e) => {
        // Check if the checkbox is checked
        var checkbox = document.querySelector('.CN-TNC-Box input[type="checkbox"]');
        if (checkbox.checked) {
            const submitUrl = CN_DraftPatch;
            const tagsUrl = CN_Tags;
            const charactersUrl = CN_Characters;
            const data = {
                title: props.titleData,
                story: props.storyData,
                conclusion: props.messageData,
                summary: props.beginningData,
                status: 1, // Status 1 for draft
                story_id: String(props.storyID)
            };

            const response = await fetch(submitUrl, {
                method: 'PATCH', // note the lowercase 'method'
                headers: {
                    Authorization: authToken,
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
                cache: 'default'
            });

            if (response.ok) {
                const jsonData = await response.json();
            } else {
                console.error('Request failed with status:', response.status);
            }
            try {
                const response = await axios.post(
                    `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/metadata/map_story_character`,
                    {
                        story_id: String(props.storyID),
                        character_id: String(storedCharacterId), // Convert tags array to comma-separated string
                    },
                    {
                        headers: {
                            Authorization: authToken,
                        },
                    }
                );

                if (response.status === 200) {
                    alert('Character Added')
                    // Perform any additional actions after adding tags
                } else {
                    console.log('Failed to add tags.');
                }
            } catch (error) {
                if (error.response) {
                    console.error('Server Error:', error.response);
                } else if (error.request) {
                    console.error('Request Error:', error.request);
                } else {
                    console.error('Unknown Error:', error.message);
                }
            }
            uploadImageApi();

            document.getElementById('CN-WS').style.display = 'none';
            document.getElementById('writing-section-preview-popup').style.display = 'none';
            setTimeout(() => {
                navigate('/', { state: { authToken: authToken, UserID: userId, from: 'finalWriteSection' } })
            }, 3000)
        }
        else {
            // Checkbox is not checked, show an alert
            alert('Please Tick the declaration before submitting.');
        }
    }
    const handleSubmit = async (e) => {
        console.log(props.altData);
        console.log(props.metaData);
        const submitUrl = CN_DraftPatch;
        const tagsUrl = CN_Tags;
        const charactersUrl = CN_Characters;
        const data = {
            title: props.titleData,
            story: props.storyData,
            conclusion: props.messageData,
            summary: props.beginningData,
            alt_text:props.altData,
            meta_description:props.metaData,
            status: 3, // Status 1 for draft
            story_id: String(props.storyID)
        };

        const response = await fetch(submitUrl, {
            method: 'PATCH', // note the lowercase 'method'
            headers: {
                Authorization: authToken,
                Accept: 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data),
            cache: 'default'
        });

        if (response.ok) {
            const jsonData = await response.json();
            handleSubmitEmail(Number(props.storyID), props.authorUserID)
            await characterAdd(authToken, props, storedCharacterId);
        } else {
            console.error('Request failed with status:', response.status);
        }
        uploadImageApi();

        document.getElementById('CN-WS').style.display = 'none';
        document.getElementById('writing-section-preview-popup').style.display = 'none';
        navigate('/', { state: { authToken: authToken, UserID: userId, from: 'finalWriteSection' } })

    }
    async function characterAdd(authToken, props, storedCharacterId) {
        try {
            const response = await axios.post(
                `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/metadata/map_story_character`,
                {
                    story_id: String(props.storyID),
                    character_id: storedCharacterId.join(','), // Assuming storedCharacterId is an array
                },
                {
                    headers: {
                        Authorization: authToken,
                    },
                }
            );

            if (response.status === 200) {
                alert('Character Added');
                // Perform any additional actions after adding tags
            } else {
                console.log('Failed to add tags.');
            }
        } catch (error) {
            if (error.response) {
                console.error('Server Error:', error.response);
            } else if (error.request) {
                console.error('Request Error:', error.request);
            } else {
                console.error('Unknown Error:', error.message);
            }
        }
    }
    const handleSubmitEmail = async (storyId, userId) => {
        let data = JSON.stringify({
            "userId": parseInt(userId), // Use the userId parameter
            "storyID": parseInt(storyId) // Use the storyId parameter
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/publish_story_email`,
            headers: {
                'Content-Type': 'application/json',
            },
            data: data
        };

        try {
            const response = await axios.request(config);
            console.log(JSON.stringify(response.data));
        } catch (error) {
            console.log(error);
        }
    };
    function uploadImageApi() {
        const fileInput = document.getElementById('myImage');
        const file = fileInput.files[0];

        if (file) {
            const formData = new FormData();
            formData.append('myImage', file);


            fetch(`https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/image_upload?storyId=${props.storyID}`, {
                method: 'POST',
                body: formData
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok.');
                    }

                    const contentType = response.headers.get('content-type');
                    if (contentType && contentType.indexOf('application/json') !== -1) {
                        return response.json(); // Parse JSON if content-type is JSON
                    } else {
                        return response.blob(); // Get response as Blob if not JSON
                    }
                })
                .then(data => {
                    // Check if data is JSON or Blob
                    if (data instanceof Blob) {
                        // Handle response as a file/blob (non-JSON response)
                        // Process the file/blob data here if needed
                    } else {
                        // Handle JSON response
                        console.log('Image uploaded:', data);
                        // Do something with the JSON data if needed
                    }
                })
                .catch(error => {
                    // Handle errors
                    console.error('There was a problem with the upload:', error);
                });
        } else {
            console.error('No file selected.');
        }
    }


    const [image, setImage] = useState(null);
    const [coverImgUrl, setCoverImgUrl] = useState(props.storyImg);
    const uploadImage = (e) => {
        if (e.target.files && e.target.files[0]) {
            let temp = URL.createObjectURL(e.target.files[0]);
            setCoverImgUrl(temp);
            document.getElementById('right-lower-cover-image').style.backgroundImage = `url('${temp}')`;
            document.getElementById('right-lower-cover-image').style.backgroundSize = 'cover';
            document.getElementById('right-lower-cover-image').style.backgroundRepeat = 'no-repeat';
            document.getElementById('right-lower-cover-image-icon').style.display = 'none';
            document.getElementById('right-lower-cover-image-text').style.display = 'none';
            setImage(e.target.files[0]);
        }
    }
    useEffect(() => {
        // Set the background image when the component mounts or when props.storyImg changes
        document.getElementById('right-lower-cover-image').style.backgroundImage = `url('${coverImgUrl}')`;
        document.getElementById('right-lower-cover-image').style.backgroundSize = 'cover';
        document.getElementById('right-lower-cover-image').style.backgroundRepeat = 'no-repeat';
        document.getElementById('right-lower-cover-image-icon').style.display = 'none';
        document.getElementById('right-lower-cover-image-text').style.display = 'none';
    }, [coverImgUrl, props.storyImg]);

    let isComponentVisible = props.isComponentVisible;
    const [tags, setTags] = useState([]); // State to store tags
    const [inputValue, setInputValue] = useState(''); // State to track input value


    const [storedCharacterId, setStoredCharacterId] = useState([]);
    const [charsub, setCharsub] = useState([]); // State to store tags
    const [charcatergory, setCharcategory] = useState([]);
    const addTag = (newTag, newCharcategory, newCharsub, charId) => {
        const trimmedSearchText = newTag.trim();
        const trimmedCharacterCategory = newCharcategory.trim();
        const trimmedCharacter = newCharsub.trim();
        if (trimmedSearchText !== '') {
            if (tags.length < 10 && !tags.includes(trimmedSearchText)) {
                setTags([...tags, trimmedSearchText]);
                setCharcategory([...charcatergory, trimmedCharacterCategory]);
                setCharsub([...charsub, trimmedCharacter]);
                setSearchText('');
                // Update storedCharacterId by adding the new charId
                setStoredCharacterId(prevIds => [...prevIds, charId]);
            } else {
                alert("You can only add one character!");
            }
        }
    };

    // Function to remove a tag
    const removeTag = (tag) => {
        setTags(tags.filter((t) => t !== tag));
    };
    const fileInputRef = useRef(null);
    const handleBoxClick = () => {
        fileInputRef.current.click();
    };
    const [isPopupOpen, setIsPopupOpen] = useState(true);

    const handleDangerClick = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    const handlePopupClose = () => {
        setIsPopupOpen(false);
    };

    const handleOutsideClick = (e) => {
        // Check if the click is outside the popup and the danger image
        if (!e.target.closest('.popup') && !e.target.closest('.cn-danger-upload')) {
            setIsPopupOpen(false);
        }
    };
    const [isCharacterVisible, setCharacterVisible] = useState(false); // State variable to manage visibility

    const toggleCharacterVisibility = (isVisible) => {
        setCharacterVisible(isVisible);
    };




    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
    }, []);
    const [suggestions, setSuggestions] = useState([]);
    const [showNoSuggestions, setShowNoSuggestions] = useState(false);
    const [storyCountData, setStoryCountData] = useState(null);
    const suggestionBoxRef = useRef(null);
    const searchParams = new URLSearchParams(location.search);
    const totalStories = searchParams.get('totalStories');
    const [searchText, setSearchText] = useState('');
    useEffect(() => {
        const fetchStoryCount = async () => {
            try {
                const response = await fetch(`https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/story_count`);
                if (response.ok) {
                    const data = await response.json();
                    setStoryCountData(data);
                } else {
                    throw new Error('Failed to fetch story count');
                }
            } catch (error) {
                console.error('Error fetching story count:', error);
            }
        };

        fetchStoryCount();
    }, []);
    const handleSearch = async (e) => {
        if (e.key === 'Enter') {
            addTag();
        }
    };
    const handleChange = (e) => {
        const inputValue = e.target.value;
        setSearchText(inputValue);

        // Show suggestions only if there's input and suggestions available
        if (inputValue.trim() !== '' && suggestions.length > 0) {
            setShowSuggestions(true);
        } else {
            setShowSuggestions(false);
        }
    };
    useEffect(() => {
        if (searchText.trim() !== '') {
            fetchSuggestions();
        } else {
            setSuggestions([]);
        }
    }, [searchText]);
    const fetchSuggestions = async () => {
        const PAGE_LIMIT = 5;
        const page = 1;

        try {
            const response = await fetch(`https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/metadata/search_character?sortMode=ASC&sortBy=character_name&character=${searchText}&pageNumber=${page}&pageSize=${PAGE_LIMIT}`);

            if (response.ok) {
                const responseData = await response.json();
                if (responseData && responseData.data && responseData.data.length > 0) {
                    const extractedSuggestions = responseData.data.map(item => {
                        return {
                            storyID: item.storyID,
                            title: item.title,
                            summary: item.summary,
                            character_name: item.character_name,
                            character_category: item.character_category,
                            region_name: item.region_name,
                            era_name: item.era_name,
                            mythology_name: item.mythology_name,
                            epic_name: item.epic_name,
                            folktales_name: item.folktales_name,
                            character_id: item.character_id,
                        };
                    });
                    setSuggestions(extractedSuggestions);
                    setShowNoSuggestions(false);
                } else {
                    setSuggestions([]);
                    setShowNoSuggestions(true);
                }
            } else {
                console.error('Failed to fetch suggestions');
                setSuggestions([]);
                setShowNoSuggestions(true);
            }
        } catch (error) {
            console.error('Error fetching suggestions:', error);
            setSuggestions([]);
            setShowNoSuggestions(true);
        }
    };
    const handleSuggestionClick = (characterName, characterCat, regionName, eraName, mythologyName, epicName, folktalesName, characterId) => {

        const newTag = `${characterName}  `;
        const newCharcategory = `${characterCat}`
        const newCharsub = `${regionName}-${eraName}-${mythologyName}-${epicName}-${folktalesName}`

        const charId = characterId
        addTag(newTag, newCharcategory, newCharsub, charId);
        setShowSuggestions(false);
    };
    const handleClickOutside = (event) => {
        if (suggestionBoxRef.current && !suggestionBoxRef.current.contains(event.target)) {
            setSuggestions([]); // Hide suggestions when clicking outside the box
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const [showSuggestions, setShowSuggestions] = useState(false); // Add state to manage suggestion box visibility

    // Function to handle click on the input field
    const handleInputClick = () => {
        if (suggestions.length > 0) {
            setShowSuggestions(true); // Show suggestions when input field is clicked and suggestions exist
        }
    };


    return (
        <div id='writing-section-preview-popup' className='main' onClick={handleOutsideClick}>
            <div className='left'>
                {isCharacterVisible ? (
                    <div></div>
                ) : (


                    <div className='cn-stories-pr'>
                        <div className='left-upper'>
                            {isCharacterVisible ? (
                                <button className='cancelBtn' onClick={() => toggleCharacterVisibility(false)}>
                                    Hide Character
                                </button>
                            ) : (
                                <button className="cool-button" onClick={() => toggleCharacterVisibility(true)}>
                                    Add Character
                                </button>
                            )}
                            <div className='CN-Preview-headings'>Character</div>
                            <div className='cn-preview-box'>
                                <div className='CN-Preview-input' id='CN-preview-input-tags'>

                                    <input
                                        type="text"
                                        value={searchText}
                                        onChange={handleChange}
                                        onKeyPress={handleSearch}
                                        id="searchInput"
                                        onClick={handleInputClick} // Add onClick event to handle input click
                                        placeholder='Add keywords to help people discover'
                                        rows='1' />

                                </div>
                                <div className='plus-img'><img alt='plus' src={plusIcon} /></div>
                            </div>
                            {showSuggestions && (
                                <div className='suggestion-box' ref={suggestionBoxRef}>
                                    {suggestions && suggestions.length > 0 ? (
                                        suggestions.map((suggestion, index) => (
                                            <div key={index}>
                                                <div onClick={() => handleSuggestionClick(suggestion.character_name, suggestion.character_category, suggestion.region_name, suggestion.era_name, suggestion.mythology_name, suggestion.epic_name, suggestion.folktales_name, suggestion.character_id)}>{suggestion.character_name}</div>
                                            </div>
                                        ))
                                    ) : null}
                                    {showNoSuggestions && searchText.length > 0 && suggestions.length === 0 && (
                                        <div className='no-suggestion'>No suggestions found</div>
                                    )}
                                </div>
                            )}
                            {tags.map((tag, index) => (
                                <div className='character-container'>
                                    <div className='tagflex'>
                                        <div key={tag} >
                                            <div className='tag' style={{ marginBottom: '4px' }}>Character Name : {tag}</div>
                                            <div className='tag' style={{ marginBottom: '4px' }}>Character Category :  {charcatergory[index]}
                                            </div>
                                            <div className='tag'>   {charsub[index]}
                                                <span className='tag-close' onClick={() => removeTag(tag)}>
                                                    &times;
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}



                            <div className='CN-Preview-headings'>
                                <div>Story image</div>
                                <div className='cn-danger-upload' onClick={handleDangerClick}>
                                    <img src={Danger} alt='h' />
                                </div>
                                {isPopupOpen && (
                                    <div className='danger-popup'>
                                        <img src={PopupImage} alt='popup' />
                                        <p >Optional.<br />You don't have to worry, we'll<br /> choose an image for your story if <br />you won’t!</p>
                                    </div>
                                )}
                            </div>
                            <div className='cn-preview-box'
                            >
                                <div className='CN-Preview-input'>
                                    <label htmlFor="myImage" className='cn-img-upload'>
                                        <div className='plus-img' ><img alt='plus' src={ImageIcon} /></div>
                                        <div className='cn-img-upload-text'>Choose image from library</div>
                                    </label>

                                    <input type="file" name="myImage" id="myImage"
                                        accept="image/*" required
                                        onChange={(e) => uploadImage(e)} placeholder='Choose image from library'
                                        style={{ display: 'none' }} // Optionally hide the input element
                                        rows='1'
                                    />

                                </div>
                                <div className='plus-img' ><img alt='plus' src={upload} /></div>
                            </div>

                        </div>

                        <div className='left-lower'>
                            <button className='cancelBtn' onClick={handleReject}>Reject</button>
                            <button className='submitBtn' onClick={handleSubmit}>Approve</button>
                        </div>
                    </div>
                )}
                <div>
                    {isCharacterVisible && <Character toggleVisibility={toggleCharacterVisibility} />} {/* Pass toggle function */}

                </div>

            </div>
            <div className='right'>
                <div className='right-upper'>
                    <span>Story Preview</span>
                </div>

                <div className='right-lower'>
                    <div className='right-lower-upper'>
                        <div className='right-lower-title'>
                            {props.titleData}
                        </div>

                        <div id='right-lower-cover-image'>
                            <div id='right-lower-cover-image-icon' className='image-preview'>
                                <img alt='imgicon' src={ImageIcon} />
                                {props.storyThumbnailImage}
                            </div>
                            <span id='right-lower-cover-image-text'>Uploaded Image</span>
                        </div>
                        <div className='right-lower-beginning'>
                            {props.beginningData}
                        </div>
                        <div className='right-lower-separator'>

                        </div>
                        <div className='right-lower-story'>
                            {props.storyData}
                        </div>
                    </div>
                    <div className='right-lower-lower'>
                        <div className='right-lower-image'>
                            <img alt='HappyPot' src={HappyPot} />
                        </div>
                        <div className='right-lower-message'>
                            <span>{props.messageData}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ModeratorPreview