/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:01-04-2023
Purpose : The purpose of this code is to fetch stories based on search tags.
Brief : The code contains an asynchronous function named fetchStories responsible for retrieving stories associated with specific search tags. It utilizes the searchtag endpoint from the SM-StoriesMetadata model to construct the API endpoint required to fetch stories based on the provided search text (searchTextParam), page number, and page size. The function makes an asynchronous GET request using Axios, including the search parameters and page details in the request URL, along with an authorization token obtained from the authToken. Upon receiving the response, it extracts and returns the data containing the fetched stories.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import axios from 'axios';
import { authToken } from '../../Model/SM-StoriesMetadata/StoriesMetadata';
import { searchtag } from '../../Model/SM-StoriesMetadata/StoriesMetadata';

export const fetchStories = async (searchTextParam, page, PAGE_LIMIT, userId, setTotalStories, setTotalStoriesObject) => {
    const response = await axios.get(`${searchtag}&tags=${searchTextParam}&pageNumber=${page}&pageSize=${PAGE_LIMIT}&user_id=${userId}`, {
        headers: {
            Authorization: `Bearer ${authToken}`,
        },
    });
    // console.log(response.data)
    const { totalStories, totalStoriesObject } = response.data;
    setTotalStories(totalStories);
    setTotalStoriesObject(totalStoriesObject);
    return response.data.data;
};
