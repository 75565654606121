/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:10-19-2023
Purpose : Term for user Page
Brief :
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React from 'react'

function TermsofUser() {
    return (
        <div>
            <div className='am-term-page'>
                <div className='am-term'>
                    Terms of Use
                </div>
                <div className='am-term-heading'>
                    Terms and Conditions ("Terms")
                </div>
                <p className='am-term-para'>Our Terms and Conditions were last updated on Dec 29 2022 </p>
                <p className='am-term-para'>Please read these terms and conditions carefully before using Our Service. </p>
                <p className='am-term-para'> The mobile Website with the name of Wiki Bedtime Stories (hereinafter referred as "Website") is
                    created and operated by Kushmanda Tech LLC (hereinafter referred as "We, us, Our"). </p>

                <div className='am-term-heading'>
                    1. Interpretation and Definitions
                </div>
                <p className='am-term-para-heading'>
                    Interpretation
                </p>
                <p className='am-term-para-heading'>
                    Definitions
                </p>
                <p className='am-term-para'>
                    For the purposes of these Terms and Conditions:
                </p>
                <ul>
                    <li className='am-term-para'>"Affiliate" means an entity that controls, is controlled by or is under common control with a
                        party, where "control" means ownership of 50% or more of the shares, equity interest or other
                        securities entitled to vote for election of directors or other managing authority. </li>
                    <li className='am-term-para'>"Country" refers to India. </li>
                    <li className='am-term-para'>"Device" means any device that can access the Service such as a cell phone or a digital tablet.</li>
                    <li className='am-term-para'>"Feedback" means feedback, innovations or suggestions sent by You regarding the attributes,
                        performance or features of our Service.</li>
                    <li className='am-term-para'>"Service" refers to the service of providing bedtime stories suitable for all ages to the parties
                        accessing the Website acting as a platform. </li>
                    <li className='am-term-para'>"Terms and Conditions" (also referred as "Terms") mean these Terms and Conditions that
                        form the entire agreement between You and the Company regarding the use of the Service.</li>
                    <li className='am-term-para'>"You" means the individual accessing or using the Service, or the company, or other legal
                        entity on behalf of which such individual is accessing or using the Service, as Applicable. </li>

                </ul>
                <div className='am-term-heading'>
                    2. Acknowledgment
                </div>
                <p className='am-term-para'>
                    These are the Terms and Conditions governing the use of this Service and the agreement that operates between You and the Company. These Terms and Conditions set out the rights and obligations of all users regarding the use of the Service.
                    Your access to and use of the Service is conditioned on Your acceptance of and compliance with these Terms and Conditions. These Terms and Conditions Apply to all visitors, users and others who access or use the Service.
                </p>
                <p className='am-term-para'>
                    By accessing or using the Service You agree to be bound by these Terms and Conditions. If You disagree with any part of these Terms and Conditions then You may not access the Service.
                </p>
                <p className='am-term-para'>
                    Your access to and use of the Service is also conditioned on Your acceptance of and compliance with the Privacy Policy of the Company. Our Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your personal information when You use the Website tells You about Your privacy rights and how the law protects You. Please read Our Privacy Policy carefully before using Our Service.
                </p>

                <div className='am-term-heading'>
                    3. Acceptable usage
                </div>
                <p className='am-term-para'>
                    The Website acts as a platform that provides bedtime stories as services, all the Users are to use the Website in a lawful manner. The users are bound to use the Website only for the purpose it is been launched.
                </p>
                <div className='am-term-heading'>
                    4. Modifications to the Website
                </div>
                <p className='am-term-para'>
                    The Company reserves the right to modify, suspend or discontinue, temporarily or permanently, the Website or any service to which it connects, with or without notice and without liability to You.
                </p>
                <div className='am-term-heading'>
                    5. Updates to the Website
                </div>
                <p className='am-term-para'>
                    The Company may from time to time provide enhancements or improvements to the features/functionality of the Website, which may include patches, bug fixes, updates, upgrades and other modifications.
                </p>
                <p className='am-term-para'>
                    Updates may modify or delete certain features and/or functionalities of the Website. You agree that the Company has no obligation to (i) provide any Updates, or (ii) continue to provide or enable any particular features and/or functionalities of the Website to You.
                </p>
                <p className='am-term-para'>
                    You further agree that all updates or any other modifications will be (i) deemed to constitute an integral part of the Website, and (ii) subject to the terms and conditions of this Agreement.
                </p>

                <div className='am-term-heading'>
                    6. Maintenance and Support
                </div>
                <p className='am-term-para'>
                    The Company does not provide any maintenance or support for the download and use of the Website. To the extent that any maintenance or support is required by Applicable law, the Company, not the Website Store, shall be obligated to furnish any such maintenance or support.
                </p>

                <div className='am-term-heading'>
                    7. Third-Party Services
                </div>
                <p className='am-term-para'>
                    The Website may display, include or make available third-party content (including data, information, Websites and other products services) or provide links to third-party websites or services.
                </p>
                <p className='am-term-para'>
                    You acknowledge and agree that the Company shall not be responsible for any Third-party Services, including their accuracy, completeness, timeliness, validity, copyright compliance, legality, decency, quality or any other aspect thereof. The Company does not assume and shall not have any liability or responsibility to You or any other person or entity for any Third-party Services.
                </p>
                <p className='am-term-para'>
                    You must comply with Applicable Third parties' Terms of agreement when using the Website. Third- party Services and links thereto are provided solely as a convenience to You and You access and use them entirely at your own risk and subject to such third parties' Terms and conditions.
                </p>

                <div className='am-term-heading'>
                    8. Copyright Policy
                </div>
                <p className='am-term-para'>
                    We respect the intellectual property rights of others. It is Our policy to respond to any claim that Content posted on the Service infringes a copyright or other intellectual property infringement of any person.
                </p>
                <p className='am-term-para'>
                    If You are a copyright owner, or authorized on behalf of one, and You believe that the copyrighted work has been copied in a way that constitutes copyright infringement that is taking place through the Service, You must submit Your notice in writing to the attention of Us via emaillegal@kushmanda.tech and include in Your notice a detailed description of the alleged infringement.
                </p>
                <p className='am-term-para'>
                    You may be held accountable for damages (including costs and attorneys' fees) for misrepresenting that any Content is infringing Your copyright.
                </p>
                <p className='am-term-para-heading'>
                    DMCA Notice and DMCA Procedure for Copyright Infringement Claims
                </p>
                <p className='am-term-para'>
                    You may submit a notification pursuant to the Digital Millennium Copyright Act (DMCA) by providing Us with the following information in writing (see 17 U.S.C 512(c)(3) for further detail):
                </p>
                <ul>
                    <li className='am-term-para'>An electronic or physical signature of the person authorized to act on behalf of the owner of
                        the copyright's interest.</li>
                    <li className='am-term-para'>A description of the copyrighted work that You claim has been infringed, including the URL
                        (i.e., web page address) of the location where the copyrighted work exists or a copy of the
                        copyrighted work.</li>
                    <li className='am-term-para'>Identification of the URL or other specific location on the Service where the material that You
                        claim is infringing is located. </li>
                    <li className='am-term-para'>
                        Your address, telephone number, and email address.
                    </li>
                    <li className='am-term-para'>
                        A statement by You that You have a good faith belief that the disputed use is not authorized
                        by the copyright owner, its agent, or the law.
                    </li>
                    <li className='am-term-para'>
                        A statement by You, made under penalty of perjury, that the above information in Your notice
                        is accurate and that You are the copyright owner or authorized to act on the copyright owner's behalf.
                    </li>
                </ul>

                <p className='am-term-para'>
                    You can contact our copyright agent via email legal@kushmanda.tech. Upon receipt of a notification, the Company will take whatever action, in its sole discretion, it deems Appropriate, including removal of the challenged content from the Service.
                </p>
                <div className='am-term-heading'>
                    9. Intellectual Property
                </div>
                <p className='am-term-para'>
                    The Service and its original content features and functionality are and will remain the exclusive property of the Company and its licensors.
                </p>
                <p className='am-term-para'>

                    The Service is protected by copyright, trademark, and other laws of both the Country and foreign countries.
                    Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of the Company.
                </p>

                <div className='am-term-heading'>
                    10. Liability and Indemnity:
                </div>
                <p className='am-term-para'>
                    A. We agree to indemnify you for the losses incurred, arising out of or in relation to (I) any violation of this Terms by Us or (II) breach of Our Privacy Policy by Us, (III) any liability that cannot be excluded by law.
                </p>
                <p className='am-term-para'>

                    Subject to clause 10 (A) the Company shall not be liable for any other indirect, special or consequential losses suffered by You.
                </p>
                <p className='am-term-para'>
                    B. You agree to indemnify and hold the Company and its parents, subsidiaries, affiliates, officers, employees, agents, partners and licensors (if any) harmless from any claim or demand, including reasonable attorneys' fees, due to or arising out of your: (a) use of the Website; (b) violation of this Agreement or any law or regulation; or (c) violation of any right of a third party.
                </p>
                <div className='am-term-heading'>
                    11. The Content of Others
                </div>
                <p className='am-term-para'>
                    We are a platform much of the content on our Services is produced by users, publishers, and other third parties. Whether that content is posted publicly or sent privately, the content is the sole responsibility of the user or entity that submitted it. Although We reserves the right to review or remove all content that Appears on the Services, we do not necessarily review all of it. So we cannot—and do not—guarantee that other users or the content they provide through the Services will comply with our Terms. If You find any inappropriate content or representations, You can report it to Us via email legal@kushmanda.tech. Upon receipt of a notification, the Company will take whatever action, in its sole discretion, it deems Appropriate, including removal of the challenged content from the Service.

                </p>
                <div className='am-term-heading'>
                    12. Terms Relating to Comment Section
                </div>
                <p className='am-term-para'>
                    The following describes the terms and conditions Applicable to your use of the “comments” submission service at this Website.

                </p>
                <p className='am-term-para'>
                    By clicking the “Publish” box for your comment, you agree to be bound by these terms and conditions and abide by all of the rules and policies set forth herein. If you do not agree to these terms and conditions, do not submit your comment in the Website.
                </p>
                <ol>
                    <li className='am-term-para'>Kushmanda Tech owns and operates the Website. Your use of the features on the
                        Website allowing for submission of a “Comment” is subject to the following terms and
                        conditions.
                    </li>
                    <li className='am-term-para'>
                        You must be 18 years of age or older to submit a “Comment” to the Website. If you
                        are minor as per your State's law, You must be below the supervision of Your legal guardian.
                        By submitting a “Comment” and accepting these Terms through your clicking of the
                        "Publish" box, you represent that You are at least 18 years old or You are accompanied by
                        Your legal guardian.
                    </li>
                    <li className='am-term-para'>
                        By submitting your comment, You are considered to know the fact that your name
                        will Appear in the chat along with the comment as public.
                    </li>
                    <li className='am-term-para'>
                        Kushmanda Tech has the right, but not the obligation, to take any of the following
                        actions, in Kushmanda Tech's sole unfettered discretion, at any time, and for any reason or no
                        reason, without providing any prior notice:
                        <ol type="a">
                            <li className='am-term-para'>Restrict, suspend or terminate your ability to submit “Comments,” Chat or any other
                                content, to the Website;</li>
                            <li className='am-term-para'>Refuse or remove any material posted on, submitted to or communicated through the
                                Website by you; </li>
                            <li className='am-term-para'>Deactivate or delete any screen names, profiles or other information associated with
                                you; or
                            </li>
                            <li className='am-term-para'>
                                Alter, modify, discontinue or remove any content of the Website.
                            </li>
                        </ol>
                    </li>
                    <li className='am-term-para'>
                        You agree that, when using or accessing the Comment session or any of the features
                        thereof, you will not:
                    </li>
                    <ol type="a">
                        <li className='am-term-para'>Violate any Applicable law or regulation;</li>
                        <li className='am-term-para'>Interfere with or damage the Website;</li>
                        <li className='am-term-para'>Transmit or introduce to the Website or to other users thereof any viruses, cancel bots,
                            Trojan horses, flood pings, denial of service attacks, or any other harmful code or
                            processes;</li>
                        <li className='am-term-para'>Infringe the rights of any third party, including but not limited to any intellectual property
                            rights, privacy rights, publicity rights or contractual rights;</li>
                        <li className='am-term-para'>Transmit or submit harmful, threatening, abusive, harassing, defamatory, deceptive,
                            fraudulent, obscene, indecent, vulgar, lewd, violent, hateful or otherwise objectionable
                            content or material;</li>
                        <li className='am-term-para'>Transmit or submit any unsolicited advertising, promotional materials, or spam;</li>
                        <li className='am-term-para'>Stalk or harass any user or visitor to the Website; or</li>
                        <li className='am-term-para'>Use the content or information available on the Website for any improper purpose.</li>
                    </ol>
                    <li className='am-term-para'>You retain the Copyright in any "Comment" you submit to Kushmanda Tech. By
                        submitting a “Comment” in the Website, you agree to grant Kushmanda Tech an irrevocable,
                        non-exclusive, royalty-free, perpetual license to use the material or commentary that you have
                        submitted, in any medium and in any manner that Kushmanda Tech may, in its sole unfettered
                        discretion, choose.
                    </li>
                    <li className='am-term-para'>
                        By submitting a “Comment” to Kushmanda Tech, you agree to comply with the
                        following rules concerning such submissions:
                        <ol type="A">
                            <li className='am-term-para'>
                                You agree not to include in your "Comment":
                                <ol type="i">
                                    <li className='am-term-para'>
                                        Any false, defamatory, libelous, abusive, threatening, racially offensive, sexually
                                        explicit, obscene, harmful, vulgar, hateful, illegal, or otherwise objectionable content.

                                    </li>
                                    <li className='am-term-para'>
                                        Any content that may be harassing of any other Website contributors, Users or other
                                        third parties;
                                    </li>
                                    <li className='am-term-para'>
                                        Any content that personally attacks an individual whose identity can be easily learned
                                        from what you post (An example of a personal attack is posting negative comments
                                        about an individual in a way meant to demean that person. Note that posting your
                                        opinion about someone's ideas or actions is not a personal attack);
                                    </li>
                                    <li className='am-term-para'>
                                        Any content that discloses private or personal matters concerning any person;
                                    </li>
                                    <li className='am-term-para'>
                                        Any content that you know to be false, misleading, or fraudulent;
                                    </li>
                                    <li className='am-term-para'>
                                        Any content that violates the property rights of others, including unauthorized
                                        copyrighted music, images or programs, trade secrets or other confidential or
                                        proprietary information, and trademarks or service marks used in an infringing
                                        fashion;
                                    </li>
                                    <li className='am-term-para'>Any use of profanity; </li>
                                    <li className='am-term-para'>
                                        Any content including advertisements or otherwise focused on the promotion of
                                        commercial events or businesses, or any request for or solicitation of money, goods,
                                        or services for private gain;
                                    </li>
                                    <li className='am-term-para'>
                                        Any content that you do not have a right to make available under any law or under
                                        contractual or fiduciary relationships (such as inside information or proprietary and
                                        confidential information learned or disclosed as part of employment relationships or
                                        under nondisclosure agreements);
                                    </li>
                                    <li className='am-term-para'>
                                        Any content that contains software viruses or any other computer code, files or
                                        programs designed to interrupt, destroy or limit the functionality of any computer
                                        software or hardware or telecommunications equipment; or
                                    </li>
                                    <li className='am-term-para'>
                                        Any content directly or indirectly soliciting responses from minors (defined as
                                        anyone under 18 years of age).
                                    </li>
                                </ol>
                            </li>
                            <li className='am-term-para'>
                                You are solely responsible for “Comment” you upload, post, transmit or otherwise
                                make available to others using this Website. Under no circumstances will Kushmanda Tech be
                                liable in any way for any “Comment” posted on or made available through this Website by
                                you or any third party.
                            </li>
                            <li className='am-term-para'>You understand that Kushmanda Tech does not generally pre-screen “Comments”
                                posted on or available through the Website by individual authors, and that it has no obligation
                                to do so. Kushmanda Tech and its designees have the right (but not the obligation) in their
                                sole unfettered discretion to remove any “Comment” that is posted on or available through the
                                Website. Without limiting the foregoing, Kushmanda Tech and its designees will have the
                                right to remove any “Comment” that violates these Terms or is otherwise deemed
                                objectionable by Kushmanda Tech in its sole discretion. </li>
                        </ol>
                    </li>
                    <li className='am-term-para'>
                        When submitting a “Comment,” you will be asked to provide your name and your
                        email address. Kushmanda Tech warrants that it will not intentionally publish your email
                        address to an outside third party, apart from Kushmanda Tech's own affiliates or
                        subcontractors, without your consent.
                    </li>
                    <li className='am-term-para'>
                        Kushmanda Tech does not sell or rent your personal information to third parties for
                        their marketing purposes without your consent. Periodically, Kushmanda Tech or its affiliates
                        may contact you via email concerning the Feedback, promotion of their products or services
                        on the Website. Notwithstanding the foregoing, you acknowledge and understand that the
                        “Comments” feature of the Website is designed to permit users to post information and
                        commentary for public review and comment and thus you hereby waive any expectation of
                        privacy you may have concerning any likeness or information provided to the Website by
                        you.
                    </li>
                    <li className='am-term-para'>
                        You are solely responsible for your interactions with other users of or visitors to the
                        Website.
                        <ol type="a">
                            <li className='am-term-para'>Kushmanda Tech shall have the right, but not the obligation, to monitor interactions
                                utilizing the facilities of the Website, You acknowledge and agree that Kushmanda
                                Tech, its affiliates, subsidiaries, licensees and assigns shall not be, and you shall not
                                seek to hold them, responsible for any harm or damage whatsoever arising in
                                connection with your interaction with other users of or visitors to the Website.
                            </li>
                            <li className='am-term-para'>
                                Kushmanda Tech does not verify any information posted to or communicated via the
                                “Comments” sections of the Website by users and does not guarantee the proper use
                                of such information by any party who may have access to the information. You
                                acknowledge and agree that Kushmanda Tech does not assume, and shall not have,
                                any responsibility for the content of messages or other communications sent or
                                received by users of the Website.
                            </li>
                        </ol>
                    </li>
                    <li className='am-term-para'>If any of You find any comment/Comments form an user inappropriate or against the
                        Terms, You can report by using the “Report” option available on the Website in the
                        Comments Section or via email legal@kushmanda.tech. Upon receipt of a notification, the
                        Company will take whatever action, in its sole discretion, it deems Appropriate, including
                        removal of the challenged content from the Service or deactivating the User Account of the
                        wrongdoer.
                    </li>
                </ol>
                <div className='am-term-heading'>
                    13. No Warranties
                </div>
                <p className='am-term-para'>
                    The Website is provided to You "AS IS" and "AS AVAILABLE" and with all faults and defects without warranty of any kind. To the maximum extent permitted under Applicable law, the Company, on its own behalf and on behalf of its affiliates and its and their respective licensors and service providers, expressly disclaims all warranties, whether express, implied, statutory or otherwise, with respect to the Website, including all implied warranties of merchantability, fitness for a particular purpose, title and non-infringement, and warranties that may arise out of course of dealing, course of performance, usage or trade practice. Without limitation to the foregoing, the Company provides no warranty or undertaking, and makes no representation of any kind that the Website will meet your requirements, achieve any intended results, be compatible or work with any other software, Websites, systems or services, operate without interruption, meet any performance or reliability standards or be error free or that any errors or defects can or will be corrected.
                </p>
                <p className='am-term-para'>
                    Without limiting the foregoing, neither the Company nor any of the company's provider makes any representation or warranty of any kind, express or implied: (i) as to the operation or availability of the Website, or the information, content, and materials or products included thereon; (ii) that the Website will be uninterrupted or error-free; (iii) as to the accuracy, reliability, or currency of any information or content provided through the Website; or (iv) that the Website, its servers, the content, or e-mails sent from or on behalf of the Company are free of viruses, scripts, trojan horses, worms, malware, timebombs or other harmful components.

                </p>
                <p className='am-term-para'>
                    Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on Applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not Apply to You. But in such a case the exclusions and limitations set forth in this section shall be Applied to the greatest extent enforceable under Applicable law. To the extent any warranty exists under law that cannot be disclaimed, the Company, not the Website Store, shall be solely responsible for such warranty.
                </p>
                <div className='am-term-heading'>
                    14. Termination
                </div>
                <p className='am-term-para'>
                    We may terminate or suspend Your Account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if You breach these Terms and Conditions.
                </p>
                <p className='am-term-para'>
                    Upon termination, Your right to use the Service will cease immediately. If You wish to terminate Your Account, You may simply discontinue using the Service.
                </p>
                <div className='am-term-heading'>
                    15. Severability
                </div>
                <p className='am-term-para'>
                    If any provision of this Agreement is held to be unenforceable or invalid, such provision will be changed and interpreted to accomplish the objectives of such provision to the greatest extent possible under Applicable law and the remaining provisions will continue in full force and effect.
                </p>
                <div className='am-term-heading'>
                    16. Waiver
                </div>
                <p className='am-term-para'>
                    Except as provided herein, the failure to exercise a right or to require performance of an obligation under this Agreement shall not effect a party's ability to exercise such right or require such performance at any time thereafter nor shall the waiver of a breach constitute a waiver of any subsequent breach.
                </p>
                <div className='am-term-heading'>
                    17. United States Legal Compliance
                </div>
                <p className='am-term-para'>
                    You represent and warrant that (i) You are not located in a country that is subject to the United States government embargo, or that has been designated by the United States government as a "terrorist supporting" country, and (ii) You are not listed on any United States government list of prohibited or restricted parties.
                </p>
                <div className='am-term-heading'>
                    18. Changes to this Agreement
                </div>
                <p className='am-term-para'>
                    The Company reserves the right, at its sole discretion, to modify or replace this Agreement at any time. What constitutes a material change will be determined at the sole discretion of the Company.
                </p>
                <p className='am-term-para'>
                    By continuing to access or use the Website after any revisions become effective, You agree to be bound by the revised terms. If You do not agree to the new terms, You are no longer authorized to use the Website.
                </p>
                <div className='am-term-heading'>
                    19. Governing Law and Jurisdiction
                </div>
                <p className='am-term-para'>
                    The laws of India, excluding its conflicts of law rules, shall govern this Agreement and your use of the Website. Your use of the Website may also be subject to other local, state, national, or international laws. The parties agree that the venue for purposes of any and all lawsuits, causes of action, arbitrations or other disputes shall be in Chennai, Tamil Nadu, India.
                </p>

                <div className='am-term-heading'>
                    20. Entire Agreement
                </div>
                <p className='am-term-para'>
                    The Agreement constitutes the entire agreement between You and the Company regarding your use of the Website and supersedes all prior and contemporaneous written or oral agreements between You and the Company.
                </p>
                <div className='am-term-heading'>
                    21. Contact Us
                </div>
                <p className='am-term-para'>
                    If you have any questions about this Agreement, You can contact Us:
                </p>

                <p className='am-term-para'>
                    By visiting this page on our website: https://kushmanda.tech
                </p>
                <p className='am-term-para'>
                    By sending us an email: legal@kushmanda.tech
                </p>



            </div>
        </div>
    )
}

export default TermsofUser
