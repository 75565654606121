/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:01-04-2023
Purpose : Detailed View of Stories 
Brief :
State Management: Uses useState hook for managing various states like showConclusion, showClick, loading, filledStars, allowClick, and showPopup.

Rendering Story Details: Renders details of a story including its title, author, creation date, summary, content, conclusion, views, and rating.

Interactive Elements: Provides interactive elements such as buttons for sharing, bookmarking, and rating the story.

Conditional Rendering: Implements conditional rendering based on loading state. While the story is loading, a loading screen is displayed.

Star Rating: Allows users to rate the story by clicking stars. It prevents multiple clicks for rating and prompts users to sign up to rate the story if they haven't already.

Sharing Functionality: Provides a share button that uses the Web Share API to share the story link if supported, otherwise, displays a message indicating that the API is not supported.

Date Formatting: Formats the creation date of the story.

Advertisement Display: Includes an advertisement block using the AdsDetailView component.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useState, useEffect } from 'react'
import axios from 'axios';
import MagicPot from '../../../Assets/Images/Magic pot.WebM'
import Heart from '../../../Assets/Images/Heart.webp'
import HeartFilled from '../../../Assets/Images/HeartFilled.webp'
import Share from '../../../Assets/Images/Share.webp'
import BookmarkFilled from '../../../Assets/Images/BookmarkFilled.webp'
import Bookmark from '../../../Assets/Images/Bookmark.webp'
import LoadingImage from '../../../Assets/Images/LoadingImage.webp'
import ShareButton from './ShareButton'
import AdsDetailView from '../../../../AM-AplicationManagement/src/JS/Ads/AdsDetailView'
import StarFill from '../../../Assets/Images/StarFill.webp'
import Star from '../../../Assets/Images/Star.webp'
import LoginPop from '../../../../UM-UserManagement/src/JS/LoginPop'
import LoadingGif from '../../../Assets/Images/Loading.WebM'
import BeforeMoral from '../../../Assets/Images/BeforeMoral.webp'
import AfterMoral from '../../../Assets/Images/AfterMoral.webp'
import MoralStart from '../../../Assets/Images/MoralStart.webp'
import MoralEnd from '../../../Assets/Images/MoralEnd.webp'
import { useLocation, useNavigate } from 'react-router-dom'
import { Add_Favourite, Add_saved, Remove_Favourite, Remove_saved, update_story_rating } from '../../../../../../Model/WS-WrittenStories/WrittenStories'
import AdsNewDetailView from '../../../../AM-AplicationManagement/src/JS/Ads/AdsNewDetailView';
import AdsMobDetailView from '../../../../AM-AplicationManagement/src/JS/Ads/AdsMobDetailView';
import { Helmet } from 'react-helmet';

function DetailedView({ data, loading }) {
    const [showConclusion, setShowConclusion] = useState(false);
    const [clickConclusion, setClickConclusion] = useState(true);
    const [showClick, setShowClick] = useState(true);
    const [filledStars, setFilledStars] = useState(Array(5).fill(false));
    const [allowClick, setAllowClick] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();
    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const [userId, setUserID] = useState(localStorage.getItem('UserID') || null);
    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
    }, []);
    const toggleStar = (index) => {
        const storyID = String(data.storyID);
        let lastValueOfI; // Declare i outside the loop
        if (allowClick) {
            const updatedFilledStars = [...filledStars];
            for (let i = 0; i <= index; i++) {

                updatedFilledStars[i] = true;
                lastValueOfI = i + 1; // Store the value of i in lastValueOfI
            }
            const requestBody = {
                story_id: storyID,
                rating: lastValueOfI.toString()
            };
            const requestHeaders = {
                Authorization: `Bearer ${authToken}`,
            };
            axios
                .patch(update_story_rating, requestBody, { headers: requestHeaders })
                .then((response) => {
                    // Handle the API response here
                })
                .catch((error) => {
                    console.error(error); // Handle errors here
                });
            setFilledStars(updatedFilledStars);
            setAllowClick(false); // Prevent further clicks
        }
    };
    const [showSignup, setShowSignup] = useState(false);

    const handleShowSignup = () => {
        setShowSignup(true);
        // Automatically close the signup modal after 3 seconds
        setTimeout(() => {
            setShowSignup(false);
        }, 3000); // 3000 milliseconds (3 seconds)
    };

    const handleCloseSignup = () => {
        navigate('/loginpop');
    };

    const handleShowConclusion = () => {
        setShowConclusion(true);
        setShowClick(false);
        setClickConclusion(false);
    }
    const [showPopup, setShowPopup] = useState(false);

    const handleShowPopup = () => {
        console.log("tokenmmm:   " + authToken);
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            console.log('logged in')
        }
    };
    const [isBookmarked, setIsBookmarked] = useState(false);
    const handleShowBookmark = () => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            console.log('logged in')
            setIsBookmarked(!isBookmarked);
        }
    };
    const unsavedStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Remove_saved, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const savedStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Add_saved, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const [isHearted, setIsHearted] = useState(false);
    const handleShowHeart = () => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {

            console.log('logged in')
            setIsHearted(!isHearted);
        }
    };
    const unfavouriteStory = (storyID) => {
        storyID = String(storyID);
        console.log(storyID)
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Remove_Favourite, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const favouriteStory = (storyID) => {
        storyID = String(storyID);

        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Add_Favourite, requestBody, { headers: requestHeaders })
            .then((response) => { })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const handleClosePopup = () => {
        setShowPopup(false);
    };
    const currentURL = window.location.href;

    const handleShareClick = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'Share Title',
                    text: 'Check out this link!',
                    url: currentURL,
                });
                // console.log('Shared successfully');
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            console.log('Web Share API not supported');
            // You can provide a fallback or show your custom share options here
        }
    };

    const authorview = (authorId, author_name) => {
        const formattedauthorName = author_name.replace(/\s+/g, '-');
        navigate(`/authorprofile/${authorId}/${formattedauthorName}`
        );
        window.scrollTo(0, 0);
    }
    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.toLocaleDateString('en-US', { day: 'numeric' });
        const month = date.toLocaleDateString('en-US', { month: 'long' });
        const year = date.toLocaleDateString('en-US', { year: 'numeric' });

        return `${day} ${month} ${year}`;
    }
    function formatDateMob(dateString) {
        const date = new Date(dateString);
        const day = date.toLocaleDateString('en-US', { day: 'numeric' });
        const month = date.toLocaleDateString('en-US', { month: 'short' });
        const year = date.toLocaleDateString('en-US', { year: 'numeric' });

        return `${day} ${month} ${year}`;
    }
    return (
        <div className='ws-story-detailview'>
            {/* {loading ? (
                <div className='ws-detailview'>
                    <div className='loading'>
                        <div className='load_gif'><img src={LoadingGif} alt='d' /></div>
                    </div>
                </div>

            ) : ( */}

                <div className='ws-detailview'>
                    {/* <Helmet>
                        <title>{`${data.title} | Kids | Wiki Bedtime Stories`}</title>
                        <meta name="description" content={data.summary} />
                        <link
                            rel="preload"
                            as="image"
                            href={data.storyThumbnailImage}
                            fetchpriority="high"
                        />
                    </Helmet> */}
                    <div className='ws-detail-title'>{data.title}</div>
                    <div className='ws-detail-authordatebtn'>
                        <div className='ws-detail-authorcdate'>
                            <div className='ws-detail-author' onClick={() => authorview(data.authorUserID, data.authorName)}>{data.authorName}&nbsp;-&nbsp;</div>

                            <div className='ws-detail-cdate'>{formatDate(data.creationDate)}</div>
                        </div>
                        <div className='ws-detail-hsb'>
                            <div className='ws-detail-heart' onClick={handleShowHeart}>
                                {
                                    data.favouriteStory ? (
                                        isHearted ? (
                                            <img
                                                onClick={() => favouriteStory(data.storyID)}
                                                src={Heart}
                                                alt='heart'
                                            />

                                        ) : (
                                            <img
                                                onClick={() => unfavouriteStory(data.storyID)}
                                                src={HeartFilled}
                                                alt='heart'
                                            />
                                        )
                                    ) : (
                                        isHearted ?
                                            (
                                                <img
                                                    onClick={() => unfavouriteStory(data.storyID)}
                                                    src={HeartFilled}
                                                    alt='heart'
                                                />
                                            ) : (
                                                <img
                                                    onClick={() => favouriteStory(data.storyID)}
                                                    src={Heart}
                                                    alt='heart'
                                                />
                                            )

                                    )
                                }

                            </div>
                            <div className='web'>
                                <ShareButton />
                            </div>
                            <div className='mobile'>
                                <div className='ws-detail-share'
                                    onClick={handleShareClick}
                                >
                                    <img src={Share} alt='share' />
                                </div>
                            </div>

                            <div className='ws-detail-bookmark' onClick={handleShowBookmark}>
                                {
                                    data.is_saved ? (
                                        isBookmarked ? (
                                            <img
                                                onClick={() => savedStory(data.storyID)}
                                                src={Bookmark}
                                                alt='bookmark'
                                            />



                                        ) : (
                                            <img
                                                onClick={() => unsavedStory(data.storyID)}
                                                src={BookmarkFilled}
                                                alt='bookmark'
                                            />
                                        )
                                    ) : (
                                        isBookmarked ?
                                            (
                                                <img
                                                    onClick={() => unsavedStory(data.storyID)}
                                                    src={BookmarkFilled}
                                                    alt='bookmark'
                                                />
                                            ) : (
                                                <img
                                                    onClick={() => savedStory(data.storyID)}
                                                    src={Bookmark}
                                                    alt='bookmark'
                                                />
                                            )

                                    )
                                }
                            </div>




                            {showPopup && (
                                <LoginPop closePopup={handleClosePopup} />

                            )}
                        </div>
                    </div>
                    <div className='ws-detail-storyimage'>
                        <img src={data.storyThumbnailImage} 
                        // alt={data.alt_text}
                        alt='thumbnail'
                            loading="eager"
                        />
                    </div>
                    <div className='ws-detail-summary'>{data.summary}</div>
                    <div className='ws-detail-story'>{data.story}</div>
                    {clickConclusion &&
                        <div className='ws-detail-magic-pot-temp' id='clickHere' onClick={handleShowConclusion}
                            style={{ position: 'relative', display: 'inline-block' }}
                        >
                            <img src={MoralStart} alt='MagicPot' style={{ width: '100%' }} />
                            <div className='moral-before-click' style={{
                                position: 'absolute',
                                top: '30%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: 'white',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                padding: '10px',
                                borderRadius: '20px'
                            }}>
                                Click me
                            </div>
                        </div>
                    }
                    {/* <div className='ws-detail-magic-pot-temp' id='clickHere' onClick={handleShowConclusion}>
                        <img src={MoralStart} alt='MagicPot' />
                    </div> */}
                    {showConclusion &&
                        <div className='ws-detail-magic-pot-temp' id='clickHere' onClick={handleShowConclusion}>
                            <img src={MoralEnd} alt='MagicPot' />
                            <div className='moral-click'>
                                <div className='moral-text'>{data.conclusion}</div>
                            </div>
                        </div>

                    }
                    {/* <div className='ws-detail-magic-pot'>
                        <img src={MagicPot} alt='MagicPot' />
                        {showClick && <div className='ws-detail-clickhere' id='clickHere' onClick={handleShowConclusion}>Click here</div>}
                        {showConclusion &&
                            <div className='ws-detail-conclusion' id='conclusion'>
                                {data.conclusion}
                            </div>}
                    </div> */}
                    <div className='ws-stories-new-detailview-adsBlock'>
                        <div className='web'>
                            <AdsNewDetailView />
                        </div>

                    </div>
                    <div className='ws-detail-views-rate'>
                        <div className='ws-detail-viewsratebox'>
                            <div className='ws-detail-viewsbox'>
                                <div className='ws-detail-viewstext'>
                                    {data.views}
                                </div>
                                <div className='ws-detail-views'>
                                    Views
                                </div>
                            </div>
                            <div className='ws-detail-ratebox'>
                                <div className='ws-detail-ratetext'>
                                    {Number(data.rating).toFixed(2)}
                                </div>
                                <div className='ws-detail-rate'>
                                    Rate
                                </div>
                            </div>

                        </div>
                        <div className='ws-detail-rate-story'>
                            <div className='ws-detail-rating'>
                                Rate Story
                            </div>
                            {(authToken == null || authToken === "no token") ?
                                <div className='ws-detail-ratestar'

                                >

                                    {filledStars.map((isFilled, index) => (
                                        <img

                                            key={index}
                                            src={isFilled ? StarFill : Star}
                                            alt={`Star ${index + 1}`}
                                            onClick={handleShowSignup}
                                        />
                                    ))}
                                </div>
                                :
                                <div className='ws-detail-ratestar'

                                >

                                    {filledStars.map((isFilled, index) => (
                                        <img

                                            key={index}
                                            src={isFilled ? StarFill : Star}
                                            alt={`Star ${index + 1}`}
                                            onClick={() => toggleStar(index)}
                                        />
                                    ))}
                                </div>
                            }
                        </div>
                    </div>
                    {showSignup && (
                        <div className="speech-bubble-container">
                            <div className="speech-bubble" onClick={handleCloseSignup}>

                                <div className="box-content">

                                    <span className='ws-rating-signup' >SignUp</span>&nbsp;
                                    to rate the story.

                                </div>
                            </div>

                        </div>
                    )}
                </div>
            {/* )} */}
            <div className='ws-stories-detailview-ads'>
                <div className='ws-stories-detailview-adsBlock'>
                    <AdsDetailView />
                </div>
            </div>
        </div>
    )
}

export default DetailedView
