/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:12-19-2023
Purpose : User Profile 
Brief :
Developer : Amit Kalita
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React from 'react';
import '../../../CS/UserProfile/UserPublishedCard.css'
import pencil from '../../../../../CN-ContributionNetwork/Assets/AuthorProfile/Pencil.svg';
import speaker from '../../../../../CN-ContributionNetwork/Assets/AuthorProfile/Speaker.svg';
import playButton from '../../../../../CN-ContributionNetwork/Assets/AuthorProfile/Play button.svg';

const UserAudioStories = ({ storyDetails }) => {
    return (
        <div className='AuthorAudioStoryCardMainSection'>

            <div className='AuthorAudioStoryCardImgSection'>
                <div>
                    <img src={storyDetails.storyBackGroundImage} />
                </div>
                <div className='AuthorAudioStoryCardImgSectionBg'></div>
                <div id='AuthorAudioStoryCardImgSectionPlay'>
                    <img src={playButton} />
                </div>
            </div>

            <div className='AuthorAudioStoryCardInfoSection'>

                <div className='AuthorAudioStoryCardInfoSectionStoryTitle'>
                    {storyDetails.title}
                </div>

                <div className='AuthorAudioStoryCardInfoSectionAuthor'>
                    <span>
                        <img src={pencil} />
                    </span>
                    <span> </span>
                    <span className='AuthorAudioStoryCardInfoSectionWriterName'>
                        {storyDetails.authorName}
                    </span>
                    <span>
                        <img src={speaker} />
                    </span>
                    <span> </span>
                    <span className='AuthorAudioStoryCardInfoSectionSpeakerName'>
                        {storyDetails.narratorName}
                    </span>
                    <span className='AuthorAudioStoryCardInfoSectionAuthorPostDate'>
                        - {storyDetails.creationDate}
                    </span>
                </div>

                <div className='AuthorAudioStoryCardInfoSectionStoryBrief'>
                    {storyDetails.summary}
                </div>

                <div className='AuthorAudioStoryCardInfoSectionData'>
                    <div className='AuthorAudioStoryCardInfoSectionDataViews'>
                        <div className='AuthorAudioStoryCardInfoSectionDataFirst'>
                            {storyDetails.views}
                        </div>

                        <div className='AuthorAudioStoryCardInfoSectionDataSecond'>
                            plays
                        </div>
                    </div>

                    <div className='AuthorAudioStoryCardInfoSectionDataRating'>
                        <div className='AuthorAudioStoryCardInfoSectionDataFirst'>
                            {storyDetails.rating}
                        </div>

                        <div className='AuthorAudioStoryCardInfoSectionDataSecond'>
                            rating
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default UserAudioStories