/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:-12-14-2023
Purpose : This code is to provide Controller for UserList.
Brief : The code consists of several API endpoint constants crucial for managing CN-related functionalities. It includes endpoints for handling CN drafts, adding tags to stories, and fetching metadata related to characters used in stories. These endpoints are structured to facilitate the creation and management of content within the application.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import axios from 'axios';
import { CN_Author_Profile_Publications, CN_DraftGET, CN_searchtag } from '../../../Model/CN-ContributionNetwork/ContributionNetwork';
const PAGE_LIMIT = 10;
const searchtag = '';
const fetchUserProfileApprovedData = async (searchTextParam, page, userId, setUserProfileApprovedData, existingApprovedData, setHasMore) => {
    try {
        const response = await axios.get(`${CN_searchtag}`, {
            params: {
                tags: searchTextParam,
                pageNumber: page,
                pageSize: PAGE_LIMIT,
                // status: 2,
                sortBy: 'last_approved_date',

            }
        });
        const json = response.data;
        const { totalStories } = json;

        // console.log('Fetched data:', json);

        if (json.data && json.data.length > 0) {
            let newData = json.data;

            // Check if existingReviewData is an array before using .some()
            if (Array.isArray(existingApprovedData)) {
                // Filter out duplicates
                newData = newData.filter(newItem => !existingApprovedData.some(existingItem => existingItem.storyID === newItem.storyID));
            } else {
                // Convert existingReviewData to an array if it's not already
                existingApprovedData = [existingApprovedData];
            }

            const updatedData = [...existingApprovedData, ...newData];
            setUserProfileApprovedData(updatedData);

            // Update hasMore based on the totalStories and current page
            const totalPages = Math.ceil(totalStories / PAGE_LIMIT);
            setHasMore(page < totalPages);
        } else {
            console.log("No data available.");
            setHasMore(false);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export default fetchUserProfileApprovedData;