/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:10-19-2023
Purpose : Exception Page
Brief :
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React from 'react'
import Header from '../../../../WS-WrittenStories/src/JS/Header/Header'
import Footer from '../../../../WS-WrittenStories/src/JS/Footer/Footer'
import ExceptionImg from '../../../Assets/Images/Exception.webp'
import ArrowBack from '../../../../UM-UserManagement/Assets/Arrow - Left.webp'
import '../../CS/Exception.css'
import { useNavigate } from 'react-router'

function Exception() {
    const navigate = useNavigate();
    return (
        <div>
            <Header />
            <div className='am-exception'>
                <img src={ExceptionImg} alt='exception' />
                <div className='am-exception-header'>Friendly reminder: Switch to a larger screen for the better experience!</div>
                <div className='am-exception-para'>It seems you're currently using a mobile device. This particular feature is optimized for larger screens. Please switch to a desktop or a tablet to continue.</div>
                <div className='am-exception-back' onClick={() => navigate('/')}
                >
                    <div className='am-exception-backimg'>
                        <img src={ArrowBack} alt='arrowback' />
                    </div>

                    Back to home
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Exception
