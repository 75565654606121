/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:12-12-2023
Purpose : AuthorList
Brief :
Developer : Amit Kalita
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useEffect, useState } from 'react'
import searchicon from '../../../../WS-WrittenStories/Assets/Images/Search.webp';
import { useLocation, useNavigate } from 'react-router-dom';

function SearchResult() {
    const navigate = useNavigate();
    const location = useLocation();
    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const [userId, setUserID] = useState(localStorage.getItem('UserID') || null);
    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
    }, []);
    const [searchText, setSearchText] = useState('');
    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            navigate(`/favoriteauthor/${searchText}`
            );
            window.location.reload(); // Reload the screen
        }
    }
    return (
        <div>
            <div className='cn-author-searchbar'>
                <img src={searchicon} alt='searchicon' />
                <input
                    id='searchauthorinput'
                    className='cn-search-input'
                    placeholder="Search authors"
                    type="text"
                    onChange={(e) => setSearchText(e.target.value)}
                    onKeyPress={handleSearch}
                />
            </div>
        </div>
    )
}

export default SearchResult
