import React, { useEffect, useState } from 'react'
import WrittenIcon from '../../../Assets/Images/written.webp'
import AudioIcon from '../../../Assets/Images/audio.webp'
import VideoIcon from '../../../Assets/Images/videoplay.webp'
import { useLocation, useNavigate } from 'react-router-dom';

function Navigation() {
    const navigate = useNavigate();
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('home'); // Initial active tab
    useEffect(() => {
        // Reset the active tab when the route changes
        const pathname = location.pathname;
        switch (pathname) {
            case '/kids-bedtime-stories-audio':
                setActiveTab('ws-audio-btn');
                break;
            case '/': // Add other route cases if needed
                setActiveTab('ws-written-btn');
                break;
            case '/authorlist': // Add other route cases if needed
                setActiveTab('authorlist');
                break;
            case '/videostories':
                setActiveTab('ws-video-btn');
                break;
            // Add additional cases for other routes if needed
            default:
                setActiveTab(''); // Reset to default active tab when route doesn't match
                break;
        }
    }, [location.pathname]);
    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
        navigate(`/${tabName === 'ws-written-btn' ? '' : tabName}`); // Navigate based on tab clicked
    };
    return (
        <div>
            <div className='ws-header-nav-mob'>
                <div

                    className={`ws-written-btn ${activeTab === 'ws-written-btn' ? 'active' : ''}`}
                    onClick={() => { navigate('/') }}>
                    <div className='ws-written-icon'>
                        <img src={WrittenIcon} alt='written-icon' />
                    </div>
                    <div className='ws-written-text'>
                        Written Stories
                    </div>
                </div>
                <div
                    className={`ws-audio-btn ${activeTab === 'ws-audio-btn' ? 'active' : ''}`}
                    onClick={() => {
                        handleTabClick('kids-bedtime-stories-audio'); navigate('/kids-bedtime-stories-audio'
                            // , { state: { authToken: authToken, from: '' } }
                        );
                    }}
                >
                    <div className='ws-audio-icon'>
                        <img src={AudioIcon} alt='audio-icon' />
                    </div>
                    <div className='ws-audio-text'>
                        Audio Stories
                    </div>
                </div>
                {/* <div
                    className={`ws-video-btn ${activeTab === 'ws-video-btn' ? 'active' : ''}`}
                    onClick={() => {
                        handleTabClick('videostories'); navigate('/videostories'
                        );
                    }}
                >
                    <div className='ws-video-icon'>
                        <img src={VideoIcon} alt='video-icon' />
                    </div>
                    <div className='ws-video-text'>
                        Video Stories
                    </div>
                </div> */}
            </div>
        </div >
    )
}

export default Navigation
