/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:01-04-2023
Purpose : Explore Audio Stories Page 
Brief :
AudioStories, renders a page displaying audio content. It includes a header, an audio content component (AudioContent), and a footer. The component manages state variables such as data, page, loading, allStoriesLoaded, totalStories, and totalStoriesObject.

The useEffect hook is employed to fetch audio data, particularly sub-playlist data, based on the current page and the predefined page limit. 
It updates the state variables accordingly after fetching the data. 
The AudioContent component utilizes these state variables to display the audio stories, handle pagination, and manage the loading state.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import AdsDetailView from '../../../AM-AplicationManagement/src/JS/Ads/AdsDetailView';
import ArrowLeft from '../../../WS-WrittenStories/Assets/Images/ArrowLeft.webp'
import Share from '../../../WS-WrittenStories/Assets/Images/Share.webp'
import Bookmark from '../../../WS-WrittenStories/Assets/Images/Bookmark.webp'
import Heart from '../../../WS-WrittenStories/Assets/Images/Heart.webp'
import PauseButtonEx from '../../Assets/Images/Pause buttonEx.webp'
import PlayButtonEx from '../../Assets/Images/Play buttonEX.webp'
import TimeRewindEX from '../../Assets/Images/Time rewindEX.webp'
import TimeForwardEX from '../../Assets/Images/Time forwardEX.webp'
import HeartFilled from '../../../WS-WrittenStories/Assets/Images/HeartFilled.webp'
import BookmarkFilled from '../../../WS-WrittenStories/Assets/Images/BookmarkFilled.webp'
import ShareButton from '../../../WS-WrittenStories/src/JS/StoryDetailView/ShareButton';
import axios from 'axios';
import { Add_Favourite, Add_saved, Remove_Favourite, Remove_saved, audio_rating, update_story_rating } from '../../../../../Model/WS-WrittenStories/WrittenStories';
import StarFill from '../../../WS-WrittenStories/Assets/Images/StarFill.webp'
import Star from '../../../WS-WrittenStories/Assets/Images/Star.webp'


function AudioDetail({ data, loading, setPage, page,
    allStoriesLoaded, totalStories,
    totalStoriesObject, setSortBy, setData,
    // sequence,
    //expand
    expandedClassName, audioList, audioPagination, onExpandClick, audioPlayerBox, audioPlayerBoxExpand, onExpandClose, seekingInputMob,
    audio
}) {
    const { sequence } = useParams();
    const authToken = localStorage.getItem('token') || null;
    const initialUserId = localStorage.getItem('UserID') || 0;
    const [showPopup, setShowPopup] = useState(false);

    const handleShowPopup = () => {
        if (authToken == null || authToken === "no token") {
            if (isPlaying) {
                audio.pause();
                setIsPlaying(false);
            }
            navigate('/loginpop');
        }
    };
    const handleSeek = (e) => {
        const { value } = e.target;
        audio.currentTime = parseFloat(value); // Convert value to a float
        setCurrentTime(parseFloat(value)); // Update the state with the float value
    };
    const formatTime = (time, from = "duration") => {
        const tt = time;
        const minutes = Math.floor(tt / 60);
        const seconds = Math.floor(tt % 60);
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };
    const [currentTime, setCurrentTime] = useState(0.0);
    const [duration, setDuration] = useState(0);
    const [durationLabel, setDurationLabel] = useState('00:00');
    const [isPlaying, setIsPlaying] = useState(false);
    const handleTimeRewind = () => {
        if (!audio) return;
        audio.currentTime -= 10; // Rewind 10 seconds
    };
    const handleTimeForward = () => {
        if (!audio) return;
        audio.currentTime += 10; // Forward 10 seconds
    };
    window.onpopstate = e => {
        if (audio) audio.pause();
    }

    // add code
    useEffect(() => {
        if (audio) {
            const handleTimeUpdate = () => {
                setCurrentTime(audio.currentTime);
            };
            audio.addEventListener('timeupdate', handleTimeUpdate);

            return () => {
                audio.removeEventListener('timeupdate', handleTimeUpdate);
                audio.pause();
            };
        }
    }, [audio]);
    // end code
    useEffect(() => {
        if (audio) {
            if (!isPlaying) {
                setIsPlaying(true);
                audio.play().catch(error => {
                    console.error('Play error:', error);
                });
            }
            audio.addEventListener('timeupdate', () => {
                let value = audio.currentTime;
                setCurrentTime(value);
            });
        }
    }, [audio]);
    useEffect(() => {
        if (audio) {
            setDurationLabel(formatTime(audio.duration));
            setDuration(audio.duration);
        }
    }, [currentTime]);
    const handlePlayPause = (index) => {
        if (isPlaying) {
            setIsPlaying(false);
            audio.pause();
            return;
        }
        audio.play().catch(error => {
            console.error('Play error:', error);
        });
        setIsPlaying(true);
    };
    const [showSignup, setShowSignup] = useState(false);
    let navigate = useNavigate();
    const goBack = () => {
        navigate("/kids-bedtime-stories-audio");
        audio.pause();
    };

    const [heartedStories, setHeartedStories] = useState(false);

    // Set the initial state of heartedStories based on data.favouriteStory
    useEffect(() => {
        setHeartedStories(data.favouriteStory);
    }, [data.favouriteStory]);

    const handleShowHeart = () => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            // Toggle the hearted state for the story
            setHeartedStories(!heartedStories);

            // Perform favorite/unfavorite action based on the state
            if (!heartedStories) {
                favouriteStory(data.storyID);
            } else {
                unfavouriteStory(data.storyID);
            }
        }
    };
    const unfavouriteStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Remove_Favourite, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const favouriteStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Add_Favourite, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };

    const [bookmarkedStories, setBookmarkedStories] = useState(false);

    // Set the initial state of heartedStories based on data.is_saved
    useEffect(() => {
        setBookmarkedStories(data.is_saved);
    }, [data.is_saved]);
    const handleShowBookmark = () => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            // Toggle the hearted state for the story
            setBookmarkedStories(!bookmarkedStories);

            // Perform favorite/unfavorite action based on the state
            if (!bookmarkedStories) {
                savedStory(data.storyID);
            } else {
                unsavedStory(data.storyID);
            }
        }
    };

    const unsavedStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Remove_saved, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const savedStory = (storyID) => {
        storyID = String(storyID);
        // Define the request body
        const requestBody = {
            story_id: storyID,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(Add_saved, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };

    const [filledStars, setFilledStars] = useState(Array(5).fill(false));
    const [allowClick, setAllowClick] = useState(true);

    const handleShowSignup = () => {
        setShowSignup(true);
    };
    const toggleStar = (index) => {
        const clickedStory = data;
        if (!clickedStory || !clickedStory.storyID) {
            console.error('Invalid story data or missing story ID');
            return;
        }

        const { storyID } = clickedStory;



        if (allowClick) {
            const updatedFilledStars = filledStars.map((star, i) => (i <= index));
            const lastValueOfI = updatedFilledStars.lastIndexOf(true) + 1;

            const requestBody = {
                story_id: String(storyID),
                sequence: String(sequence),
                rating: lastValueOfI.toString()
            };

            const requestHeaders = {
                Authorization: `Bearer ${authToken}`,
            };

            axios
                .patch(audio_rating, requestBody, { headers: requestHeaders })
                .then((response) => {
                    // Handle the API response here
                })
                .catch((error) => {
                    console.error(error); // Handle errors here
                });

            setFilledStars(updatedFilledStars);
            setAllowClick(false);
        }
    };

    const handleCloseSignup = () => {
        navigate('/loginpop');
    };
    const currentURL = window.location.href;

    const handleShareClick = async () => {
        if (navigator.share) {
            try {
                await navigator.share({
                    title: 'Share Title',
                    text: 'Check out this link!',
                    url: currentURL,
                });
                // console.log('Shared successfully');
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            console.log('Web Share API not supported');
            // You can provide a fallback or show your custom share options here
        }
    };
    return (
        <div>
            <div >
                <div className='as-audio-detail-view'>
                    <div className='as-player-box-expand'>

                        <div className='as-audio-detail-view'>
                            <div className='as-audio-backbtn'
                            >
                                <img src={ArrowLeft} alt='backbtn' onClick={goBack} />
                                <div className='as-audio-now-playing'>
                                    Now Playing
                                </div>
                            </div>
                            <div className='as-audio-detailview'>
                                <div className='as-detailview-thumbnailimg'>
                                    <img src={data.

                                        storyThumbnailImage
                                    } alt='Story' />

                                </div>
                                <div className='as-detailview-storyTitle'>
                                    <div className='as-detailview-heart' >

                                        <div className='as-detailview-heart-icon' onClick={handleShowHeart}>
                                            <img
                                                src={heartedStories ? HeartFilled : Heart}
                                                alt='heart'
                                            />
                                        </div>


                                    </div>
                                    <div className='as-audio-TitleAuthorNarrator'>
                                        <div className='as-audio-storyTitle'>{data.storyTitle}</div>
                                        <div className='as-detailview-authorNarrator'>
                                            <div>{data.authorName},

                                                {data.narratorName}</div>

                                        </div>
                                    </div>

                                    <div className='as-detailview-bookmark-share'>

                                        <div className='ws-bookmark' onClick={handleShowBookmark}>
                                            <img
                                                src={bookmarkedStories ? BookmarkFilled : Bookmark}
                                                alt='heart'
                                            />
                                        </div>


                                        <div className='ws-shareEX'>
                                            <div className='web'>
                                                <ShareButton />

                                            </div>
                                            <div className='mobile'>
                                                <div className='ws-detail-share'
                                                    onClick={handleShareClick}
                                                >
                                                    <img src={Share} alt='share' />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='as-detailview-playrate'>
                                    <div className='as-detail-play'><span className='as-detail-playno'>{data.listening_count}&nbsp;</span> play</div>
                                    <div className='as-detail-rate'> <span className='as-detail-rateno'>
                                        {Number(data.rating).toFixed(2)}
                                        &nbsp; </span>rating</div>
                                </div>
                                <div className='seekbar-expand'>

                                    <div className='seekinginput-expand'>
                                        <input id='myinput'
                                            type="range"
                                            min={0}
                                            max={duration}
                                            value={currentTime}
                                            onChange={handleSeek}
                                            className='slider-expand'
                                        />
                                    </div>
                                </div>
                                <div className='as-audio-timeShow'>
                                    <div className='as-player-currenttimeEX'>{formatTime(currentTime, "currentTime")}</div>
                                    <div className='as-player-maxtimeEX'>{durationLabel}</div>
                                </div>
                                <div className='as-audio-playpausebtn'>
                                    <div className='as-player-timerewindEX'
                                        onClick={handleTimeRewind}
                                    >
                                        <img src={TimeRewindEX} alt='TimeRewind' />
                                    </div>
                                    <div className='as-player-playpause'
                                        onClick={handlePlayPause}
                                    >
                                        {(isPlaying) ? <img src={PauseButtonEx} alt='PauseButton' /> : <img src={PlayButtonEx} alt='PlayButton' />}
                                    </div>

                                    <div className='as-player-timeforwardEX'
                                        onClick={handleTimeForward}
                                    >
                                        <img src={TimeForwardEX} alt='Timeforward' />
                                    </div>
                                </div>
                                <div className='as-audio-rating'>
                                    <div className='as-detail-rating'>
                                        Rate Story
                                    </div>
                                    {(authToken == null || authToken === "no token") ?
                                        <div className='as-audio-detail-ratestar'>
                                            {filledStars.map((isFilled, index) => (
                                                <img
                                                    key={index}
                                                    src={isFilled ? StarFill : Star}
                                                    alt={`Star ${index + 1}`}
                                                    onClick={handleShowSignup}
                                                />
                                            ))}
                                        </div>
                                        :
                                        <div className='as-audio-detail-ratestar'>
                                            {filledStars.map((isFilled, index) => (
                                                <img
                                                    key={index}
                                                    src={isFilled ? StarFill : Star}
                                                    alt={`Star ${index + 1}`}
                                                    onClick={() => toggleStar(index)}
                                                />
                                            ))}
                                        </div>
                                    }


                                </div>
                                {showSignup && (
                                    <div className="speech-bubble-container">


                                        <div className="speech-bubble" onClick={handleCloseSignup}>
                                            <div className="box-content">
                                                <div><span className='ws-rating-signup'>SignUp</span> to rate the story.</div>
                                            </div>
                                        </div>

                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='as-detailview-ads'>
                            <AdsDetailView />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default AudioDetail
