/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:01-04-2023
Purpose : This code is to provide data model for CN
Brief : The code consists of several API endpoint constants crucial for managing CN-related functionalities. It includes endpoints for handling CN drafts, adding tags to stories, and fetching metadata related to characters used in stories. These endpoints are structured to facilitate the creation and management of content within the application.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

export const CN_Draft = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story`;
export const CN_DraftGET = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/pages`;
export const CN_DraftStoryIdGET = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story`;



export const CN_DraftPatch = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/story_details`;
export const CN_Tags = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story_tag/add_tags`;
export const CN_Characters = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/metadata/characters`;

// authorapi
export const CN_Author = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/metadata/authors?`;
export const CN_AuthorDiscover = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/metadata/authors?`;

export const GetAuthorFollow = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/user/follow_author?`
export const GetAuthorFav = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/user/favourite_author?`

//authorProfile
export const CN_Author_Profile = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/user/get_author_profile`;
export const CN_Author_Profile_Publications = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/pages/author`;
export const CN_Author_Follow = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/user/follow_author`;
export const CN_Author_Unfollow = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/user/unfollow_author`;
export const CN_Author_Saved = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/pages/save`
export const CN_Author_Liked = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0//story/pages/favourite`

export const CN_Author_Favorite = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/user/favourite_author`;
export const CN_Author_UnFavorite = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/user/unfavourite_author`;
export const CN_Author_Followers = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/user/author_followers`;
export const CN_Author_Following = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/user/follow_author`;

export const CN_Author_Fav_Search = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/user/search_author?`

export const CN_searchtag = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/pages/tags?sortMode=DESC&sortBy=views&user_id=1`
