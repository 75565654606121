/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:12-12-2023
Purpose : AuthorList
Brief :
Developer : Amit Kalita
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useState } from 'react'
import searchicon from '../../../../WS-WrittenStories/Assets/Images/Search.webp';
import '../../CS/AuthorBanner.css'
import Author1 from '../../../Assets/AuthorImage/Author1.webp'
import Author2 from '../../../Assets/AuthorImage/Author2.webp'
import Author3 from '../../../Assets/AuthorImage/Author3.webp'
import Author4 from '../../../Assets/AuthorImage/Author4.webp'
import Author5 from '../../../Assets/AuthorImage/Author5.webp'
import Author6 from '../../../Assets/AuthorImage/Author6.webp'
import Author7 from '../../../Assets/AuthorImage/Author7.webp'
import Author8 from '../../../Assets/AuthorImage/Author8.webp'
import Author9 from '../../../Assets/AuthorImage/Author9.webp'
import Author10 from '../../../Assets/AuthorImage/Author10.webp'
import Author11 from '../../../Assets/AuthorImage/Author11.webp'
import Author12 from '../../../Assets/AuthorImage/Author12.webp'
import { useNavigate, useLocation } from 'react-router-dom';
function AuthorBannner({ data,
    loading }) {
    const location = useLocation();
    const [showPopup, setShowPopup] = useState(false);
    const [selectedAuthor, setSelectedAuthor] = useState(null);
    const [selectedAuthorId, setSelectedAuthorId] = useState(null);
    const navigate = useNavigate();
    const handleAuthorDetail = (author_id, author_name) => {
        window.scrollTo(0, 0);
        console.log('Clicked Author ID:', author_id);
        // Set selected author or open popup based on the authorId
        setSelectedAuthor(author_name);
        setSelectedAuthorId(author_id)
        setShowPopup(true);

    }
    const handleAuthorProfile = (author_id, author_name) => {
        navigate(`/authorprofile/${author_id}`
        );
        window.scrollTo(0, 0);

    }
    const closePopup = () => {
        // Close the popup
        setShowPopup(false);
        setSelectedAuthor(null);
    };
    const [searchText, setSearchText] = useState('');
    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            navigate(`/favoriteauthor/${searchText}`
            );
            window.location.reload(); // Reload the screen
        }
    }
    return (
        <div>
            <div className='web'>
                <div className='cn-author-banner'>
                    <div className='author-banner'>
                        <div className='cn-author-search'>
                            <div className='cn-author-trend-box'>
                                <div className='cn-author-trending'>
                                    Trending Authors
                                </div>
                                <div className='cn-author-trend-text'>
                                    A vibrant and dynamic community of authors whose words resonate with readers across the globe
                                </div>
                                <div className='cn-author-searchbar'>
                                    <img src={searchicon} alt='searchicon' />
                                    <input
                                        id='searchauthorinput'
                                        className='cn-search-input'
                                        placeholder="Search authors"
                                        type="text"
                                        onChange={(e) => setSearchText(e.target.value)}
                                        onKeyPress={handleSearch}
                                    />
                                </div>
                            </div>

                        </div>
                        <div className='cn-author-trend-profile'>
                            <div className='cn-empty-space'></div>
                            <div className='cn-author-profile-box'>
                                <div className='cn-author-profile-boxone'>
                                    <img onClick={handleAuthorDetail.bind(null, data[0]?.author_id, data[0]?.author_name)} src={Author1} alt='Author' />
                                    <img onClick={handleAuthorDetail.bind(null, data[1]?.author_id, data[1]?.author_name)} src={Author2} alt='Author' />
                                    <img onClick={handleAuthorDetail.bind(null, data[2]?.author_id, data[2]?.author_name)} src={Author3} alt='Author' />
                                    <img onClick={handleAuthorDetail.bind(null, data[3]?.author_id, data[3]?.author_name)} src={Author4} alt='Author' />
                                </div>

                                <div className='cn-author-profile-boxtwo'>
                                    <img onClick={handleAuthorDetail.bind(null, data[4]?.author_id, data[4]?.author_name)} src={Author5} alt='Author' />
                                    <img onClick={handleAuthorDetail.bind(null, data[5]?.author_id, data[5]?.author_name)} src={Author6} alt='Author' />
                                    <img onClick={handleAuthorDetail.bind(null, data[6]?.author_id, data[6]?.author_name)} src={Author7} alt='Author' />
                                    <img onClick={handleAuthorDetail.bind(null, data[7]?.author_id, data[7]?.author_name)} src={Author8} alt='Author' />
                                </div>
                                <div className='cn-author-profile-boxthree'>
                                    <img onClick={handleAuthorDetail.bind(null, data[8]?.author_id, data[8]?.author_name)} src={Author9} alt='Author' />
                                    <img onClick={handleAuthorDetail.bind(null, data[9]?.author_id, data[9]?.author_name)} src={Author10} alt='Author' />
                                    <img onClick={handleAuthorDetail.bind(null, data[10]?.author_id, data[10]?.author_name)} src={Author11} alt='Author' />
                                    <img onClick={handleAuthorDetail.bind(null, data[11]?.author_id, data[11]?.author_name)} src={Author12} alt='Author' />
                                </div>
                            </div>
                        </div>
                    </div>

                </div >
            </div>
            <div className='mobile'>
                <div className='cn-author-banner'>
                    <div className='author-banner'>
                        <div className='cn-author-trend-profile'>
                            <div className='cn-empty-space'></div>
                            <div className='cn-author-profile-box'>
                                <div className='cn-author-imageboxone'>
                                    <div className='cn-author-profile-boxone'>
                                        <img onClick={handleAuthorDetail.bind(null, data[0]?.author_id, data[0]?.author_name)} src={Author1} alt='Author' />
                                        <img onClick={handleAuthorDetail.bind(null, data[1]?.author_id, data[1]?.author_name)} src={Author2} alt='Author' />
                                        <img onClick={handleAuthorDetail.bind(null, data[2]?.author_id, data[2]?.author_name)} src={Author3} alt='Author' />
                                        <img onClick={handleAuthorDetail.bind(null, data[3]?.author_id, data[3]?.author_name)} src={Author4} alt='Author' />
                                    </div>
                                </div>

                                <div className='cn-author-imageboxone'>
                                    <div className='cn-author-profile-boxtwo'>
                                        <img onClick={handleAuthorDetail.bind(null, data[4]?.author_id, data[4]?.author_name)} src={Author5} alt='Author' />
                                        <img onClick={handleAuthorDetail.bind(null, data[5]?.author_id, data[5]?.author_name)} src={Author6} alt='Author' />
                                        <img onClick={handleAuthorDetail.bind(null, data[6]?.author_id, data[6]?.author_name)} src={Author7} alt='Author' />
                                        <img onClick={handleAuthorDetail.bind(null, data[7]?.author_id, data[7]?.author_name)} src={Author8} alt='Author' />
                                    </div>
                                </div>
                                <div className='cn-author-imageboxone'>
                                    <div className='cn-author-profile-boxthree'>
                                        <img onClick={handleAuthorDetail.bind(null, data[8]?.author_id, data[8]?.author_name)} src={Author9} alt='Author' />
                                        <img onClick={handleAuthorDetail.bind(null, data[9]?.author_id, data[9]?.author_name)} src={Author10} alt='Author' />
                                        <img onClick={handleAuthorDetail.bind(null, data[10]?.author_id, data[10]?.author_name)} src={Author11} alt='Author' />
                                        <img onClick={handleAuthorDetail.bind(null, data[11]?.author_id, data[11]?.author_name)} src={Author12} alt='Author' />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='cn-author-search'> */}
                        <div className='cn-author-trend-box'>
                            <div className='cn-author-trending'>
                                Trending Authors
                            </div>
                            <div className='cn-author-trend-text'>
                                A vibrant and dynamic community of authors whose words resonate with readers across the globe
                            </div>

                        </div>

                        {/* </div> */}
                    </div>

                </div>
            </div>

        </div >

    )
}

export default AuthorBannner
