/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:10-19-2023
Purpose : Video Stories Page
Brief :
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useState } from 'react';
import YouTube from 'react-youtube';
import Header from '../../WS-WrittenStories/src/JS/Header/Header';
import '../CS/VideoPlayer.css'

const VideoPlayer = () => {
    const [videoId, setVideoId] = useState('3_xV51dZSCI');

    const opts = {
        height: '390',
        width: '640',
        playerVars: {
            autoplay: 1,
        },
    };

    const onReady = (event) => {
        // access to player in all event handlers via event.target
        // event.target.pauseVideo(); // For example, pause the video when it's ready
        event.target.playVideo(); // Play the video when it's ready
    };

    return (
        <div>
            <Header />
            <div className='vs-stories'>
                <YouTube videoId={videoId} opts={opts}
                    onReady={onReady}
                />
            </div>

        </div>
    );
};

export default VideoPlayer;
