/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:-12-14-2023
Purpose : This code is to provide Controller for AuthorList.
Brief : The code consists of several API endpoint constants crucial for managing CN-related functionalities. It includes endpoints for handling CN drafts, adding tags to stories, and fetching metadata related to characters used in stories. These endpoints are structured to facilitate the creation and management of content within the application.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import axios from 'axios';
import { CN_Author_Profile_Publications } from '../../../Model/CN-ContributionNetwork/ContributionNetwork';
let PAGE_LIMIT = 10;
const fetchAuthorProfilePublicationsData = async (page, authorId, setTotalStories, setAuthorProfilePublicationsData, filterBy, existingData = [], setHasMore, achievementsValue = 0) => {

    try {
        if (achievementsValue != 0) PAGE_LIMIT = 3;
        else PAGE_LIMIT = 10;
        let sortBy = 'last_approved_date';
        let sortMode = 'DESC';
        if (achievementsValue != 0) sortMode = 'DESC';
        else sortMode = 'DESC';
        if (achievementsValue === 4) sortBy = 'views';
        else if (achievementsValue === 5) sortBy = 'liked_stories';
        else if (achievementsValue === 6) sortBy = 'saved_stories';
        const response = await axios.get(`${CN_Author_Profile_Publications}`, {
            params: {
                pageNumber: page,
                pageSize: PAGE_LIMIT,
                sortBy: sortBy,
                user_id: 1,
                sortMode: sortMode,
                author_id: authorId,
                status: 3,
                filterBy: filterBy
            }
        });
        const json = response.data;
        const { totalStories } = json;
        setTotalStories(totalStories);
        setAuthorProfilePublicationsData(json.data);
        if (json.data.length < PAGE_LIMIT) {
            setHasMore(false); // No more data to fetch
        }
        const updatedData = [...existingData, ...json.data];
        setAuthorProfilePublicationsData(updatedData);

    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export default fetchAuthorProfilePublicationsData;