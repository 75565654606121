/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:-12-14-2023
Purpose : This code is to provide Controller for UserList.
Brief : The code consists of several API endpoint constants crucial for managing CN-related functionalities. It includes endpoints for handling CN drafts, adding tags to stories, and fetching metadata related to characters used in stories. These endpoints are structured to facilitate the creation and management of content within the application.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import axios from 'axios';
import { CN_Author_Profile_Publications } from '../../../Model/CN-ContributionNetwork/ContributionNetwork';
const PAGE_LIMIT = 10;
const fetchUserProfilePublicationsData = async (page, userId, authorId, setTotalStories, setAuthorProfilePublicationsData, filterBy, existingData = [], setHasMore) => {
    try {
        const response = await axios.get(`${CN_Author_Profile_Publications}`, {
            params: {
                pageNumber: page,
                pageSize: PAGE_LIMIT,
                sortBy: 'last_approved_date',
                user_id: 1,
                sortMode: 'DESC',
                author_id: userId,
                status: 3,
                filterBy: filterBy
            }
        });
        const json = response.data;
        const { totalStories } = json;
        setTotalStories(totalStories);

        if (json.data && json.data.length > 0) {
            // Filter out duplicates
            const newData = json.data.filter(newItem => !existingData.some(existingItem => existingItem.storyID === newItem.storyID));

            if (newData.length > 0) {
                const updatedData = [...existingData, ...newData];
                setAuthorProfilePublicationsData(updatedData);


                if (newData.length < PAGE_LIMIT) {
                    setHasMore(false); // No more data to fetch
                }
            } else {
                // If all new data items are duplicates, we don't need to update the state
                console.log("No new unique items to add.");
            }
        } else {
            setHasMore(false); // No more data to fetch
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export default fetchUserProfilePublicationsData;
