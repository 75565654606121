/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:12-19-2023
Purpose : User Profile 
Brief :
Developer : Amit Kalita
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React from 'react';
import '../../CS/UserProfile/UserPublications.css';
import UserDraftCard from './UserPublishedCard/UserDraftCard';
import UserPublicationCard from './UserPublishedCard/UserPublishedCard';
import UserDataCard from './UserPublishedCard/UserDataCard';
import UserLikedStories from './UserPublishedCard/UserLikedStoies';
import AuthorFollowerCard from '../../../../CN-ContributionNetwork/src/JS/AuthorProfile/AuthorFollowerCard';
import UserSavedStories from './UserPublishedCard/UserSavedStories';
import UserNoPublishedCard from './UserPublishedCard/UserNoPublishedCard';
import UserAudioStories from './UserPublishedCard/UserAudioStories';
import UserReviewCard from './UserPublishedCard/UserReviewCard';
import UserApprovedCard from './UserPublishedCard/UserApprovedCard';
import ApprovedSearch from './ApprovedStories/ApprovedSearch';
import LoadingGif from '../../../../WS-WrittenStories/Assets/Images/Loading.WebM'

const UserPublications = ({ Count, userData, likedData, savedData, profileData, data, review, approved, audioData, setPage, page, totalStories, displayList, authorFollowerData, authorFollowingData, hasMore }) => {
  const handleScroll = (e) => {
    const { clientHeight, scrollHeight, scrollTop } = e.target;
    const isBottom = scrollHeight - (scrollTop + clientHeight) <= 1;
    if (isBottom && hasMore) {
      console.log('Reached bottom, loading more...');
      setPage(prevPage => prevPage + 1);
    } else if (isBottom) {
      console.log('No more stories to load');
    }
  };
  return (
    <div className='AuthorPublicationsMainSection' >
      <div className='AuthorPublicationsHead'>
        {(displayList === 0) ? <div>Stories ({Count.total_stories})</div> : <></>}
        {(displayList === 1) ? <div>Followers
          ({Count.followers_count})
        </div> : <></>}
        {(displayList === 2) ? <div>Following
          ({Count.following_count})
        </div> : <></>}
        {(displayList === 3) ? <div>Audio Stories
          ({Count.audio_story_count})
        </div> : <></>}
        {(displayList === 4) ? <div>Draft Stories
          {/* (5) */}
        </div> : <></>}
        {(displayList === 5) ? <div>Saved Stories
          {/* (5) */}
        </div> : <></>}
        {(displayList === 6) ? <div>Liked Stories
          {/* (5) */}
        </div> : <></>}
      </div>
      <div className='AuthorPublicationsCards' onScroll={handleScroll}>

        {userData && Array.isArray(userData) && userData.length > 0 ? (
          userData.map((story, i) => (
            story.status === 401 ? (
              <p key={i}>No published story</p>
            ) : (
              displayList === 0 && (
                <UserPublicationCard key={i} storyDetails={story} />
              )
            )
          ))
        ) : (
          displayList === 0 ? <><UserNoPublishedCard /></> : <></>
        )}

        {(displayList === 5 && savedData && savedData.length > 0) ? savedData.map((story, i) =>
          <UserSavedStories key={i} storyDetails={story} />
        ) : displayList === 5 ? <>No Saved Stories to Show</> : <></>}
        {(displayList === 6 && likedData && likedData.length > 0) ? likedData.map((story, i) =>
          <UserLikedStories key={i} storyDetails={story} />
        ) : displayList === 6 ? <>No Liked Stories to Show</> : <></>}

        {(displayList === 4 && data && Array.isArray(data) && data.length > 0) ? data.map((story, i) =>
          <UserDraftCard key={i} storyDetails={story} />
        ) : displayList === 4 ? <><UserNoPublishedCard /></> : <></>}

        {(displayList === 7 && review && Array.isArray(review) && review.length > 0) ? review.map((story, i) =>
          <UserReviewCard key={i} storyDetails={story} />
        ) : displayList === 7 ? <><UserNoPublishedCard /></> : <></>}
        {(displayList === 8 && approved && Array.isArray(approved) && approved.length > 0) ? (
          <>
            <ApprovedSearch />
            {approved.map((story, i) => (
              <UserApprovedCard key={i} storyDetails={story} />
            ))}
          </>
        ) : displayList === 8 ? (
          <>
            <div className='loading'>
              <div className='load_gif'><img src={LoadingGif} alt='d' /></div>
            </div>
          </>
        ) : (
          <></>
        )}

        {audioData && Array.isArray(audioData) && audioData.length > 0 ? (
          audioData.map((story, i) => (
            story.status === 401 ? (
              <p key={i}>No published story</p>
            ) : (
              displayList === 3 && (
                <UserAudioStories key={i} storyDetails={story} />
              )
            )
          ))
        ) : (
          displayList === 3 ? <><UserDataCard /></> : <></>
        )}
        {(displayList === 1 && authorFollowerData && authorFollowerData.length > 0) ? authorFollowerData.map((fol) => <AuthorFollowerCard follower={fol} displayList={displayList} />) : displayList === 1 ? <>No Follower to display...</> : <></>}
        {(displayList === 2 && authorFollowingData && authorFollowingData.length > 0) ? authorFollowingData.map((fol) => <AuthorFollowerCard follower={fol} displayList={displayList} />) : displayList === 2 ? <>No Follower to display...</> : <></>}

      </div>
    </div>
  )
}

export default UserPublications