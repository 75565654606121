/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:10-19-2023
Purpose : Search Box
Brief :
State Management: Utilizes several state variables:

searchText: Manages the text entered in the search input.
suggestions: Manages the suggested search results based on user input.
showNoSuggestions: Controls the display of a message when no suggestions are found.
storyCountData: Stores data about the total count of authors and stories.
Effects (useEffect): It contains multiple useEffect hooks:

Fetches the total count of stories and authors when the component mounts.
Triggers fetching suggestions based on the entered search text.
Listens for clicks outside the suggestion box to hide suggestions.
Handling User Input:

Allows users to input search queries and triggers a search either by pressing "Enter" or clicking on the search icon.
Dynamically fetches suggestions based on the user input and displays them in the suggestion box.
Clicking on a suggestion navigates the user to a search results page with the clicked suggestion as the search query.
Rendering JSX:

Renders a search box UI with an input field, search icon, and suggestions dropdown.
Displays an image with text overlay providing information about the content available.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useState, useEffect, useRef } from 'react';
import Banner from '../../../Assets/Images/BG.webp';
import '../../CS/ImageTextPage.css';
import { storycount } from '../../../../../../Model/WS-WrittenStories/WrittenStories';
import { useNavigate } from 'react-router-dom';

function SearchBox({ totalStories, loading }) {
    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const [userId, setUserID] = useState(localStorage.getItem('UserID') || null);
    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
    }, []);
    const [searchText, setSearchText] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showNoSuggestions, setShowNoSuggestions] = useState(false);
    const [storyCountData, setStoryCountData] = useState(null);
    const suggestionBoxRef = useRef(null);
    const navigate = useNavigate();

    const handleSearch = async (e) => {
        if (e.key === 'Enter') {
            navigate(`/search-results?searchText=${searchText}&totalStories=${totalStories}`
            );
        }
    };
    useEffect(() => {
        const fetchStoryCount = async () => {
            try {
                const response = await fetch(`https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/story_count`);
                if (response.ok) {
                    const data = await response.json();
                    setStoryCountData(data);
                } else {
                    throw new Error('Failed to fetch story count');
                }
            } catch (error) {
                console.error('Error fetching story count:', error);
            }
        };

        fetchStoryCount();
    }, []);
    const handleChange = (e) => {
        const inputValue = e.target.value;
        setSearchText(inputValue);

        // Show suggestions only if there's input and suggestions available
        if (inputValue.trim() !== '' && suggestions.length > 0) {
            setShowSuggestions(true);
        } else {
            setShowSuggestions(false);
        }
    };
    useEffect(() => {
        if (searchText.trim() !== '') {
            fetchSuggestions();
        } else {
            setSuggestions([]);
        }
    }, [searchText]);

    const fetchSuggestions = async () => {
        const PAGE_LIMIT = 10; // Define your page limit
        const page = 1; // Set the page number

        try {
            const response = await fetch(`https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/pages/tags?sortMode=ASC&sortBy=views&user_id=1&tags=${searchText}&pageNumber=${page}&pageSize=${PAGE_LIMIT}&user_id=${userId}`);
            if (response.ok) {
                const responseData = await response.json();
                if (responseData && responseData.data && responseData.data.length > 0) {
                    const extractedSuggestions = responseData.data.map(item => ({
                        storyID: item.storyID,
                        title: item.title,
                        summary: item.summary,
                    }));
                    setSuggestions(extractedSuggestions);
                    setShowNoSuggestions(false); // Hide "No suggestions found" message
                } else {
                    setSuggestions([]);
                    setShowNoSuggestions(true); // Show "No suggestions found" message
                }
            } else {
                console.error('Failed to fetch suggestions');
                setSuggestions([]);
                setShowNoSuggestions(true); // Show "No suggestions found" message
            }
        } catch (error) {
            console.error('Error fetching suggestions:', error);
            setSuggestions([]);
            setShowNoSuggestions(true); // Show "No suggestions found" message
        }
    };

    const handleSuggestionClick = (clickedTitle) => {
        navigate(`/search-results?searchText=${clickedTitle}&totalStories=${totalStories}`
            // , { state: { authToken: authToken, UserID: initialUserId, from: '' } }
        );
    };
    const handleClickOutside = (event) => {
        if (suggestionBoxRef.current && !suggestionBoxRef.current.contains(event.target)) {
            setSuggestions([]); // Hide suggestions when clicking outside the box
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);
    const [showSuggestions, setShowSuggestions] = useState(false); // Add state to manage suggestion box visibility

    // Function to handle click on the input field
    const handleInputClick = () => {
        if (suggestions.length > 0) {
            setShowSuggestions(true); // Show suggestions when input field is clicked and suggestions exist
        }
    };
    return (
        <div>
            <div className='ws-search-text-box'>

                <div className="image-text-container">
                    <div className="image-overlay">
                        <img src={Banner} alt="By johnniedavid12412" />
                    </div>
                    <div className="text-container">
                        <div>
                            <div className='ws-search-discover'>Your story, our platform. Let's create a magical world!</div>

                            <div className='ws-search-distinct'>{storyCountData && (<strong>{storyCountData.totalAuthors}</strong>)}&nbsp;Authors, {storyCountData && (<strong>{storyCountData.totalStories} </strong>)} stories, enchanting short bedtime stories every night</div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
}

export default SearchBox;
