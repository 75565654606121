/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:12-19-2023
Purpose : User Profile 
Brief :
Developer : Amit Kalita
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React from 'react'
import facebook from '../../../../CN-ContributionNetwork/Assets/AuthorProfile/Facebook.svg';
import instagram from '../../../../CN-ContributionNetwork/Assets/AuthorProfile/Instagram.svg';
import linkedin from '../../../../CN-ContributionNetwork/Assets/AuthorProfile/Linkedin.svg';
import twitter from '../../../../CN-ContributionNetwork/Assets/AuthorProfile/Twitter.svg';

const UserSocialMediaCard = ({ profileData }) => {
    return (
        <></>

    )
}

export default UserSocialMediaCard