/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:10-19-2023
Purpose : Share Button
Brief :
State Management: Utilizes the useState hook to manage the visibility of the share options via showShareOptions.

Sharing Options: Provides various sharing functionalities through different platforms - WhatsApp, Facebook, Twitter, and Copy Link.

Web Share Functionality:

handleWebWhatsappShare: Opens a WhatsApp web share link with the current page's URL encoded in the message.
handleFacebookShare: Opens a Facebook share dialog with the current page's URL.
handleTwitterShare: Opens a Twitter intent for tweeting the current page's URL.
Copy to Clipboard: Copies the current page's URL to the clipboard when the "Copy" option is clicked.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useState } from 'react';
import Share from '../../../WS-WrittenStories/Assets/Images/Share.webp'
import WhatsApp from '../../../WS-WrittenStories/Assets/Images/Share/Whatsapp.png';
import Facebook from '../../../WS-WrittenStories/Assets/Images/Share/Facebook.png';
import Twitter from '../../../WS-WrittenStories/Assets/Images/Share/Twitter.png';
import Link from '../../../WS-WrittenStories/Assets/Images/Share/Link.png';
import '../../../WS-WrittenStories/src/CS/Share.css';

const ShareButton = () => {
    const [showShareOptions, setShowShareOptions] = useState(false);
    const currentURL = window.location.origin + window.location.pathname + window.location.hash;

    const handleShareClick = () => {
        setShowShareOptions(!showShareOptions);
    };

    const handleWebWhatsappShare = () => {
        const shareText = encodeURIComponent('Check out this link: ' + currentURL);
        window.open(`https://web.whatsapp.com/send?text=${shareText}`);
    };

    const handleFacebookShare = () => {
        console.log(currentURL);
        window.open(`https://www.facebook.com/sharer/sharer.php?u=${currentURL}`);
    };

    const handleTwitterShare = () => {
        const shareText = encodeURIComponent('Check out this link: ' + currentURL);
        window.open(`https://twitter.com/intent/tweet?text=${shareText}`);
    };

    const handleCopyLink = () => {
        const textArea = document.createElement('textarea');
        textArea.value = currentURL;
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
        alert('Link copied to clipboard');
    };

    return (
        <div className='ws-detail-share'
            onClick={handleShareClick}
        >
            <img
                src={Share}
                onClick={handleShareClick}
                style={{ cursor: 'pointer' }}
                alt='share' />


            {showShareOptions && (
                <>
                    {/* Semi-transparent background */}
                    <div className="overlay" onClick={handleShareClick}></div>

                    {/* Share options */}
                    <div id="shareOptions">
                        <div className="arrow-up"></div> {/* The arrow */}
                        <div className='shareOpt'>
                            <div className='shareOptflex' >
                                <a href="#" onClick={handleFacebookShare}>
                                    <div className='whatsapp-img'>
                                        <img src={Facebook} alt='.' />
                                    </div>
                                </a>
                                <a href="#" onClick={handleTwitterShare}>
                                    <div className='whatsapp-img'>
                                        <img src={Twitter} alt='.' />
                                    </div>
                                </a>
                                <a href="#" onClick={handleWebWhatsappShare}>
                                    <div className='whatsapp-img'>
                                        <img src={WhatsApp} alt='.' />
                                    </div>
                                </a>
                            </div>
                            <div className='share-link'>
                                <div className='link-img'>
                                    <img src={Link} alt='.' />
                                </div>
                                <div className='text-wbs'>
                                    www.wikibedtimestories
                                </div>
                                <a className='link-copy' href="#" onClick={handleCopyLink}> Copy </a>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default ShareButton;
