/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:01-04-2023
Purpose : Explore Audio Stories Page 
Brief :
AudioStories, renders a page displaying audio content. It includes a header, an audio content component (AudioContent), and a footer. The component manages state variables such as data, page, loading, allStoriesLoaded, totalStories, and totalStoriesObject.

The useEffect hook is employed to fetch audio data, particularly sub-playlist data, based on the current page and the predefined page limit. 
It updates the state variables accordingly after fetching the data. 
The AudioContent component utilizes these state variables to display the audio stories, handle pagination, and manage the loading state.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useEffect, useState } from 'react'
import Header from '../../../WS-WrittenStories/src/JS/Header/Header'
import Footer from '../../../WS-WrittenStories/src/JS/Footer/Footer'
import { fetchSubPlaylistData } from '../../../../../Controller/AS-AudioStories/SubPlaylistController'
import { useLocation, useParams } from 'react-router';
import AudioDetail from './AudioDetail';
import { fetchStoryData } from '../../../../../Controller/AS-AudioStories/PlayController';
import { fetchStoryDetail } from '../../../../../Controller/AS-AudioStories/AudioStoryDataController';

function AudioDetailView() {
    const [sortby, setSortBy] = useState('story_id');
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const PAGE_LIMIT = 10; // number of items to display per page
    const [loading, setLoading] = useState(false);
    const [allStoriesLoaded, setAllStoriesLoaded] = useState(false);
    const [totalStories, setTotalStories] = useState(0);
    const [totalStoriesObject, setTotalStoriesObject] = useState(0);

    const location = useLocation();
    const authToken = localStorage.getItem('token') || null;
    const initialUserId = localStorage.getItem('UserID') || 0;
    const [userId, setUserId] = useState(initialUserId || 0);
    // expand audio content
    const [seekingInputMob, setSeekingInputob] = useState('seekinginputmob');
    const [expandedClassName, setExpandedClassName] = useState('as-audio-playstories');
    const [audioList, setAudioList] = useState('ws-stories')
    const [audioPagination, setAudioPagination] = useState('as-audio-pagination')
    const [audioSearch, setAudioSearch] = useState('as-player-search')
    const [audioPlayerBox, setAudioPlayerBox] = useState('as-player-box')
    const [audioPlayerBoxExpand, setAudioPlayerBoxExpand] = useState('as-player-box-expand-none')
    const [footerMob, setFooterMob] = useState('rights')
    const handleExpandClick = () => {
        setExpandedClassName('as-expand'); // Change the class name here
        setAudioList('ws-stories-expand');
        setAudioPagination('as-audio-pagination-expand')
        setAudioSearch('as-player-search-expand')
        setAudioPlayerBox('as-player-box-hide');
        setAudioPlayerBoxExpand('as-player-box-expand')
        setSeekingInputob('seekinginputmob-none');
        setFooterMob('rights-hide');
    };
    const handleExpandClose = () => {
        setExpandedClassName('as-audio-playstories'); // Change the class name here
        setAudioList('ws-stories');
        setAudioPagination('as-audio-pagination')
        setAudioSearch('as-player-search')
        setAudioPlayerBox('as-player-box');
        setAudioPlayerBoxExpand('as-player-box-expand-none')
        setFooterMob('rights');
    };
    // const [data, setData] = useState({});
    const { story_id, sequence } = useParams();
    const { state } = useLocation();

    const [isPlaying, setIsPlaying] = useState(false);
    const [audio, setAudio] = useState(null);
    const [maxTime, setMaxTime] = useState('00:00');
    // let sequence = (state) ? state.data.sequence : null;
    const setMaxTimeFromAudio = (audio) => {
        const duration = audio.duration;
        const minutes = Math.floor(duration / 60).toString().padStart(2, '0');
        const seconds = Math.floor(duration % 60).toString().padStart(2, '0');
        const maxTime = `${minutes}:${seconds}`;
        setMaxTime(maxTime);
    };
    useEffect(() => {
        async function fetchData() {
            if (audio) {
                audio.pause(); // Pause the current audio file before loading the new one
            }
            const result = await fetchStoryData(sequence);
            setData(result);
            const newAudio = new Audio(result.
                audioLocation
            );
            newAudio.addEventListener('loadedmetadata', () => {
                setMaxTimeFromAudio(newAudio);
            });
            setAudio(newAudio);
            setIsPlaying(true);

        }

        fetchData();
    }, [sequence]);

    return (
        <div>
            <Header authToken={authToken} />

            <AudioDetail
                authToken={authToken} data={data} loading={loading} setPage={setPage} page={page}
                allStoriesLoaded={allStoriesLoaded} totalStories={totalStories}
                totalStoriesObject={totalStoriesObject}
                setSortBy={setSortBy}

                setData={setData}
                //expand
                expandedClassName={expandedClassName}
                audioList={audioList}
                audioPagination={audioPagination}
                audioSearch={audioSearch}
                audioPlayerBox={audioPlayerBox}
                onExpandClick={handleExpandClick}
                onExpandClose={handleExpandClose}
                audioPlayerBoxExpand={audioPlayerBoxExpand}
                seekingInputMob={seekingInputMob}
                audio={audio}
            />
            <Footer footerMob={footerMob} />
        </div>
    )
}

export default AudioDetailView
