/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:10-19-2023
Purpose : AdsDetailView
Brief :
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useEffect } from 'react'

function AdsDetailView() {
    useEffect(() => {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }, []);
    return (
        <div className='ws-ads-storiesdetailList' style={{ display: 'flex', justifyContent: 'center' }}>
            <ins
                className="adsbygoogle"
                style={{
                    display: 'inline-block',
                    // border: "1px solid black",
                    width: '300px', height: '1050px'
                }}
                data-ad-client="ca-pub-1752072945714872"
                data-ad-slot="6686373441"
            />
        </div>
    )
}

export default AdsDetailView
