/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:12-19-2023
Purpose : User Profile 
Brief :
Developer : Amit Kalita
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React from 'react';
import '../../../CS/UserProfile/UserPublishedCard.css'
import NoPublished from '../../../../Assets/UserProfile/NoPublish.webp'

const UserDataCard = () => {
    return (
        <div className='UserPublishedCard'>
            <div className='user-nopublish-img'>
                <img src={NoPublished} alt='nopublished' />
            </div>
            <div className='user-nopublish-text'>No Publications</div>
        </div>
    )
}

export default UserDataCard