/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:12-12-2023
Purpose : AuthorList
Brief :
Developer : Amit Kalita
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useEffect, useState } from 'react'
import BG from '../../../Assets/DiscoverAuthor/BGFavorite.webp'
import AddUser from '../../../Assets/AuthorImage/Add User.webp'
import Following from '../../../Assets/AuthorImage/following.webp'
import '../../CS/DiscoverAuthor.css'
import searchicon from '../../../../WS-WrittenStories/Assets/Images/Search.webp';
import FavButton from '../../../Assets/DiscoverAuthor/FavButton.webp'
import StarFav from '../../../Assets/DiscoverAuthor/StarFav.webp'
import Frame from '../../../Assets/DiscoverAuthor/Frame.webp'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'
import { CN_Author_Favorite, CN_Author_Follow, CN_Author_UnFavorite, CN_Author_Unfollow } from '../../../../../../Model/CN-ContributionNetwork/ContributionNetwork'

function DiscoverAuthorContent({ data, fav, getfav,
    loading }) {
    const navigate = useNavigate();
    const location = useLocation();
    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const [userId, setUserID] = useState(localStorage.getItem('UserID') || null);
    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
    }, []);
    const [authorFollow, setAuthorFollow] = useState(new Array(data.length).fill(false));
    const [authorFavor, setAuthorFavor] = useState(new Array(data.length).fill(false));
    const handleAuthorFollow = (author_id, index) => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            console.log('logged in');

            // Create a copy of the bookmarkedStories array
            const updatedAuthorFollow = [...authorFollow];

            // Toggle the bookmarked state for the specific story
            updatedAuthorFollow[index] = !updatedAuthorFollow[index];

            // Update the bookmarked state for all stories
            setAuthorFollow(updatedAuthorFollow);
        }
    }
    const handleAuthorFavor = (author_id, index) => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            console.log('logged in');

            // Create a copy of the bookmarkedStories array
            const updatedAuthorFavor = [...authorFavor];

            // Toggle the bookmarked state for the specific story
            updatedAuthorFavor[index] = !updatedAuthorFavor[index];

            // Update the bookmarked state for all stories
            setAuthorFavor(updatedAuthorFavor);
        }
    }
    const unfollowAuthor = (author_id) => {
        author_id = parseInt(author_id);
        // Define the request body
        const requestBody = {
            author_id: author_id,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .put(CN_Author_Unfollow, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const followAuthor = (author_id) => {
        author_id = parseInt(author_id);
        // Define the request body
        const requestBody = {
            author_id: author_id,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .post(CN_Author_Follow, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const unfavorAuthor = (author_id) => {
        author_id = parseInt(author_id);
        // Define the request body
        const requestBody = {
            author_id: author_id,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(CN_Author_UnFavorite, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const favorAuthor = (author_id) => {
        author_id = parseInt(author_id);
        // Define the request body
        const requestBody = {
            author_id: author_id,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(CN_Author_Favorite, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const handleLoginSignUp = () => {
        navigate('/loginpop');
    }
    const [searchText, setSearchText] = useState('');

    const handleAuthorProfile = (author_id, author_name) => {
        const formattedauthorName = author_name.replace(/\s+/g, '-');
        navigate(`/authorprofile/${author_id}/${formattedauthorName}`
        );
        window.scrollTo(0, 0);
    }
    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            navigate(`/favoriteauthor/${searchText}`
            );
            window.location.reload(); // Reload the screen
        }
    }
    return (
        <div className='cn-discover-author'>
            <div>
                <div className='cn-image-text-container'>
                    <div className='cn-discover-author-bg'>
                        <img src={BG} alt='background' />
                    </div>
                    <div className='cn-text-container'>
                        <div className='cn-bg-discover-author'>
                            <div className='cn-discover'>Favorite Authors</div>

                            <div className='cn-explore'>The most loved and favorite authors across the Wiki Bedtime Stories Community!</div>
                            <div className='web'>
                                <div className='cn-discover-search'>
                                    <div className='cn-author-searchbar'>
                                        <img src={searchicon} alt='searchicon' />
                                        <input
                                            id='searchauthorinput'
                                            className='cn-search-input'
                                            placeholder="Search authors"
                                            type="text"
                                            onChange={(e) => setSearchText(e.target.value)}
                                            onKeyPress={handleSearch}
                                        />
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <div className='cn-new-community-page'>
                    <div className='cn-new-community-header'>
                        <div className='cn-new-community-text'>
                            Chosen by community!
                        </div>
                    </div>
                    <div className='cn-new-author'>
                        {loading ? ( // Conditionally render the loading screen or data
                            <div className='loading'>
                                <div className='load'>Loading story</div>
                            </div>
                        ) : (<div className='author-favorite-card-container'>
                            {data.map((author, i) => {
                                return (
                                    <div key={i}>
                                        <div className='cn-new-author-card'>
                                            <div className='author-img' onClick={handleAuthorProfile.bind(null, author.author_id, author.author_name)}>
                                                <img src={author.image_name} alt='author-img' />

                                            </div>
                                            <div className='cn-author-name' onClick={handleAuthorProfile.bind(null, author.author_id, author.author_name)}>
                                                {author.author_name}
                                            </div>
                                            <div className='cn-author-design' onClick={handleAuthorProfile.bind(null, author.author_id, author.author_name)}>
                                                Author
                                            </div>
                                            <div className='cn-author-follow' onClick={() => handleAuthorFollow(author.author_id, i)}>
                                                {author.is_following ? (

                                                    authorFollow[i] ? (
                                                        <div className='cn-author-follow-inside' onClick={() => followAuthor(author.author_id, i)}>
                                                            <div className='cn-author-add'>
                                                                <img src={AddUser} alt='adduser' />
                                                            </div>
                                                            <div className='cn-add-follow'>
                                                                Follow
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className='cn-author-follow-inside' onClick={() => unfollowAuthor(author.author_id, i)}>
                                                            <div className='cn-author-add'>
                                                                <img src={Following} alt='adduser' />
                                                            </div>
                                                            <div className='cn-add-follow'>
                                                                Following
                                                            </div>
                                                        </div>
                                                    )

                                                ) : (

                                                    authorFollow[i] ? (
                                                        <div className='cn-author-follow-inside' onClick={() => unfollowAuthor(author.author_id, i)}>
                                                            <div className='cn-author-add'>
                                                                <img src={Following} alt='adduser' />
                                                            </div>
                                                            <div className='cn-add-follow'>
                                                                Following
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className='cn-author-follow-inside' onClick={() => followAuthor(author.author_id, i)}>
                                                            <div className='cn-author-add'>
                                                                <img src={AddUser} alt='adduser' />
                                                            </div>
                                                            <div className='cn-add-follow'>
                                                                Follow
                                                            </div>
                                                        </div>

                                                    )

                                                )
                                                }

                                            </div>
                                            <div className='cn-author-favorite' onClick={() => handleAuthorFavor(author.author_id, i)}>
                                                {author.is_favorite ? (
                                                    authorFavor[i] ? (
                                                        <div className='cn-author-follow-inside' onClick={() => favorAuthor(author.author_id, i)}>
                                                            <div className='cn-author-add'>
                                                                <img src={StarFav} alt='adduser' />
                                                            </div>
                                                            <div className='cn-add-favorite'>
                                                                Add favorite
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div className='cn-author-follow-inside' onClick={() => unfavorAuthor(author.author_id, i)}>
                                                            <div className='cn-author-add'>
                                                                <img src={StarFav} alt='adduser' />
                                                            </div>
                                                            <div className='cn-add-favorite'>
                                                                Added favorite
                                                            </div>
                                                        </div>
                                                    )
                                                ) : (
                                                    authorFavor[i] ?
                                                        (
                                                            <div className='cn-author-follow-inside' onClick={() => unfavorAuthor(author.author_id, i)}>
                                                                <div className='cn-author-add'>
                                                                    <img src={StarFav} alt='adduser' />
                                                                </div>
                                                                <div className='cn-add-favorite'>
                                                                    Added favorite
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className='cn-author-follow-inside' onClick={() => favorAuthor(author.author_id, i)}>
                                                                <div className='cn-author-add'>
                                                                    <img src={StarFav} alt='adduser' />
                                                                </div>
                                                                <div className='cn-add-favorite'>
                                                                    Add favorite
                                                                </div>
                                                            </div>
                                                        )
                                                )
                                                }

                                            </div>

                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                        )}
                    </div>
                </div>
                <div className='web'>
                    <div className='cn-new-choose-by-you'>
                        <div className='cn-new-community-header'>
                            <div className='cn-new-community-text'>
                                Chosen by you!
                            </div>
                        </div>

                        <div className='cn-new-author'>
                            {(authToken == null || authToken === "no token") ?
                                <div >
                                    <div className='cn-new-author-card'>
                                        <div className='frame-author-img'>
                                            <img src={Frame} alt='author-img' />

                                        </div>

                                        <div className='frame-author-design'>
                                            Signup to add yours
                                        </div>
                                        <div className='cn-author-follow' >
                                            <div className='cn-author-follow-inside' >

                                                <div className='cn-add-follow' onClick={handleLoginSignUp}>
                                                    Sign up
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div> :
                                <div>
                                    {loading ? ( // Conditionally render the loading screen or data
                                        <div className='loading'>
                                            <div className='load'>Loading story</div>
                                        </div>
                                    ) : (
                                        <div className='discover-author-card-container'>

                                            {getfav.map((author, i) => {
                                                return (
                                                    <div className='cn-author-followed' key={i}>
                                                        <div className='cn-new-author-followed-card'>
                                                            <div className='cn-author-img' onClick={handleAuthorProfile.bind(null, author.author_id, author.author_name)}>
                                                                <img src={author.image_name} alt='author-img' />

                                                            </div>
                                                            <div className='cn-follow-author-name' onClick={handleAuthorProfile.bind(null, author.author_id, author.author_name)}>
                                                                {author.author_name}
                                                            </div>
                                                            <div className='cn-follow-author-design' onClick={handleAuthorProfile.bind(null, author.author_id, author.author_name)}>
                                                                Author
                                                            </div>
                                                            <div className='cn-follow-fav'>
                                                                <div className='cn-author-follow' onClick={() => handleAuthorFollow(author.author_id, i)}>
                                                                    {author.is_following ? (

                                                                        authorFollow[i] ? (
                                                                            <div className='cn-author-follow-inside' onClick={() => followAuthor(author.author_id, i)}>
                                                                                <div className='cn-author-add'>
                                                                                    <img src={AddUser} alt='adduser' />
                                                                                </div>
                                                                                <div className='cn-add-follow'>
                                                                                    Follow
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <div className='cn-author-follow-inside' onClick={() => unfollowAuthor(author.author_id, i)}>
                                                                                <div className='cn-author-add'>
                                                                                    <img src={Following} alt='adduser' />
                                                                                </div>
                                                                                <div className='cn-add-follow'>
                                                                                    Following
                                                                                </div>
                                                                            </div>
                                                                        )

                                                                    ) : (

                                                                        authorFollow[i] ? (
                                                                            <div className='cn-author-follow-inside' onClick={() => unfollowAuthor(author.author_id, i)}>
                                                                                <div className='cn-author-add'>
                                                                                    <img src={Following} alt='adduser' />
                                                                                </div>
                                                                                <div className='cn-add-follow'>
                                                                                    Following
                                                                                </div>
                                                                            </div>
                                                                        ) : (
                                                                            <div className='cn-author-follow-inside' onClick={() => followAuthor(author.author_id, i)}>
                                                                                <div className='cn-author-add'>
                                                                                    <img src={AddUser} alt='adduser' />
                                                                                </div>
                                                                                <div className='cn-add-follow'>
                                                                                    Follow
                                                                                </div>
                                                                            </div>

                                                                        )

                                                                    )
                                                                    }

                                                                </div>
                                                                <div className='cn-favorite-author-add'>
                                                                    <img src={FavButton} alt='adduser' />
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    )}
                                </div>}

                        </div>
                    </div>
                </div>

            </div>


        </div>
    )
}

export default DiscoverAuthorContent
