/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:01-04-2023
Purpose : Explore Audio Stories Page 
Brief :
AudioStories, renders a page displaying audio content. It includes a header, an audio content component (AudioContent), and a footer. The component manages state variables such as data, page, loading, allStoriesLoaded, totalStories, and totalStoriesObject.

The useEffect hook is employed to fetch audio data, particularly sub-playlist data, based on the current page and the predefined page limit. 
It updates the state variables accordingly after fetching the data. 
The AudioContent component utilizes these state variables to display the audio stories, handle pagination, and manage the loading state.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useEffect, useState } from "react";
import Header from '../../../WS-WrittenStories/src/JS/Header/Header'
import AudioContent from './AudioContent'
import Footer from '../../../WS-WrittenStories/src/JS/Footer/Footer'
import '../../CS/AudioStories.css'
import { fetchSubPlaylistData } from '../../../../../Controller/AS-AudioStories/SubPlaylistController'
import { useLocation } from 'react-router-dom';

function AudioStories() {
    const [sortby, setSortBy] = useState('recent');
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const PAGE_LIMIT = 10; // number of items to display per page
    const [loading, setLoading] = useState(false);
    const [allStoriesLoaded, setAllStoriesLoaded] = useState(false);
    const [totalStories, setTotalStories] = useState(0);
    const [totalStoriesObject, setTotalStoriesObject] = useState(0);

    const location = useLocation();
    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const [userId, setUserID] = useState(localStorage.getItem('UserID') || null);
    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
    }, []);
    // expand audio content
    const [seekingInputMob, setSeekingInputob] = useState('seekinginputmob');
    const [expandedClassName, setExpandedClassName] = useState('as-audio-playstories');
    const [audioList, setAudioList] = useState('ws-stories')
    const [audioPagination, setAudioPagination] = useState('as-audio-pagination')
    const [audioSearch, setAudioSearch] = useState('as-player-search')
    const [audioPlayerBox, setAudioPlayerBox] = useState('as-player-box')
    const [audioPlayerBoxExpand, setAudioPlayerBoxExpand] = useState('as-player-box-expand-none')
    const [footerMob, setFooterMob] = useState('rights')
    const handleExpandClick = () => {
        setExpandedClassName('as-expand'); // Change the class name here
        setAudioList('ws-stories-expand');
        setAudioPagination('as-audio-pagination-expand')
        setAudioSearch('as-player-search-expand')
        setAudioPlayerBox('as-player-box-hide');
        setAudioPlayerBoxExpand('as-player-box-expand')
        setSeekingInputob('seekinginputmob-none');
        setFooterMob('rights-hide');
    };
    const handleExpandClose = () => {
        setExpandedClassName('as-audio-playstories'); // Change the class name here
        setAudioList('ws-stories');
        setAudioPagination('as-audio-pagination')
        setAudioSearch('as-player-search')
        setAudioPlayerBox('as-player-box');
        setAudioPlayerBoxExpand('as-player-box-expand-none')
        setFooterMob('rights');
    };

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);

            try {
                const newData = await fetchSubPlaylistData(page, PAGE_LIMIT, sortby, setSortBy, userId);
                setTotalStories(newData.totalStories);
                setTotalStoriesObject(newData.totalStoriesObject);
                setData(prevData => [...prevData, ...newData.data]);

                if (newData.data.length < PAGE_LIMIT) {
                    setAllStoriesLoaded(true);
                }
            } catch (error) {
                console.error(error);
            }

            setLoading(false);
        };

        fetchData();
    }, [page, sortby, setData]);
    return (
        <div>
            <Header
            />
            <AudioContent
                data={data} loading={loading} setPage={setPage} page={page}
                allStoriesLoaded={allStoriesLoaded} totalStories={totalStories}
                totalStoriesObject={totalStoriesObject}
                setSortBy={setSortBy}
                setData={setData}
                //expand
                expandedClassName={expandedClassName}
                audioList={audioList}
                audioPagination={audioPagination}
                audioSearch={audioSearch}
                audioPlayerBox={audioPlayerBox}
                onExpandClick={handleExpandClick}
                onExpandClose={handleExpandClose}
                audioPlayerBoxExpand={audioPlayerBoxExpand}
                seekingInputMob={seekingInputMob}
            />
            <Footer
                footerMob={footerMob}
            />
        </div>
    )
}

export default AudioStories
