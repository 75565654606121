/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:01-04-2023
Purpose : The purpose of this code is to fetch stories based on Category List.
Brief : The code contains an asynchronous function named fetchStories responsible for retrieving stories associated with specific search tags. It utilizes the searchtag endpoint from the SM-StoriesMetadata model to construct the API endpoint required to fetch stories based on the provided search text (searchTextParam), page number, and page size. The function makes an asynchronous GET request using Axios, including the search parameters and page details in the request URL, along with an authorization token obtained from the authToken. Upon receiving the response, it extracts and returns the data containing the fetched stories.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

const PAGE_LIMIT = 10;
const fetchDataFromApi = async (page, sortBy, setSortBy, selectedCategory, subCategoryID, setTotalStories, setTotalStoriesObject, userId) => {
    const apiUrl = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/pages/category?&sortMode=DESC&sortBy=${sortBy}&filterBy=all_stories&status=3&primary_category=${selectedCategory}&secondary_category=${subCategoryID}&pageNumber=${page}&pageSize=${PAGE_LIMIT}&userId=${userId}`;

    try {
        const response = await fetch(apiUrl);
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        const { totalStories, totalStoriesObject } = responseData;
        setTotalStories(totalStories);
        setTotalStoriesObject(totalStoriesObject);
        if (Array.isArray(responseData.data) && responseData.data.length > 0) {

            return responseData.data;
        } else {
            return [];
        }
    } catch (error) {
        throw new Error('Error fetching data');
    }
};
export default fetchDataFromApi;
