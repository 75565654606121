/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:-12-14-2023
Purpose : This code is to provide Controller for UserList.
Brief : The code consists of several API endpoint constants crucial for managing CN-related functionalities. It includes endpoints for handling CN drafts, adding tags to stories, and fetching metadata related to characters used in stories. These endpoints are structured to facilitate the creation and management of content within the application.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import axios from 'axios';

import { CN_Author_Profile } from '../../../Model/CN-ContributionNetwork/ContributionNetwork';
import { userdetail } from '../../../Model/UM-UserManagement/UserManagement';

const fetchUserProfileData = async (userId, setUserProfileData) => {

    try {
        const response = await axios.get(`${userdetail}`, {
            params: {
                userId: userId
            }
        });
        const json = response.data;
        // Store the new image URL from the API response in localStorage
        if (json.data.ImageName) {
            localStorage.setItem('ImageName', json.data.ImageName);
        }

        setUserProfileData(json.data);
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export default fetchUserProfileData;