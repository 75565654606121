/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:12-19-2023
Purpose : SignUpPopUp
Brief :
Developer : Amit Kalita
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useState, useEffect, useRef } from 'react'
import LoginBanner from '../../Assets/LoginBG.webp'
import Profile from '../../Assets/Profile.webp'
import Message from '../../Assets/Message.webp'
import Lock from '../../Assets/Lock.webp'
import EyeOpen from '../../Assets/eye.webp'
import EyeClosed from '../../Assets/eye hide password .webp'
import { useNavigate } from 'react-router-dom'
import { UM_SignUp } from '../../../../../Model/UM-UserManagement/UserManagement';
import axios from 'axios';
import GoogleButton from './GoogleButton'
import FacebookSignIn from './FacebookButton'
import DangerCircle from '../../Assets/Danger Circle.webp'

function SignUpPop({ closePopup }) {
    const navigate = useNavigate();
    const popupRef = useRef(null);
    const url = UM_SignUp;
    const [nameVal, setNameVal] = useState('');
    const [emailVal, setEmailVal] = useState('');
    const [passwordVal, setPasswordVal] = useState('');
    const [cnfPasswordVal, setCnfPasswordVal] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleSignUpSubmit = async () => {
        try {
            setLoading(true);

            if (passwordVal.length < 8) {
                setErrorMessage('Password should be at least 8 characters long');
                return;
            }
            if (passwordVal !== cnfPasswordVal) {
                setErrorMessage("Passwords don't match");
                return;
            }

            // Reset error message if there are no issues
            setErrorMessage('');

            const url = UM_SignUp;
            const data = {
                password: passwordVal,
                name: nameVal,
                email: emailVal,
                fcmToken: "zzzz",
                deviceType: "react",
                loginType: "normal"
            };

            const response = await axios.post(url, data);

            if (response.data.status === 200) {
                const Image = response.data.data.ImageName;
                const ImageExist = response.data.data.ImageExist;
                const UserID = response.data.data.UserID;
                // Save UserID and token to local storage
                localStorage.setItem('UserID', UserID);
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('ImageName', Image);
                localStorage.setItem('ImageExist', ImageExist);
                navigate('/')
                handleWelcome(response.data.token);
            } else {
                // Set error message if signup fails
                setErrorMessage(response.data.message);
            }

        } catch (error) {
            // Handle errors as needed
            console.error('Error during signup:', error);
        } finally {
            setLoading(false);
        }
    };
    const handleWelcome = async (authToken) => {
        try {
            const response = await axios.post(`https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/user/welcome`, {
            }, {
                headers: {
                    'Authorization': authToken
                }
            });

            // Handle the response as needed

        } catch (error) {
            // Handle errors as needed
            console.error('Error during welcome request:', error);
        }
    };
    const handleCloseAlert = () => {
        setErrorMessage(null);
    };
    const handlepopup = (event) => {
        const targetId = event.target.id;

        // Check the id and navigate accordingly
        if (targetId === 'pop-up-overlay') {
            navigate('/');
        } else if (targetId === 'um-login') { }
    };
    return (
        <div className="pop-up-overlay"
            id='pop-up-overlay'
            onClick={handlepopup}
        >
            <div className='um-login'
                ref={popupRef}
            >
                <div className='um-login-banner'>
                    <div className='um-login-img'>
                        <img src={LoginBanner} alt='LoginBanner' />
                    </div>
                </div>
                <div className='um-login-detail'>
                    <div>
                        <div className='um-login-account'>
                            Sign Up
                        </div>
                        <div className='um-login-social'>
                            Sign up using social networks
                        </div>
                        <div className='um-login-google-fb'>
                            {/* <FacebookSignIn /> */}

                            <GoogleButton />

                        </div>
                        <div className='um-login-email'>
                            or use your email address for registration
                        </div>
                        <div className='um-login-emailbox'>

                            <div className='um-name-textbox'>
                                <div className='um-name-msg'>
                                    <img src={Profile} alt='message' />

                                </div>
                                <input onChange={(e) => setNameVal(e.target.value)} type='email'
                                    className='um-email-inputbox'
                                    placeholder="Name"
                                />
                            </div>
                        </div>
                        <div className='um-login-emailbox'>

                            <div className='um-email-msg-textbox'>
                                <div className='um-email-msg'>
                                    <img src={Message} alt='message' />

                                </div>
                                <input onChange={(e) => setEmailVal(e.target.value)} type='email'
                                    className='um-email-inputbox'
                                    placeholder="Email Address"
                                />
                            </div>
                        </div>
                        <div className='um-login-passwordbox'>
                            <div className='um-password-lock-textbox'>
                                <div className='um-password-lock'>
                                    <img src={Lock} alt='lock' />

                                </div>
                                <input onChange={(e) => setPasswordVal(e.target.value)}
                                    type={showPassword ? 'text' : 'password'}
                                    className='um-password-inputbox'
                                    placeholder="Password"
                                />
                                <div className='um-eye' onClick={togglePasswordVisibility}>
                                    <img src={showPassword ? EyeClosed : EyeOpen} alt={showPassword ? 'eyeclosed' : 'eyeopen'} />
                                </div>
                            </div>
                        </div>
                        <div className='um-login-passwordbox'>
                            <div className='um-password-lock-textbox'>
                                <div className='um-password-lock'>
                                    <img src={Lock} alt='lock' />

                                </div>
                                <input onChange={(e) => setCnfPasswordVal(e.target.value)}
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    className='um-password-inputbox'
                                    placeholder="Confirm Password"
                                />
                                <div className='um-eye' onClick={toggleConfirmPasswordVisibility}>
                                    <img src={showConfirmPassword ? EyeClosed : EyeOpen} alt={showConfirmPassword ? 'eyeclosed' : 'eyeopen'} />
                                </div>
                            </div>
                        </div>
                        {errorMessage && (
                            <div className="alert-overlay" onClick={handleCloseAlert}>
                                <div className='alert-box-img' onClick={handleCloseAlert}><img src={DangerCircle} alt='alert-danger' /></div>
                                <div className="um-alert-box">
                                    {errorMessage}

                                </div>
                            </div>
                        )}
                        <div className='um-login-btn' onClick={handleSignUpSubmit}>
                            Register
                        </div>
                        {/* Display loading overlay or spinner based on the loading state */}
                        {loading && (
                            <div className="loading-overlay">
                                Loading...
                            </div>
                        )}
                        <div className='um-does-signup'>
                            <div className='um-account'>
                                Already have an account?&nbsp;
                            </div>
                            <div
                                // onClick={handleSignupClick}
                                onClick={() => navigate('/loginpop')}
                                className='um-signup-btn'>
                                Log In
                            </div>
                        </div>
                    </div>


                </div>


            </div>

        </div>
    )
}

export default SignUpPop
