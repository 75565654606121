/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:10-19-2023
Purpose : The CategoryList component returns JSX (JavaScript XML) elements representing the layout of the webpage.
Brief :Header: Represents the header section of the webpage.
       SearchBox: Represents a search box, presumably for searching within the categories.
       CategoryListView: Displays a view for the list of categories.
       Footer: Represents the footer section of the webpage.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */
import React, { useState, useEffect } from 'react';
import Header from '../../../WS-WrittenStories/src/JS/Header/Header'
import CategoryListView from './CategoryListView'
import Footer from '../../../WS-WrittenStories/src/JS/Footer/Footer'
import SearchBox from '../../../WS-WrittenStories/src/JS/StoriesList/SearchBox'
import { useParams, useLocation } from 'react-router-dom';
import fetchDataFromApi from '../../../../../Controller/SM-StoriesMetadata/CategoryListViewController';// Import the fetchDataFromApi function

function CategoryList() {
    const location = useLocation();
    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const [userId, setUserID] = useState(localStorage.getItem('UserID') || null);
    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
    }, []);
    const [sortby, setSortBy] = useState('story_id');
    const { selectedCategory, subCategoryID } = useParams();
    const PAGE_LIMIT = 10;
    const [categoryData, setCategoryData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0); // State to store total pages
    const [totalStories, setTotalStories] = useState(0);
    const [totalStoriesObject, setTotalStoriesObject] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchDataFromApi(page, sortby, setSortBy, selectedCategory, subCategoryID, setTotalStoriesObject, setTotalStories, userId);
                setCategoryData(data);
                setLoading(false);
                // Check if 'totalStories' is present in the fetched data
                const totalStories = data?.totalStories || 0; // Access total stories from fetched data

                // Check the value of PAGE_LIMIT and totalPagesCount calculation
                const totalPagesCount = Math.ceil(totalStories / PAGE_LIMIT);

                setTotalPages(totalPagesCount);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        fetchData();
    }, [selectedCategory, sortby, subCategoryID, page]);
    return (
        <div>
            <Header authToken={(location.state) ? location.state.authToken : null} />
            <SearchBox />
            <CategoryListView categoryData={categoryData}
                loading={loading}
                setSortBy={setSortBy}
                error={error}
                totalStories={totalStories}
                totalStoriesObject={totalStoriesObject}
                setPage={setPage} page={page}
                setCategoryData={setCategoryData}
                totalPages={totalPages}
            />
            <Footer />
        </div>
    )
}

export default CategoryList
