/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:01-04-2023
Purpose : This code is to provide data model for Audio stories 
Brief : The code comprises several API endpoint constants essential for managing Audio Stories functionalities. It includes endpoints related to retrieving sub-playlists and player information for audio content. Additionally, an endpoint for updating story views is present, which might be used in conjunction with audio stories to manage view counts.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

// export const sub_playlist = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/audio/get_playlist?userId=1&playlistId=4`
export const sub_playlist = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/audio/get_playlist?&playlistId=4`
export const sub_playler = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/audio/get_playlist?playlistId=4`
export const update_story_views = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/view`
export const audiolisten = `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0//audio/listening`