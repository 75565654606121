/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:10-19-2023
Purpose : Moderator Page
Brief :
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './Character.css'

const StoryCharacter = () => {
    const { storyID } = useParams();
    const [characters, setCharacters] = useState([]);
    const [popupVisibleFor, setPopupVisibleFor] = useState(null); // State to track which character's popup is visible

    useEffect(() => {
        fetchCharacterData(); // Call fetchCharacterData when the component mounts
    }, []);

    const fetchCharacterData = async () => {
        try {
            const response = await fetch(`https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/metadata/characters_by_story?pageNumber=1&pageSize=10&story_id=${storyID}`);
            const responseData = await response.json();
            const charactersData = responseData.data || [];
            console.log(charactersData);
            setCharacters(charactersData);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const showPopup = (characterID) => {
        setPopupVisibleFor(characterID);
    };

    const hidePopup = () => {
        setPopupVisibleFor(null);
    };

    const handleDeleteClick = (characterID) => {
        deleteCharacter(characterID);
    };

    const deleteCharacter = async (characterID) => {
        try {
            const response = await fetch(`https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/metadata/delete_map_story_character?story_id=${storyID}&character_id=${characterID}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                fetchCharacterData(); // Reload characters after deletion
                hidePopup();
            } else {
                console.error('Error deleting character:', response.statusText);
            }
        } catch (error) {
            console.error('Error deleting character:', error);
        }
    };

    return (
        <div>
            {characters && characters.length > 0 ? (
                characters.map((character, index) => (
                    <div className='Existing-character-container' key={character.character_id}>
                        <div className='character-box'>
                            <span className='character-close' onClick={() => showPopup(character.character_id)}>
                                &times;
                            </span>
                            {popupVisibleFor === character.character_id && (
                                <div className='popup-overlay'>
                                    <div className='popup-content'>
                                        <p>Are you sure you want to delete?</p>
                                        <div className='popup-buttons'>
                                            <button onClick={() => handleDeleteClick(character.character_id)}>Delete</button>
                                            <button onClick={hidePopup}>Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            )}
                            <div className='tag' style={{ marginBottom: '4px' }}>
                                Existing Character Name: {character.character_name}
                            </div>
                            <div className='tag' style={{ marginBottom: '4px' }}>
                                Existing Character Category: {character.character_category}
                            </div>
                            <div className='tag' style={{ marginBottom: '4px' }}>
                                {character.region_name} - {character.era_name} - {character.mythology_name} - {character.epic_name} - {character.folktales_name}
                            </div>
                        </div>
                    </div>
                ))
            ) : (
                <div className='tag'>No characters available</div>
            )}
        </div>
    );
};

export default StoryCharacter;
