/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:12-12-2023
Purpose : AuthorList
Brief :
Developer : Jayesh Todi
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useEffect, useState } from 'react'
import Header from '../../../../WS-WrittenStories/src/JS/Header/Header'
import Footer from '../../../../WS-WrittenStories/src/JS/Footer/Footer'
import AuthorCard from './AuthorCard'
import '../../CS/AuthorProfile.css'
import AuthorSocialMediaCard from './AuthorSocialMediaCard'
import AuthorPageSearchBar from './AuthorPageSearchBar'
import AuthorDescriptionCard from './AuthorDescriptionCard'
import AuthorPublications from './AuthorPublications'
import fetchAuthorProfileData from '../../../../../../Controller/CN-ContributionNetwork/AuthorProfile/AuthorProfile'
import fetchAuthorProfilePublicationsData from '../../../../../../Controller/CN-ContributionNetwork/AuthorProfile/AuthorProfilePublications'
import { useLocation, useParams } from 'react-router-dom'
import fetchAuthorProfileFollowers from '../../../../../../Controller/CN-ContributionNetwork/AuthorProfile/AuthorProfileFollowing'
import fetchAuthorProfileFollowing from '../../../../../../Controller/CN-ContributionNetwork/AuthorProfile/AuthorProfileFollowers'
import Exception from '../../../../AM-AplicationManagement/src/JS/Exception/Exception'

const AuthorProfile = () => {
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [totalStories, setTotalStories] = useState(0);
  const currentUrl = window.location.href;
  const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
  const [userId, setUserID] = useState(localStorage.getItem('UserID') || null);
  useEffect(() => {
    // Update component state with values from localStorage on mount
    setAuthToken(localStorage.getItem('token') || null);
    setUserID(localStorage.getItem('UserID') || null);
  }, []);
  const { authorId } = useParams();
  const [authorProfileData, setAuthorProfileData] = useState({
    name: '',
    author_id: Number(authorId),
    biography: '',
    author_image: '',
    facebook_url: "",
    instagram_url: "",
    linkedin_url: "",
    twitter_url: "",
    social_media_status: false,
    total_stories: 0,
    total_views: 0,
    rating: "0",
    total_likes: 0,
    audio_story_count: 0,
    followers_count: 0,
    following_count: 0,
    add_to_favorite: 0,
    is_following: false,
    is_favorite: false
  });

  const [authorProfilePublicationsData, setAuthorProfilePublicationsData] = useState([]);
  const [authorProfileAudioData, setAuthorProfileAudioData] = useState([]);
  const [authorProfileFollowers, setAuthorProfileFollowersData] = useState([]);
  const [authorProfileFollowing, setAuthorProfileFollowingData] = useState([]);
  const [displayList, setDisplayList] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  useEffect(() => {
    const existingData = authorProfilePublicationsData;
    const existingFollowData = authorProfileFollowers;
    const existingAudioData = authorProfileAudioData;
    const existingFollowingData = authorProfileFollowing;
    fetchAuthorProfileData(authorId, userId, setAuthorProfileData);

    if (displayList === 0)
      fetchAuthorProfilePublicationsData(page, authorId, setTotalStories, setAuthorProfilePublicationsData, "all_stories", existingData, setHasMore);
    else if (displayList === 3)
      fetchAuthorProfilePublicationsData(page, authorId, setTotalStories, setAuthorProfileAudioData, "audio", existingAudioData, setHasMore);

    else if (displayList === 1) {
      fetchAuthorProfileFollowers(page, authorId, setAuthorProfileFollowersData, existingFollowData, setHasMore);
    }
    else if (displayList === 2) fetchAuthorProfileFollowing(page, authorId, setAuthorProfileFollowingData, existingFollowingData, setHasMore);
    else if (displayList === 4) fetchAuthorProfilePublicationsData(page, authorId, setTotalStories, setAuthorProfilePublicationsData, "story", existingData, setHasMore, 4);
    else if (displayList === 5) fetchAuthorProfilePublicationsData(page, authorId, setTotalStories, setAuthorProfilePublicationsData, "story", existingData, setHasMore, 5);
    else if (displayList === 6) fetchAuthorProfilePublicationsData(page, authorId, setTotalStories, setAuthorProfilePublicationsData, "story", existingData, setHasMore, 6);
  }, [page, displayList, authorId])

  return (
    <div>
      <Header authToken={(location.state) ? location.state.authToken : null} />
      <div className='web'>
        <div className='AuthorCardMainSection'>
          <div className='AuthorCardFirstSection'>
            <AuthorCard setPage={setPage} data={authorProfileData} authorId={authorId} setData={setAuthorProfileData} setDisplayList={setDisplayList} displayList={displayList} setAuthorProfilePublicationsData={setAuthorProfilePublicationsData} setAuthorProfileAudioData={setAuthorProfileAudioData} setAuthorProfileFollowersData={setAuthorProfileFollowersData} setAuthorProfileFollowingData={setAuthorProfileFollowingData}
              fetchAuthorProfileData={fetchAuthorProfileData} // Pass the function as a prop
            />
            <AuthorSocialMediaCard data={authorProfileData} />
          </div>
          <div className='AuthorCardSecondSection'>
            {/* <AuthorPageSearchBar /> */}
            <AuthorDescriptionCard setDisplayList={setDisplayList} profileData={authorProfileData} setAuthorProfilePublicationsData={setAuthorProfilePublicationsData} setAuthorProfileAudioData={setAuthorProfileAudioData} setAuthorProfileFollowersData={setAuthorProfileFollowersData} setAuthorProfileFollowingData={setAuthorProfileFollowingData} />
            <AuthorPublications profileData={authorProfileData} page={page} totalStories={totalStories} setPage={setPage} audioData={authorProfileAudioData} data={authorProfilePublicationsData} displayList={displayList} authorFollowerData={authorProfileFollowers} authorFollowingData={authorProfileFollowing} hasMore={hasMore} />
          </div>
        </div>
      </div>
      <div className='mobile'>
        <AuthorCard setPage={setPage} data={authorProfileData} authorId={authorId} setData={setAuthorProfileData} setDisplayList={setDisplayList} displayList={displayList} setAuthorProfilePublicationsData={setAuthorProfilePublicationsData} setAuthorProfileAudioData={setAuthorProfileAudioData} setAuthorProfileFollowersData={setAuthorProfileFollowersData} setAuthorProfileFollowingData={setAuthorProfileFollowingData}
          fetchAuthorProfileData={fetchAuthorProfileData} // Pass the function as a prop
        />
        <AuthorSocialMediaCard data={authorProfileData} />

        <AuthorPageSearchBar />
        <AuthorDescriptionCard setDisplayList={setDisplayList} profileData={authorProfileData} setAuthorProfilePublicationsData={setAuthorProfilePublicationsData} setAuthorProfileAudioData={setAuthorProfileAudioData} setAuthorProfileFollowersData={setAuthorProfileFollowersData} setAuthorProfileFollowingData={setAuthorProfileFollowingData} />
        <AuthorPublications profileData={authorProfileData} page={page} totalStories={totalStories} setPage={setPage} audioData={authorProfileAudioData} data={authorProfilePublicationsData} displayList={displayList} authorFollowerData={authorProfileFollowers} authorFollowingData={authorProfileFollowing} hasMore={hasMore} />

      </div>

      <Footer />
    </div>
  )
}

export default AuthorProfile