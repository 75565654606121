/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:10-19-2023
Purpose : Footer 
Brief : Purpose: This code represents the footer section of the "wikibedtimestories.com" website.
Developer Details: The developer of this component is identified as Amit Kalita, contactable via email or phone number provided.
App Information: It's part of the larger web application named "wikibedtimestories.com," licensed under Kushmanda Tech LLC.
Functionality:
Structure: Contains both web and mobile versions of the footer.
Elements:
Displays the website logo and name.
Includes contact information for the company.
Provides links to pages such as About Us, Cookie Policy, Terms of Use, and Privacy Policy.
Showcases product links and social media links (Facebook, Twitter, LinkedIn, Instagram).
Offers a Play Store link for the mobile app.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React from 'react'
import WBSLogo from '../../../Assets/Images/WBSLogo.png'
import Instagram from '../../../Assets/Images/Instagram.webp'
import Facebook from '../../../Assets/Images/Facebook.webp'
import Twitter from '../../../Assets/Images/Twitter.webp'
import YouTube from '../../../Assets/Images/youtube.webp'
import PlayStore from '../../../Assets/Images/GooglePlay.webp'
import '../../CS/Footer.css'
import { useNavigate } from 'react-router-dom'

function Footer() {
    const navigate = useNavigate();
    const whiteColor = {
        color: 'var(--white, #FFF)',
    };
    return (
        <div>
            <div className='web'>
                <div className='footer'>
                    <div className='footer1'>
                        <div className='footer-text-logo'>
                            <img src={WBSLogo} alt='App-logo'></img>
                            <div className='web-name'><span style={whiteColor}>Wiki</span> Bedtime Stories</div>

                        </div>
                        <div className='middle'></div>

                        <div className='footer-SM'>
                            <div className='ws-footer-heading-follow'>FOLLOW US</div>
                            <div className='social-media'>

                                <div className='social-logo' onClick={() => { window.open('//www.facebook.com/profile.php?id=100076326081861', '_blank') }}>
                                    <img src={Facebook} alt='facebook' />
                                </div>
                                <div className='social-logo'>
                                    <img src={Twitter} alt='twitter' onClick={() => { window.open('//twitter.com/wikibedtime', '_blank') }} />
                                </div>
                                <div className='social-logo'>
                                    <img src={YouTube} alt='youtube' onClick={() => { window.open('//youtube.com/@Wikibedtimestories', '_blank') }} />
                                </div>
                                <div className='social-logo' onClick={() => { window.open('//www.instagram.com/wikibedtimestories/', '_blank') }}>
                                    <img src={Instagram} alt='instagram' />
                                </div>

                            </div>

                        </div>
                        <div className='footerlast'>
                            <div className='play' onClick={() => { window.open('//play.google.com/store/apps/details?id=tech.kushmanda.wikibedtimestories&hl=en&gl=US', '_blank') }}>
                                <img src={PlayStore} alt='PlayStore' />
                            </div>
                        </div>

                    </div>
                    <div className='footer2'>
                        <div className='footer4'>
                            <div className='ws-footer-heading'>OUR PRODUCTS:&nbsp;</div>
                            <div className='ws-footer-para-kushmanda' onClick={() => { window.open('//kushmanda.tech/products', '_blank') }}>kushmanda.tech/products</div>
                        </div>
                        <div className='footercorner'>
                            <div className='ws-footer-heading'>CONTACT US:</div>
                            <div className='ws-footer-para'>
                                San Diego(contact@kushmanda.ai)
                            </div>
                        </div>
                    </div>
                    <div className='footer6'>

                    </div>
                    <div className='footerend'>
                        <div className='footer7'>
                            <div className='ws-footer-para-btn' onClick={() => { navigate('/aboutus'); window.scrollTo(0, 0); }}>Our Story</div>
                            <div className='ws-footer-para-btn'
                                onClick={() => { navigate('/cookie'); window.scrollTo(0, 0); }}
                            >Cookie Policy</div>
                            <div className='ws-footer-para-btn' onClick={() => { navigate('/term'); window.scrollTo(0, 0); }}>Terms of Use</div>
                            <div className='ws-footer-para-btn' onClick={() => { navigate('/privacy'); window.scrollTo(0, 0); }}>Privacy Policy</div>

                        </div>
                    </div>
                </div>
            </div>
            <div className='mobile'>
                <div className='rights'>
                    © All rights reserved.
                </div>
            </div>
        </div>
    )
}

export default Footer
