/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:12-12-2023
Purpose : AuthorList
Brief :
Developer : Amit Kalita
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useEffect, useState } from 'react'
import Following from '../../../Assets/AuthorImage/following.webp'
import AddUser from '../../../Assets/AuthorProfile/Add User.svg';
import StarFav from '../../../Assets/AuthorProfile/Star.svg';
import sample from '../../../Assets/AuthorProfile/Cat.png'

import defaultImage from '../../../../WS-WrittenStories/Assets/Images/defaultprofile.webp'
import { useLocation, useNavigate } from 'react-router-dom';
import { CN_Author_Favorite, CN_Author_Follow, CN_Author_UnFavorite, CN_Author_Unfollow } from '../../../../../../Model/CN-ContributionNetwork/ContributionNetwork';
import axios from 'axios';

const FavoriteAuthorSearchCard = ({ author, index, dataLength }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const [userId, setUserID] = useState(localStorage.getItem('UserID') || null);
    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
    }, []);
    const [authorFollow, setAuthorFollow] = useState(new Array(dataLength).fill(false));
    const [authorFavor, setAuthorFavor] = useState(new Array(dataLength).fill(false));

    const handleAuthorFollow = (author_id, index) => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            console.log('logged in');

            // Create a copy of the bookmarkedStories array
            const updatedAuthorFollow = [...authorFollow];

            // Toggle the bookmarked state for the specific story
            updatedAuthorFollow[index] = !updatedAuthorFollow[index];

            // Update the bookmarked state for all stories
            setAuthorFollow(updatedAuthorFollow);
        }
    }
    const unfollowAuthor = (author_id) => {
        author_id = parseInt(author_id);
        // Define the request body
        const requestBody = {
            author_id: author_id,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .put(CN_Author_Unfollow, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const followAuthor = (author_id) => {
        author_id = parseInt(author_id);
        // Define the request body
        const requestBody = {
            author_id: author_id,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .post(CN_Author_Follow, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const handleAuthorFavor = (author_id, index) => {
        if (authToken == null || authToken === "no token") {
            navigate('/loginpop');
        } else {
            console.log('logged in');

            // Create a copy of the bookmarkedStories array
            const updatedAuthorFavor = [...authorFavor];

            // Toggle the bookmarked state for the specific story
            updatedAuthorFavor[index] = !updatedAuthorFavor[index];

            // Update the bookmarked state for all stories
            setAuthorFavor(updatedAuthorFavor);

            // Perform other actions for bookmarking like updating the story if needed
            // updateStoryBookmark(storyID);
        }
    }
    const unfavorAuthor = (author_id) => {
        author_id = parseInt(author_id);
        // Define the request body
        const requestBody = {
            author_id: author_id,
        };
        // console.log(storyID)
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(CN_Author_UnFavorite, requestBody, { headers: requestHeaders })
            .then((response) => {
                // window.location.reload(); // Reload the screen
                // console.log(response.data); // Handle the API response here
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const favorAuthor = (author_id) => {
        author_id = parseInt(author_id);
        // Define the request body
        const requestBody = {
            author_id: author_id,
        };
        // Define the request headers with the authToken
        const requestHeaders = {
            Authorization: `${authToken}`, // Include the authToken in the request header
        };
        axios
            .patch(CN_Author_Favorite, requestBody, { headers: requestHeaders })
            .then((response) => {
            })
            .catch((error) => {
                console.error(error); // Handle errors here
            });
    };
    const [errorMessage, setErrorMessage] = useState(null); // State to store error message

    // Handle API response errors
    const handleApiError = (error) => {
        if (error.response && error.response.status === 401) {
            // Unauthorized error handling (e.g., redirect to login)
            console.error('Unauthorized error:', error);
            navigate('/loginpop');
        } else if (error.response && error.response.data && error.response.data.message === 'No Author found') {
            // No Author found error handling
            console.error('No Author found error:', error);
            setErrorMessage('No Author found'); // Set the error message
        } else {
            // Other error handling
            console.error('API Error:', error);
            // Handle other errors here, such as showing a generic error message
            setErrorMessage('An error occurred. Please try again.'); // Set a generic error message
        }
    };
    const handleAuthorProfile = (author_id, author_name) => {
        const formattedauthorName = author_name.replace(/\s+/g, '-');
        navigate(`/authorprofile/${author_id}/${formattedauthorName}`
        );
        window.scrollTo(0, 0);
    }
    return (
        <div className='cn-author-followed'>
            {errorMessage && (
                <div className='error-message'>
                    {/* Display the error message to the user */}
                    {errorMessage}
                </div>
            )}
            <div className='cn-new-author-followed-card'>
                <div className='cn-author-img' onClick={handleAuthorProfile.bind(null, author.author_id, author.author_name)}>
                    <img src={author.image_name} alt='author-img' />
                </div>

                <div className='cn-follow-author-name' onClick={handleAuthorProfile.bind(null, author.author_id, author.author_name)}>
                    {author.author_name}
                </div>
                <div className='cn-follow-author-bio' onClick={handleAuthorProfile.bind(null, author.author_id, author.author_name)}>
                    {author.biography}
                </div>
                <div className='cn-follow-fav'>
                    <div className='cn-author-follow' onClick={() => handleAuthorFollow(author.author_id, index)}>
                        {author.is_following ? (

                            authorFollow[index] ? (
                                <div className='cn-author-follow-inside' onClick={() => followAuthor(author.author_id, index)}>
                                    <div className='cn-author-add'>
                                        <img src={AddUser} alt='adduser' />
                                    </div>
                                    <div className='cn-add-follow'>
                                        Follow
                                    </div>
                                </div>
                            ) : (
                                <div className='cn-author-follow-inside' onClick={() => unfollowAuthor(author.author_id, index)}>
                                    <div className='cn-author-add'>
                                        <img src={Following} alt='adduser' />
                                    </div>
                                    <div className='cn-add-follow'>
                                        Following
                                    </div>
                                </div>
                            )

                        ) : (

                            authorFollow[index] ? (
                                <div className='cn-author-follow-inside' onClick={() => unfollowAuthor(author.author_id, index)}>
                                    <div className='cn-author-add'>
                                        <img src={Following} alt='adduser' />
                                    </div>
                                    <div className='cn-add-follow'>
                                        Following
                                    </div>
                                </div>
                            ) : (
                                <div className='cn-author-follow-inside' onClick={() => followAuthor(author.author_id, index)}>
                                    <div className='cn-author-add'>
                                        <img src={AddUser} alt='adduser' />
                                    </div>
                                    <div className='cn-add-follow'>
                                        Follow
                                    </div>
                                </div>

                            )

                        )
                        }


                    </div>
                    <div className='cn-community-author-favorite' onClick={() => handleAuthorFavor(author.author_id, index)}>
                        {author.is_favorite ? (
                            authorFavor[index] ? (
                                <div className='cn-author-follow-inside' onClick={() => favorAuthor(author.author_id, index)}>
                                    <div className='cn-author-add'>
                                        <img src={StarFav} alt='adduser' />
                                    </div>
                                    <div className='cn-add-favorite'>
                                        Add to favorite
                                    </div>
                                </div>
                            ) : (
                                <div className='cn-author-follow-inside' onClick={() => unfavorAuthor(author.author_id, index)}>
                                    <div className='cn-author-add'>
                                        <img src={StarFav} alt='adduser' />
                                    </div>
                                    <div className='cn-add-favorite'>
                                        Added to favorite
                                    </div>
                                </div>
                            )
                        ) : (
                            authorFavor[index] ?
                                (
                                    <div className='cn-author-follow-inside' onClick={() => unfavorAuthor(author.author_id, index)}>
                                        <div className='cn-author-add'>
                                            <img src={StarFav} alt='adduser' />
                                        </div>
                                        <div className='cn-add-favorite'>
                                            Added to favorite
                                        </div>
                                    </div>
                                ) : (
                                    <div className='cn-author-follow-inside' onClick={() => favorAuthor(author.author_id, index)}>
                                        <div className='cn-author-add'>
                                            <img src={StarFav} alt='adduser' />
                                        </div>
                                        <div className='cn-add-favorite'>
                                            Add to favorite
                                        </div>
                                    </div>
                                )
                        )
                        }

                    </div>

                </div>

            </div>
        </div>
    )
}

export default FavoriteAuthorSearchCard