/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:10-19-2023
Purpose : Server Down Page
Brief :
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React from 'react'
import '../../CS/ServerDown.css'
import BackArrow from '../../../Assets/Images/Arrow - Left.webp'
import { useNavigate } from 'react-router-dom'

function ServerDown() {
    const navigate = useNavigate();
    return (
        <div className='am-server'>
            <div className='am-notfound-404'>404</div>
            <div className='am-notfoundwhop'>We're experiencing technical difficulties.</div>
            <div className='am-notfound-page'>Our servers are currently down for maintenance or due to an unexpected issue. We're working hard to get things back up and running as soon as possible.</div>
            <div className='am-notfoundwhop'>What you can do:</div>
            <div className='am-notfound-page'>Refresh the page: Sometimes the issue is temporary, and a simple refresh might help.
                Check back later: We're actively working on resolving the problem. Please try visiting the site again in a little while.</div>
            <div className='am-return-btn-text'>
                <div className='am-return-btn' onClick={() => { navigate('/') }}>
                    <img src={BackArrow} alt='backbtn' />
                </div>
                <div className='am-return-home' onClick={() => { navigate('/') }}>Return to Home Page</div>
            </div>
        </div>
    )
}

export default ServerDown
