/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:12-12-2023
Purpose : AuthorList
Brief :
Developer : Amit Kalita
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useEffect, useState } from 'react'
import Header from '../../../../WS-WrittenStories/src/JS/Header/Header'
import Footer from '../../../../WS-WrittenStories/src/JS/Footer/Footer'
import AuthorContent from './AuthorContent'
import fetchAuthorData from '../../../../../../Controller/CN-ContributionNetwork/AuthorListController'
import '../../CS/AuthorList.css'
import { useLocation } from 'react-router-dom'
import fetchAuthorVisited from '../../../../../../Controller/CN-ContributionNetwork/AuthorList/AuthorVisit'
import Exception from '../../../../AM-AplicationManagement/src/JS/Exception/Exception'
import fetchAuthorMayLike from '../../../../../../Controller/CN-ContributionNetwork/AuthorList/AuthorMLike'

function AuthorList() {
    const location = useLocation();
    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const [userId, setUserID] = useState(localStorage.getItem('UserID') || null);
    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
    }, []);
    const [data, setData] = useState([]);
    const [maylike, setMayLike] = useState([]);
    const [visit, setVisit] = useState([]);
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        fetchAuthorData(page, userId, setData, setLoading);
    }, [page]);
    useEffect(() => {
        fetchAuthorMayLike(page, userId, setMayLike, setLoading);
    }, [page]);
    useEffect(() => {
        fetchAuthorVisited(page, userId, setVisit, setLoading);
    }, [page]);
    return (
        <div>
            <Header />
            <AuthorContent data={data}
                maylike={maylike}
                visit={visit}
                loading={loading}
            />
            <Footer />
        </div>
    )
}

export default AuthorList
