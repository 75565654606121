/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:12-19-2023
Purpose : Login PopUp 
Brief :
Developer : Amit Kalita
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useState, useEffect, useRef } from 'react'
import LoginBanner from '../../Assets/LoginBG.webp'
import Message from '../../Assets/Message.webp'
import Lock from '../../Assets/Lock.webp'
import EyeOpen from '../../Assets/eye.webp'
import EyeClosed from '../../Assets/eye hide password .webp'
import DangerCircle from '../../Assets/Danger Circle.webp'
import '../CS/LoginPopUp.css'
import SignUpPop from './SignUpPop'
import { useNavigate } from 'react-router-dom'
import axios from 'axios';
import { UM_Login } from '../../../../../Model/UM-UserManagement/UserManagement';
import GoogleButton from './GoogleButton'
import FacebookSignIn from './FacebookButton'

function LoginPop({ closePopup }) {
    const navigate = useNavigate();
    const popupRef = useRef(null);
    const [showSignup, setShowSignup] = useState(false); // State to control signup overlay visibility
    const [passwordVal, setPasswordVal] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
    const [emailVal, setEmailVal] = useState('');
    const [alertMessage, setAlertMessage] = useState(null);
    const [passwordAlertMessage, setPasswordAlertMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const handleLoginSubmit = async (e) => {
        try {
            setLoading(true); // Set loading to true before making the API call

            const url = UM_Login;
            const data = {
                email: emailVal,
                password: passwordVal,
                fcmToken: 'zzzzzd',
                loginType: 'normal',
                deviceType: 'react'
            };
            const response = await axios.post(
                url,
                data
            );
            if (response.data.status === 200) {
                console.log(response.data.data.
                    ImageExist)
                const Image = response.data.data.ImageName;
                const ImageExist = response.data.data.ImageExist;
                const IsAdmin = response.data.data.IsAdmin;
                const UserID = response.data.data.UserID; // Extract UserID from response data
                // Save UserID and token to local storage
                localStorage.setItem('IsAdmin', IsAdmin);
                localStorage.setItem('UserID', UserID);
                localStorage.setItem('token', response.data.token);
                localStorage.setItem('ImageName', Image);
                localStorage.setItem('ImageExist', ImageExist);

                navigate('/')
            }
            else {
                if (response.data.status === 470 || response.data.status === 451) {
                    setPasswordAlertMessage(null); // Clear password-related alert if it was set previously
                    setAlertMessage(response.data.message);
                } else {
                    setAlertMessage(null); // Clear general alert if it was set previously
                    setPasswordAlertMessage(response.data.message);
                }
            }
            setLoading(false);
        }
        catch (error) {
            // Handle errors as needed
            setLoading(false);
        }
    }
    const handleCloseAlert = () => {
        setAlertMessage(null);
        setPasswordAlertMessage(null);
    };
    const handlepopup = (event) => {
        const targetId = event.target.id;

        // Check the id and navigate accordingly
        if (targetId === 'pop-up-overlay') {
            navigate('/');
        } else if (targetId === 'um-login') {
            // Handle um-login click (e.g., show a popup or perform other actions)
        }
    };
    return (

        <div className="pop-up-overlay"
            id="pop-up-overlay"
            onClick={handlepopup}
        >
            <div className='um-login'
                ref={popupRef}
            >
                <div className='um-login-banner'>
                    <div className='um-login-img'>
                        <img src={LoginBanner} alt='LoginBanner' />
                    </div>
                </div>
                <div className='um-login-detail'>
                    <div>
                        <div className='um-login-account'>
                            Login to Your Account
                        </div>
                        <div className='um-login-social'>
                            Login using social networks
                        </div>
                        <div className='um-login-google-fb'>
                            {/* <FacebookSignIn /> */}
                            <GoogleButton />
                        </div>
                        <div className='um-login-email'>
                            or use your email address
                        </div>

                        <div className='um-login-emailbox'>
                            <div className='um-email-msg-textbox'>
                                <div className='um-email-msg'>
                                    <img src={Message} alt='message' />

                                </div>
                                <input
                                    className='um-email-inputbox'
                                    onChange={(e) => setEmailVal(e.target.value)} type='email'
                                    placeholder="Email Address"
                                />
                            </div>

                        </div>
                        {alertMessage && (
                            <div className="alert-overlay" onClick={handleCloseAlert}>
                                <div className='alert-box-img' onClick={handleCloseAlert}><img src={DangerCircle} alt='alert-danger' /></div>
                                <div className="um-alert-box">
                                    {alertMessage}

                                </div>
                            </div>
                        )}
                        <div className='um-login-passwordbox'>
                            <div className='um-password-lock-textbox'>
                                <div className='um-password-lock'>
                                    <img src={Lock} alt='lock' />

                                </div>
                                <input
                                    onChange={(e) => setPasswordVal(e.target.value)}
                                    type={showPassword ? 'text' : 'password'}
                                    className='um-password-inputbox'
                                    placeholder="Password"
                                />
                                <div className='um-eye' onClick={togglePasswordVisibility}>
                                    <img src={showPassword ? EyeClosed : EyeOpen} alt={showPassword ? 'eyeclosed' : 'eyeopen'} />
                                </div>
                            </div>
                        </div>
                        {passwordAlertMessage && (
                            <div className="alert-overlay" onClick={handleCloseAlert}>
                                <div className='alert-box-img' onClick={handleCloseAlert}><img src={DangerCircle} alt='alert-danger' /></div>
                                <div className="um-alert-box">
                                    {passwordAlertMessage}

                                </div>
                            </div>
                        )}
                        <div className='forgot-password' onClick={() => navigate('/forgotpassword')}>
                            Forgot password?
                        </div>
                        <div className='um-login-btn' onClick={handleLoginSubmit}>
                            Log In
                        </div>
                        {/* Display loading overlay or spinner based on the loading state */}
                        {loading && (
                            <div className="loading-overlay">
                                {/* Add your loading spinner or message here */}
                                Loading...
                            </div>
                        )}
                        <div className='um-does-signup'>
                            <div className='um-account'>
                                Doesn't have an account?&nbsp;
                            </div>
                            <div
                                onClick={() => navigate('/signuppop')}
                                className='um-signup-btn'>
                                Sign Up
                            </div>
                        </div>

                    </div>


                </div>


            </div>
            {/* Display signup overlay if showSignup state is true */}
            {showSignup && (
                <div className="signup-overlay">
                    <div className="signup-form">
                        <SignUpPop />
                    </div>
                </div>
            )}
        </div>

    )
}

export default LoginPop
