/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:01-04-2023
Purpose : The purpose of this code is to fetch stories based on subplaylist .
Brief : The code defines an asynchronous function called fetchSubPlaylistData. 
It fetches data from an API endpoint using the fetch function. 
This function constructs a URL based on a provided sub_playlist constant and accepts parameters for pagination (page and PAGE_LIMIT). 
It retrieves JSON data from the endpoint based on the specified page and item limit, then returns the parsed JSON result.
 The code lacks error handling for potential network issues or unexpected responses.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import { sub_playlist } from '../../Model/AS-AudioStories/AudioStories';

// Create a new function to fetch the data
export async function fetchSubPlaylistData(page, PAGE_LIMIT, sortBy, setSortBy, userId) {
    const response = await fetch(`${sub_playlist}&pageNumber=${page}&pageSize=${PAGE_LIMIT}&sortBy=${sortBy}&userId=${userId}`);
    const json = await response.json();
    return json;

}