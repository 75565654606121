/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:11-1-2023
Purpose : Preview Part in Contribution Network
Brief :
Developer : Jayash Todi.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useEffect, useRef, useState } from 'react';
import '../CS/ContributionNetworkPreviewSection.css';
import plusIcon from '../../Assets/Plus.webp';
import upload from '../../Assets/Upload.webp'
import ImageIcon from '../../Assets/Image.webp';
import HappyPot from '../../Assets/HappyPot.png';
import Danger from '../../Assets/Danger Circle.webp'
import PopupImage from '../../Assets/PopUpBox.webp'
import { useLocation, useNavigate } from 'react-router-dom';
import { CN_Characters, CN_Draft, CN_Tags, CN_DraftPatch } from '../../../../../Model/CN-ContributionNetwork/ContributionNetwork';
import axios from 'axios';

const EditPreview = (props) => {

    const navigate = useNavigate();
    let location = useLocation();
    const [authToken, setAuthToken] = useState(localStorage.getItem('token') || null);
    const [userId, setUserID] = useState(localStorage.getItem('UserID') || null);
    useEffect(() => {
        // Update component state with values from localStorage on mount
        setAuthToken(localStorage.getItem('token') || null);
        setUserID(localStorage.getItem('UserID') || null);
    }, []);

    const handleSubmit = async (e) => {
        // Check if the checkbox is checked
        var checkbox = document.querySelector('.CN-TNC-Box input[type="checkbox"]');
        if (checkbox.checked) {
            const submitUrl = CN_DraftPatch;
            const tagsUrl = CN_Tags;
            const charactersUrl = CN_Characters;
            const data = {
                title: props.titleData,
                story: props.storyData,
                conclusion: props.messageData,
                summary: props.beginningData,
                status: 2, // Status 1 for draft
                story_id: String(props.storyID)
            };

            const response = await fetch(submitUrl, {
                method: 'PATCH', // note the lowercase 'method'
                headers: {
                    Authorization: authToken,
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data),
                cache: 'default'
            });

            if (response.ok) {
                const jsonData = await response.json();
                handleSubmitEmail(Number(props.storyId), userId)
            } else {
                console.error('Request failed with status:', response.status);
            }
            try {
                const response = await axios.post(
                    `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story_tag/add_tags`,
                    {
                        story_id: String(props.storyID),
                        tags: tags.join(','), // Convert tags array to comma-separated string
                    },
                    {
                        headers: {
                            Authorization: authToken,
                        },
                    }
                );

                if (response.status === 200) {
                    alert('Tags Inserted')
                    // Perform any additional actions after adding tags
                } else {
                    console.log('Failed to add tags.');
                }
            } catch (error) {
                if (error.response) {
                    console.error('Server Error:', error.response);
                } else if (error.request) {
                    console.error('Request Error:', error.request);
                } else {
                    console.error('Unknown Error:', error.message);
                }
            }
            uploadImageApi();

            document.getElementById('CN-WS').style.display = 'none';
            document.getElementById('writing-section-preview-popup').style.display = 'none';

            document.getElementById('CN-WS-Fin-Popup').style.display = 'flex';
            setTimeout(() => {
                navigate('/', { state: { authToken: authToken, UserID: userId, from: 'finalWriteSection' } })
            }, 3000)
        }
        else {
            // Checkbox is not checked, show an alert
            alert('Please Tick the declaration before submitting.');
        }
    }
    const handleSubmitEmail = async (storyId, userId) => {
        let data = JSON.stringify({
            "userId": parseInt(userId), // Use the userId parameter
            "storyID": parseInt(storyId) // Use the storyId parameter
        });

        let config = {
            method: 'post',
            maxBodyLength: Infinity,
            url: `https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/submit_story`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authToken
            },
            data: data
        };

        try {
            const response = await axios.request(config);
            console.log(JSON.stringify(response.data));
        } catch (error) {
            console.log(error);
        }
    };
    function uploadImageApi() {
        const fileInput = document.getElementById('myImage');
        const file = fileInput.files[0];

        if (file) {
            const formData = new FormData();
            formData.append('myImage', file);


            fetch(`https://${process.env.REACT_APP_ENVIRONMENT_VARIABLE}wikibedtimestories.com/api/V2.0/story/image_upload?storyId=${props.storyID}`, {
                method: 'POST',
                body: formData
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok.');
                    }

                    const contentType = response.headers.get('content-type');
                    if (contentType && contentType.indexOf('application/json') !== -1) {
                        return response.json(); // Parse JSON if content-type is JSON
                    } else {
                        return response.blob(); // Get response as Blob if not JSON
                    }
                })
                .then(data => {
                    // Check if data is JSON or Blob
                    if (data instanceof Blob) {
                        // Handle response as a file/blob (non-JSON response)
                        // Process the file/blob data here if needed
                    } else {
                        // Handle JSON response
                        console.log('Image uploaded:', data);
                        // Do something with the JSON data if needed
                    }
                })
                .catch(error => {
                    // Handle errors
                    console.error('There was a problem with the upload:', error);
                });
        } else {
            console.error('No file selected.');
        }
    }


    const [image, setImage] = useState(null);

    const uploadImage = (e) => {
        console.log(e.target.files);
        if (e.target.files && e.target.files[0]) {
            let temp = URL.createObjectURL(e.target.files[0]);
            setCoverImgUrl(URL.createObjectURL(e.target.files[0]));
            console.log(temp);
            document.getElementById('right-lower-cover-image').style.backgroundImage = `url('${temp}')`;
            document.getElementById('right-lower-cover-image').style.backgroundSize = 'cover';
            document.getElementById('right-lower-cover-image').style.backgroundRepeat = 'no-repeat';
            document.getElementById('right-lower-cover-image-icon').style.display = 'none';
            document.getElementById('right-lower-cover-image-text').style.display = 'none';
            setImage(e.target.files[0]);
        }
    }
    const [characters, setCharacters] = useState('');
    const [refLink, setRefLink] = useState('');


    const [coverImgUrl, setCoverImgUrl] = useState(null);

    let isComponentVisible = props.isComponentVisible;
    const [tags, setTags] = useState([]); // State to store tags
    const [inputValue, setInputValue] = useState(''); // State to track input value

    // Function to add a tag
    const addTag = () => {
        const trimmedInputValue = inputValue.trim();

        if (trimmedInputValue !== '') {
            if (tags.length < 4 && !tags.includes(trimmedInputValue)) {
                setTags([...tags, trimmedInputValue]);
                setInputValue('');
            } else {
                alert("You can only add up to four tags!");
            }
        }
    };

    // Function to remove a tag
    const removeTag = (tag) => {
        setTags(tags.filter((t) => t !== tag));
    };
    const fileInputRef = useRef(null);
    const handleBoxClick = () => {
        fileInputRef.current.click();
    };
    const [isPopupOpen, setIsPopupOpen] = useState(true);

    const handleDangerClick = () => {
        setIsPopupOpen(!isPopupOpen);
    };

    const handlePopupClose = () => {
        setIsPopupOpen(false);
    };

    const handleOutsideClick = (e) => {
        // Check if the click is outside the popup and the danger image
        if (!e.target.closest('.popup') && !e.target.closest('.cn-danger-upload')) {
            setIsPopupOpen(false);
        }
    };
    return (
        <div id='writing-section-preview-popup' className='main' onClick={handleOutsideClick}>
            <div className='left'>
                <div className='left-upper'>

                    <div className='CN-Preview-headings'>Tags</div>
                    <div className='cn-preview-box'>
                        <div className='CN-Preview-input' id='CN-preview-input-tags'>
                            <input
                                value={inputValue}
                                onChange={(e) => setInputValue(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        addTag();
                                    }
                                }}
                                placeholder='Add keywords to help people discover'
                                rows='1' />
                        </div>
                        <div className='plus-img'><img alt='plus' src={plusIcon} /></div>
                    </div>


                    <div className='tags-container'>
                        {tags.map((tag) => (

                            <div className='tagflex'>
                                <div key={tag} className='tag'>
                                    {tag}
                                    <span className='tag-close' onClick={() => removeTag(tag)}>
                                        &times;
                                    </span>
                                </div>
                            </div>

                        ))}
                    </div>


                    <div className='CN-Preview-headings'>
                        <div>Story image</div>
                        <div className='cn-danger-upload' onClick={handleDangerClick}>
                            <img src={Danger} alt='h' />
                        </div>
                        {isPopupOpen && (
                            <div className='danger-popup'>
                                <img src={PopupImage} alt='popup' />
                                <p >Optional.<br />You don't have to worry, we'll<br /> choose an image for your story if <br />you won’t!</p>
                            </div>
                        )}
                    </div>
                    <div className='cn-preview-box'
                    >
                        <div className='CN-Preview-input'>
                            <label htmlFor="myImage" className='cn-img-upload'>
                                <div className='plus-img' ><img alt='plus' src={ImageIcon} /></div>
                                <div className='cn-img-upload-text'>Choose image from library</div>
                            </label>

                            <input type="file" name="myImage" id="myImage"
                                accept="image/*" required
                                onChange={(e) => uploadImage(e)} placeholder='Choose image from library'
                                style={{ display: 'none' }} // Optionally hide the input element
                                rows='1'
                            />

                        </div>
                        <div className='plus-img' ><img alt='plus' src={upload} /></div>
                    </div>


                    <div className='CN-Declaration-Heading'>
                        Declaration <span className='cn-declaration-req'>*(required)</span>
                    </div>

                    <div className='CN-TNC-Box'>
                        <input type='checkbox' />
                        <div>
                            I hereby declare that the above submitted work is my own and I've not copied it from anywhere.
                        </div>
                    </div>

                </div>

                <div className='left-lower'>
                    <button className='cancelBtn' onClick={() => isComponentVisible(false)}>Cancel</button>
                    <button className='submitBtn' onClick={handleSubmit}>Submit</button>
                </div>
            </div>
            <div className='right'>
                <div className='right-upper'>
                    <span>Story Preview</span>
                </div>

                <div className='right-lower'>
                    <div className='right-lower-upper'>
                        <div className='right-lower-title'>
                            {props.titleData}
                        </div>

                        <div id='right-lower-cover-image'>
                            <div id='right-lower-cover-image-icon' className='image-preview'>
                                <img alt='imgicon' src={ImageIcon} />
                            </div>
                            <span id='right-lower-cover-image-text'>Uploaded Image</span>
                        </div>
                        <div className='right-lower-beginning'>
                            {props.beginningData}
                        </div>
                        <div className='right-lower-separator'>

                        </div>
                        <div className='right-lower-story'>
                            {props.storyData}
                        </div>
                    </div>
                    <div className='right-lower-lower'>
                        <div className='right-lower-image'>
                            <img alt='HappyPot' src={HappyPot} />
                        </div>
                        <div className='right-lower-message'>
                            <span>{props.messageData}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditPreview