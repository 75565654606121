/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:01-04-2023
Purpose : Explore Audio Stories Page 
Brief :
AudioContent, manages the display and interaction of audio stories. It uses various state variables created via the useState hook to control the class names and appearance of different elements based on user actions.

It renders a search box (SearchBox) and an audio stories list (AudioStoriesList). The component handles expanding and collapsing sections of the audio player interface by changing class names and state variables upon user clicks through handleExpandClick and handleExpandClose functions.

The AudioStoriesList component is passed various props related to data, loading state, pagination, expand/collapse functionality, and search input for audio stories. Overall, this component organizes the display and behavior of the audio content within a web page or application.
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React, { useState } from 'react'
import SearchBox from '../../../WS-WrittenStories/src/JS/StoriesList/SearchBox'
import AudioStoriesList from './AudioStoriesList'

function AudioContent({ data, loading, setPage, page,
    setSortBy,
    allStoriesLoaded, totalStories,
    totalStoriesObject, setData,
    //expand
    expandedClassName,
    audioList,
    audioSearch,
    audioPagination,
    audioPlayerBox,
    onExpandClick,
    onExpandClose,
    audioPlayerBoxExpand,
    seekingInputMob
}) {
    return (
        <div className='as-stories'>
            <div className={audioSearch}>
                <SearchBox totalStories={totalStories} />
            </div>
            <AudioStoriesList data={data} loading={loading} setPage={setPage} page={page}
                allStoriesLoaded={allStoriesLoaded} totalStories={totalStories}
                totalStoriesObject={totalStoriesObject}
                setSortBy={setSortBy}
                setData={setData}
                //expand
                expandedClassName={expandedClassName}
                audioList={audioList}
                audioPagination={audioPagination}
                audioPlayerBox={audioPlayerBox}
                onExpandClick={onExpandClick}
                onExpandClose={onExpandClose}
                audioPlayerBoxExpand={audioPlayerBoxExpand}
                seekingInputMob={seekingInputMob}

            />
        </div>
    )
}

export default AudioContent
