/***************************************************************************************************************************
This code is licensed under Kushmanda Tech LLC license
Property of Kushmanda Tech LLC, Do not distribute to unauthorized person. Do not print / copy without explicit permission.
App name : wikibedtimestories.com
Date:10-19-2023
Purpose : About Us Page
Brief :
Developer : Amit Kalita.
email : contact@kushmanda.tech Phone : +1 (760) 659-0487
*************************************************************************************************************************** */

import React from 'react'
import '../../CS/AboutUs.css'
import Header from '../../../../WS-WrittenStories/src/JS/Header/Header'
import Footer from '../../../../WS-WrittenStories/src/JS/Footer/Footer'
function AboutUs() {
    return (
        <div>
            <Header />
            <div className='am-aboutus-page'>
                <div className='am-aboutus'>
                    Our Story
                </div>
                <div className='am-aboutus-heading'>Wiki Bedtime Stories: Where Characters Come to Life!   </div>
                <p className='am-para'>At Wiki Bedtime Stories, we believe that storytelling is like a warm hug at the end of a long day. That's why we have brought you a treasure trove of bedtime stories—whether regional tales, fairy tales, or folklore—all in one place. </p>
                <p className='am-para'>Our community-based website is a magical space where authors find a creative space to unleash their writing talent freely, crafting kids' bedtime stories that captivate young minds and hearts. </p>
                <p className='am-para'>Think of a world where each story transcends you to adventure, teaches your kids morals, and helps you explore your talent. That's where you should be if you are a dreamer like us! That's what Wiki Bedtime Stories offers! </p>
                <div className='am-aboutus-heading'>Here's What We Bring to Your Life  </div>
                <p className='am-para'>Our driving force is you - people who read and contribute fascinating short stories to reach out to the world! Our platform is author-centric, and storytellers are the true heroes here. Whether you're Shakespeare or a budding writer, Wiki Bedtime Stories welcomes authors of all backgrounds and experiences. </p>
                <div className='am-aboutus-heading'>Diverse Stories from Around the World  </div>
                <p className='am-para'>We delve deep into the ocean of diversity! From the top of beautiful Himalayas to the sunny beaches of Hawaii, we got bedtime stories to tell from all around the world. Each story sings the glory of its culture and the author's rich experiences. </p>
                <div className='am-aboutus-heading'>Contributors: Weavers of Tales  </div>
                <p className='am-para'>Our contributors are not just writers - they're weavers, spinning marvellous stories that touch the reader's heart. From old folktales to fresh, imaginative tales, they'll take you to all sorts of exciting places—so get ready to read some amazing bedtime stories written by our talented contributors. </p>
            </div>
            <Footer />
        </div>
    )
}

export default AboutUs
